// https://github.com/salvoravida/redux-first-history
// Move to using redux first history
// @TODO - Instead of history across the app, use import { push } from "redux-first-history";
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'redux/ducks';
import rootSaga from 'redux/sagas';

import { routerMiddleware } from 'redux/ducks/routerDuck';

// Setup devtools
const composeEnhancers =
	process.env.NODE_ENV !== 'production' &&
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				trace: true,
				traceLimit: 25
		  })
		: compose;

export default (initialState = {}) => {
	const middlewares = [routerMiddleware];

	// Create the saga middleware
	const sagaMiddleware = createSagaMiddleware({});
	middlewares.push(sagaMiddleware);

	// Create and export the store
	const createStoreWithMiddleware = composeEnhancers(
		applyMiddleware(...middlewares)
	);
	const finalCreateStore = createStoreWithMiddleware(createStore);
	const store = finalCreateStore(rootReducer, initialState);

	// Start the sagas
	sagaMiddleware.run(rootSaga);

	if (process.env.NODE_ENV !== 'production') {
		if (module.hot) {
			module.hot.accept('../ducks/index', () => {
				store.replaceReducer(rootReducer);
			});
		}
	}

	return store;
};
