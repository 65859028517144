import axios from 'axios';
import responseHandler from '../responseHandler';

const hatchBaseRoute = process.env.REACT_APP_HATCH_BASE;
const hatchBasicAuth = process.env.REACT_APP_HATCH_BASIC_AUTH;

const REFRESH_TOKEN = 'refresh_token';

const hatchInstance = axios.create({
	baseURL: hatchBaseRoute,
	timeout: 9000,
	headers: { 'content-type': 'application/x-www-form-urlencoded' },
	withCredentials: false
});

const getHeaders = () => {
	const token = JSON.parse(localStorage.getItem('token'));

	return { authorization: `Bearer ${token}` };
};

/**
 * Login user
 * @param { username, password, grant_type } params
 */
const login = (params) =>
	hatchInstance
		.post(`/oauth/token`, params, {
			headers: {
				authorization: hatchBasicAuth
			}
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);

/**
 * @param {*} params
 * @returns
 */
const refreshToken = (params) => {
	const form = new FormData();

	form.append(REFRESH_TOKEN, params.refreshToken);
	form.append('grant_type', REFRESH_TOKEN);

	return hatchInstance
		.post(`/oauth/token`, form, {
			headers: {
				authorization: hatchBasicAuth,
				'content-type': 'application/x-www-form-urlencoded'
			}
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const checkOttAuthorisation = (ott) =>
	hatchInstance
		.get(`auth/ott?ott=${ott}`, {
			headers: {
				authorization: hatchBasicAuth
			}
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);

/**
 * Get user information
 * @returns {*} user
 */
const getUser = () =>
	hatchInstance
		.get(`/api/management/me`, { headers: getHeaders() })
		.then(responseHandler.success)
		.catch(responseHandler.error);

export default {
	login,
	refreshToken,
	getUser,
	checkOttAuthorisation
};
