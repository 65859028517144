const hasAudio = (video) =>
	video?.mozHasAudio ||
	Boolean(video?.webkitAudioDecodedByteCount) ||
	Boolean(video?.audioTracks && video?.audioTracks.length);

const calculateImageDimensions = (formField, layout) => {
	const isBoxed = layout === 'boxed';
	const isFullWidth = layout === 'fullWidth';

	let width;

	if (formField.includes('favicon_image')) {
		width = 32;
	} else if (formField.includes('header_mobile_image')) {
		width = 440;
	} else if (isBoxed || (isFullWidth && !formField.includes('header_image'))) {
		width = 960;
	} else if (isFullWidth && formField.includes('header_image')) {
		width = 1600;
	} else {
		width = 960;
	}

	return { outputWidth: width };
};

export { hasAudio, calculateImageDimensions };
