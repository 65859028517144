const getDataGridHeight = (count) => {
	const HEADER_HEIGHT = 56;
	const PAGINATION_HEIGHT = 53;
	const ROW_HEIGHT = 52;

	if (!count || count < 5) {
		return 400;
	}

	if (count < 10) {
		return count * ROW_HEIGHT + HEADER_HEIGHT + PAGINATION_HEIGHT + 2;
	}

	return 635;
};

export { getDataGridHeight };
