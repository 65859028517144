import { useParams } from 'react-router-dom';

const getQueryParams = () => {
	const query = window.location.search;
	const searchParams = new URLSearchParams(query);
	const params = {};

	searchParams.forEach((value, key) => {
		params[key] = value;
	});

	return params;
};

const useMergedUrlAndQueryParams = () => {
	const urlParams = useParams();

	const getParams = () => {
		const params = getQueryParams();

		return { ...urlParams, ...params };
	};

	return {
		urlParams,
		queryParams: getQueryParams(),
		getParams
	};
};

export default useMergedUrlAndQueryParams;
