const fileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);

		reader.onload = () => {
			const base64String = reader.result;
			resolve(base64String);
		};

		reader.onerror = (error) => reject(error);
	});

async function urlToBase64(url) {
	const response = await fetch(url);
	if (!response.ok) {
		throw new Error(`Error status code: ${response.status}`);
	}
	const blob = await response.blob();
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
}

const bytesToKB = (bytes) => `${(bytes / 1024).toFixed(2)} kb`;

const getSupportedFormats = (fileType) => {
	if (fileType === 'image') {
		return 'JPG, JPEG, PNG, GIF or WEBP';
	}
	if (fileType === 'video') {
		return 'WEBM or MP4';
	}
	return 'JPG, JPEG, PNG, GIF, WEBP, WEBM or MP4.';
};

const formatFileSize = (sizeString) => {
	const sizeInBytes = parseInt(sizeString, 10);
	const KB = 1024;
	const MB = KB * KB;

	if (sizeInBytes >= MB) {
		return `${(sizeInBytes / MB).toFixed(2)} MB`;
	}

	if (sizeInBytes >= KB) {
		return `${(sizeInBytes / KB).toFixed(2)} KB`;
	}

	return `${sizeInBytes} bytes`;
};

export {
	fileToBase64,
	bytesToKB,
	urlToBase64,
	getSupportedFormats,
	formatFileSize
};
