import { useState, useEffect, useRef } from 'react';

const useRequestTimeTracker = (isRequestActive) => {
	const [hasExceededThreshold, setHasExceededThreshold] = useState(false);
	const threshold = 5000;
	const timerRef = useRef();

	useEffect(() => {
		// Reset the flag when the request is not active anymore
		if (!isRequestActive) {
			setHasExceededThreshold(false);
		}

		// Start the timer only when the request is active and the timer is not already running
		if (isRequestActive && timerRef.current === undefined) {
			timerRef.current = setTimeout(() => {
				setHasExceededThreshold(true);
			}, threshold);
		}

		// Cleanup function to clear the timer
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
				timerRef.current = undefined;
			}
		};
	}, [isRequestActive, threshold]);

	return hasExceededThreshold;
};

export default useRequestTimeTracker;
