import React from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(0.3),
		minWidth: 191,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				)
			}
		}
	}
}));

const DropdownMenu = ({ actionText, options, handleAction }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const id = actionText.split(' ').join('-').toLowerCase();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (_, option) => {
		setAnchorEl(null);

		if (option !== 'backdropClick') {
			handleAction(option);
		}
	};

	return (
		<div>
			<Button
				id={id}
				aria-controls={open ? id : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="contained"
				disableElevation
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{actionText}
			</Button>
			<StyledMenu
				id={id}
				MenuListProps={{
					'aria-labelledby': id
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						onClick={(event) => handleClose(event, option)}
						disableRipple
					>
						{option.label}
					</MenuItem>
				))}
			</StyledMenu>
		</div>
	);
};

DropdownMenu.propTypes = {
	actionText: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	handleAction: PropTypes.func.isRequired
};

export default DropdownMenu;
