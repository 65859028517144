import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSetQueryParams, useGeneratePageFilters } from 'hooks';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterDropdownV2 from 'components/FiltersV2/FilterDropdownV2';
import ChipsGroup from 'components/FiltersV2/Chips';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';

const Filters = ({
	page,
	apiFilters,
	isLoading,
	children,
	showResetAll = true
}) => {
	const [lastFilterChanged, setLastFilterChanged] = useState(null);
	const { updateQueryParam, resetQueryParams } = useSetQueryParams();

	const mergedFilters = useMemo(
		() => ({
			...apiFilters,
			...lastFilterChanged
		}),
		[apiFilters, lastFilterChanged]
	);

	const { generateFilters } = useGeneratePageFilters(page, mergedFilters);
	const filters = generateFilters();

	const hasFiltersSelected = useMemo(
		() =>
			filters.find((filter) => filter.items.find((item) => item.isSelected)),
		[filters]
	);

	const handleSubmit = useCallback(
		({ name, selected, fieldName }) => {
			const lastFilter =
				fieldName && selected.length
					? { [fieldName]: mergedFilters[fieldName] }
					: null;

			setLastFilterChanged(lastFilter);
			updateQueryParam(name, selected.map((item) => item.value).join(','));
		},
		[mergedFilters, setLastFilterChanged, updateQueryParam]
	);

	const handleResetFilters = () => {
		resetQueryParams();
		setLastFilterChanged(null);
	};

	return (
		<Paper sx={{ width: '100%', mb: 2 }}>
			<Box width="100%" p={1.5}>
				<Box display="flex" justifyContent="space-between" height="36.5px">
					{children && children}

					<Stack direction="row" spacing={2}>
						{isLoading ? (
							<>
								<Skeleton variant="rounded" height={36} width={90} />
								<Skeleton variant="rounded" height={36} width={90} />
								<Skeleton variant="rounded" height={36} width={90} />
								<Skeleton variant="rounded" height={36} width={90} />
							</>
						) : (
							filters.map((filter) =>
								filter?.items?.length > 0 && filter?.display ? (
									<FilterDropdownV2
										key={filter.paramName}
										filter={filter}
										onSubmit={handleSubmit}
									/>
								) : null
							)
						)}

						{hasFiltersSelected && showResetAll && (
							<Button
								variant="reset"
								onClick={handleResetFilters}
								startIcon={<RefreshTwoToneIcon />}
								sx={{ marginLeft: 'auto !important' }}
							>
								Reset All
							</Button>
						)}
					</Stack>
				</Box>

				{hasFiltersSelected && (
					<ChipsGroup filters={filters} handleChange={handleSubmit} />
				)}
			</Box>
		</Paper>
	);
};

Filters.propTypes = {
	page: PropTypes.string.isRequired,
	apiFilters: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	children: PropTypes.node,
	showResetAll: PropTypes.bool
};

Filters.defaultProps = {
	isLoading: false,
	children: null,
	showResetAll: true
};

export default Filters;
