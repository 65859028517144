/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

const isPlainObject = (obj) =>
	Object.prototype.toString.call(obj) === '[object Object]';

const map = (fn, list) => {
	const arr = isPlainObject(list) ? Object.values(list) : list;
	return arr.map(fn);
};

const identity = (x) => x;

const isObject = (val) => val && typeof val === 'object';

const objToArray = (objOrArray) => map(identity, objOrArray);

const objToArrayWithKey = (objOrArray) => {
	if (!isPlainObject(objOrArray)) {
		return objOrArray;
	}

	const result = [];
	for (const key in objOrArray) {
		if (Object.prototype.hasOwnProperty.call(objOrArray, key)) {
			result.push({ key, value: objOrArray[key] });
		}
	}
	return result;
};

const arrayToObj = (arr, key) =>
	arr.reduce((acc, cur) => {
		acc[cur[key]] = cur;
		return acc;
	}, {});

const convertToArray = (obj) => {
	const arr = [];

	Object.keys(obj).forEach((key) =>
		arr.push({
			name: key,
			value: key,
			...obj[key]
		})
	);

	return arr;
};

export const isEmpty = (obj) =>
	obj && Object.entries(obj).length === 0 && obj.constructor === Object;

const compareObjects = (obj1, obj2) => {
	if (obj1 === obj2) return true;

	if (
		typeof obj1 !== 'object' ||
		typeof obj2 !== 'object' ||
		obj1 === null ||
		obj2 === null
	) {
		return false;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	return (
		keys1.every(
			(key) => obj2.hasOwnProperty(key) && compareObjects(obj1[key], obj2[key])
		) &&
		keys2.every(
			(key) => obj1.hasOwnProperty(key) && compareObjects(obj2[key], obj1[key])
		)
	);
};

const getValueAtPath = (errors, path) =>
	path.split('.').reduce((obj, key) => obj?.[key], errors);

const removeProperties = (obj, allowedProperties) => {
	if (obj) {
		Object.keys(obj).forEach((key) => {
			if (!allowedProperties.includes(key)) {
				// eslint-disable-next-line no-param-reassign
				delete obj[key];
			}
		});
		return obj;
	}

	return {};
};

const isObjEmpty = (object) => Object.keys(object).length === 0;

export {
	isPlainObject,
	map,
	identity,
	objToArray,
	objToArrayWithKey,
	arrayToObj,
	convertToArray,
	isObject,
	compareObjects,
	getValueAtPath,
	removeProperties,
	isObjEmpty
};
