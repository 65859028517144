import { PLATFORMS } from 'constants';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { useNavigate } from 'react-router-dom';
import { useForm, Controller, useWatch } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CustomModal from 'components/CustomModal/CustomModal';
import { useMessageContext } from 'contexts/Message/MessageContext';
import {
	useUserData,
	useUserCountryList,
	useSetUserLicenses
} from 'hooks/user';
import apiCampaigns from 'services/apiCampaigns';
import RadioGroupInput from 'components/Form/RadioGroupInput';

import Datepicker from 'components/Datepicker/Datepicker';
import { isEmpty } from 'utils';

import schema from './validationSchema';

const isDisabled = (origin, tokens, selectedBrand, selectedCountry) => {
	const noTokens = !tokens?.length;
	const noCountry = !selectedCountry;
	const noBrandOrCountry = !selectedBrand || !selectedCountry;

	if (origin === PLATFORMS.cc) {
		return noCountry || noTokens;
	}

	return noBrandOrCountry || noTokens;
};

const getStatusState = (campaignDetails) => {
	if (campaignDetails) {
		return campaignDetails.active ? 'active' : 'inactive';
	}

	return 'active';
};

const CreateCampaign = ({ campaignDetails, isOpen, closeModal }) => {
	const { userBrands, origin, userCountries, tokens } = useUserData();
	const { updateUserLicenses, resetUserLicenses } = useSetUserLicenses();

	const navigate = useNavigate();
	const sendMessage = useMessageContext();

	const {
		control,
		setValue,
		reset,
		resetField,
		handleSubmit,
		clearErrors,
		formState: { isSubmitting, errors }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: {
			name: campaignDetails?.name ?? '',
			manufacturer_id: null,
			country: null,
			end_date: campaignDetails?.end_date
				? new Date(campaignDetails?.end_date)
				: null,
			token: null,
			active: getStatusState(campaignDetails)
		},
		criteriaMode: 'all',
		resolver: yupResolver(schema(origin))
	});

	const [selectedBrand, selectedCountry] = useWatch({
		control,
		name: ['manufacturer_id', 'country']
	});

	const countries = useUserCountryList(userCountries, origin, selectedBrand);

	// --- set brand value
	useEffect(() => {
		if (userBrands?.length && campaignDetails) {
			const { manufacturer_id: brandId } = campaignDetails;

			const selected = userBrands.find(
				(brand) => brand.value.id.toString() === brandId
			);
			setValue('manufacturer_id', selected);
		}

		if (origin === PLATFORMS.cc && campaignDetails) {
			setValue('manufacturer_id', campaignDetails.manufacturer_id);
		}
	}, [userBrands, campaignDetails, setValue, origin]);

	// Set token on edit
	useEffect(() => {
		if (campaignDetails && tokens?.length) {
			const selected = tokens.find(
				(token) => token.value === campaignDetails.token
			);

			setValue('token', selected);
		}
	}, [campaignDetails, setValue, tokens]);

	//  Set Country value on edit
	useEffect(() => {
		if (selectedBrand && campaignDetails && !selectedCountry && countries) {
			const { country } = campaignDetails;
			const selected = countries.find(
				(item) => item.value.locale === country.locale
			);

			setValue('country', selected, {
				shouldValidate: true
			});
		}
	}, [selectedBrand, campaignDetails, setValue, countries, selectedCountry]);

	// --- Set tokens for CC
	useEffect(() => {
		if (origin === PLATFORMS.cc && selectedCountry) {
			updateUserLicenses(selectedCountry.value.locale);
		}
	}, [origin, selectedCountry, tokens.length, updateUserLicenses]);

	// --- Preselect token if there's only one
	useEffect(() => {
		if (!campaignDetails && tokens?.length === 1) {
			setValue('token', tokens[0], { shouldValidate: true });
		}
	}, [setValue, tokens, campaignDetails]);

	const getCountryHelperText = useCallback(
		(errorMessage) => {
			if (errorMessage) {
				return errorMessage;
			}

			return origin !== PLATFORMS.cc && !selectedBrand
				? 'Please select Brand to enable Country selection'
				: '';
		},
		[origin, selectedBrand]
	);

	const preparePayload = useCallback(
		(values) => {
			const { country, manufacturer_id: brand, end_date: endDate } = values;

			const payload = {
				name: values.name,
				country: {
					id: country.value.id,
					language: country.value.language ?? country.value.locale,
					name: country.value.name,
					locale: country.value.locale
				},
				active: values.active === 'active',
				end_date: endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null,
				token: values?.token?.value
			};

			if (origin === PLATFORMS.hatch) {
				payload.affiliate_id = brand.value.affiliateId.toString();
			}

			if (origin !== PLATFORMS.cc) {
				payload.manufacturer_id = brand.value.id;
				payload.manufacturer_name = brand.label;
			}

			return payload;
		},
		[origin]
	);

	const createCampaign = useCallback(
		async (payload) => {
			const {
				data: {
					response: { id }
				}
			} = await apiCampaigns.createCampaign(payload);

			navigate(`/campaign/${id}/products/0/10?sort=modified_at&sort_type=desc`);

			sendMessage({ text: 'Campaign successfully created.', type: 'success' });
		},
		[sendMessage, navigate]
	);

	const updateCampaign = useCallback(
		async (campaignId, payload) => {
			await apiCampaigns.updateCampaign(campaignId, payload);
			sendMessage({ text: 'Campaign successfully updated.', type: 'success' });
		},
		[sendMessage]
	);

	const onSubmit = useCallback(
		async (formValues) => {
			const payload = preparePayload(formValues);

			try {
				if (campaignDetails) {
					await updateCampaign(campaignDetails.id, payload);
				} else {
					await createCampaign(payload);
				}
			} catch (error) {
				sendMessage({ text: 'Something went wrong', type: 'error' });
			} finally {
				reset();
				closeModal(true);
			}
		},
		[
			campaignDetails,
			closeModal,
			createCampaign,
			preparePayload,
			sendMessage,
			updateCampaign,
			reset
		]
	);

	const handleClose = () => {
		reset();
		clearErrors();
		closeModal();

		if (origin === PLATFORMS.cc) {
			resetUserLicenses();
		}
	};

	return (
		<CustomModal
			isOpen={isOpen}
			closeModal={handleClose}
			title={campaignDetails?.id ? 'Update Campaign' : 'Create Campaign'}
		>
			<form>
				<Box
					display="flex"
					flexDirection="column"
					gap="16px"
					padding="24px 0 0"
				>
					<Controller
						control={control}
						name="name"
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								fullWidth
								data-testid="CampaignName"
								name="name"
								label="Name"
								error={!!error}
								helperText={error?.message}
								variant="outlined"
								maxLength="90"
								placeholder="Max. 90 characters allowed"
							/>
						)}
					/>

					{/* Brand */}
					{origin !== PLATFORMS.cc && (
						<Controller
							control={control}
							name="manufacturer_id"
							render={({
								field: { onChange, ...field },
								fieldState: { error }
							}) => (
								<Autocomplete
									{...field}
									fullWidth
									disablePortal
									disabled={!!campaignDetails}
									data-testid="BrandSelection"
									options={userBrands}
									isOptionEqualToValue={(option, selected) =>
										selected ? option.value.id === selected?.value?.id : false
									}
									getOptionLabel={(option) => option?.label ?? ''}
									ListboxProps={{ style: { maxHeight: 220 } }}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Brand"
											error={!!error}
											helperText={error?.message}
										/>
									)}
									onChange={(_, option) => {
										resetField('country', { keepError: true });
										onChange(option);
									}}
								/>
							)}
						/>
					)}

					{/* Country */}
					<Controller
						control={control}
						name="country"
						render={({
							field: { onChange, ...field },
							fieldState: { error }
						}) => (
							<Autocomplete
								{...field}
								fullWidth
								disablePortal
								data-testid="CountrySelection"
								options={countries}
								disabled={!countries?.length || !!campaignDetails}
								isOptionEqualToValue={(option, selected) =>
									selected ? option.value.id === selected?.value?.id : false
								}
								getOptionLabel={(option) => option?.label ?? ''}
								onChange={(_, option) => {
									if (origin === PLATFORMS.ps && tokens?.length === 1) {
										onChange(option);
									} else {
										resetField('token', { keepError: true });
										onChange(option);
									}
								}}
								ListboxProps={{ style: { maxHeight: 220 } }}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Country"
										error={!!error}
										helperText={getCountryHelperText(error?.message)}
										InputProps={{
											...params.InputProps,
											autoComplete: 'disabled',
											startAdornment: (
												<>
													{field.value ? (
														<img
															width="16px"
															src={`/flags/${field?.value?.value?.locale.toLowerCase()}.svg`}
															alt="flag"
														/>
													) : null}
												</>
											)
										}}
									/>
								)}
								renderOption={(props, option) => (
									<li
										{...props}
										style={{
											fontSize: '14px',
											display: 'flex',
											alignItems: 'center',
											gap: '5px'
										}}
									>
										<img
											width="16px"
											src={`/flags/${option.value.locale.toLowerCase()}.svg`}
											alt={option.value.label}
										/>
										{option.label}
									</li>
								)}
							/>
						)}
					/>

					{/* End Date */}
					<Controller
						control={control}
						name="end_date"
						render={({ field: { onChange, value } }) => (
							<Datepicker
								label="End date (optional)"
								size="normal"
								value={value}
								data-testid="DateSelection"
								onChange={(event) => onChange(event)}
								helperText="If left blank, the campaign will run for unlimited time. Format: MM / DD / YYYY"
							/>
						)}
					/>

					{/* Token */}
					{origin !== PLATFORMS.hatch && (
						<Controller
							control={control}
							name="token"
							render={({
								field: { onChange, ...field },
								fieldState: { error }
							}) => (
								<Autocomplete
									data-testid="TokenSelection"
									{...field}
									fullWidth
									disablePortal
									disabled={isDisabled(
										origin,
										tokens,
										selectedBrand,
										selectedCountry
									)}
									options={tokens}
									isOptionEqualToValue={(option, selected) =>
										selected ? option.value === selected?.value : false
									}
									getOptionLabel={(option) => (option ? option.label : '')}
									onChange={(_, option) => onChange(option)}
									ListboxProps={{ style: { maxHeight: 150 } }}
									renderInput={(params) => (
										<>
											<TextField
												{...params}
												label="Token"
												error={!!error}
												helperText={error?.message}
											/>
											{origin === PLATFORMS.ps && (
												<FormHelperText
													sx={{
														marginLeft: '14px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}
												>
													Your access key to the PriceSpider platform based on
													selected brand and country.
												</FormHelperText>
											)}
										</>
									)}
								/>
							)}
						/>
					)}

					{/* Form Status */}
					<RadioGroupInput
						control={control}
						name="active"
						label="Campaign Status"
						options={[
							{ value: 'active', label: 'Active' },
							{ value: 'inactive', label: 'Inactive' }
						]}
					/>

					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="center"
						gap="16px"
					>
						<Button
							variant="text"
							onClick={handleClose}
							data-testid="CampaignButtonCancel"
							sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							type="submit"
							data-testid="CampaignButtonConfirm"
							onClick={handleSubmit(onSubmit)}
							disabled={!isEmpty(errors) || isSubmitting}
							startIcon={isSubmitting ? <CircularProgress size={20} /> : false}
						>
							{campaignDetails ? 'Update' : 'Create'}
						</Button>
					</Box>
				</Box>
			</form>
		</CustomModal>
	);
};

CreateCampaign.propTypes = {
	campaignDetails: PropTypes.any,
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired
};

CreateCampaign.defaultProps = {
	campaignDetails: null
};

export default CreateCampaign;
