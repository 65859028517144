import React from 'react';
import { useSelector } from 'react-redux';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const messagesSelector = (state) => state?.app?.messages;

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const SnackBar = () => {
	const messages = useSelector(messagesSelector);
	const hasMessages = messages.length > 0;

	return hasMessages
		? messages.map((message) => (
				<Snackbar open autoHideDuration={6000} key={message}>
					<Alert severity="success" sx={{ width: '100%' }}>
						{message}
					</Alert>
				</Snackbar>
		  ))
		: null;
};

export default SnackBar;
