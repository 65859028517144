import React from 'react';
import PropTypes from 'prop-types';

import LongRequestInfo from 'components/LongRequestInfo/LongRequestInfo';
import CustomModal from 'components/CustomModal/CustomModal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const CloneModal = ({
	isOpen,
	name,
	text,
	onSubmit,
	onClose,
	isLoading,
	cloneName,
	setCloneName
}) => {
	const handleSubmit = () => {
		onSubmit(cloneName);
	};

	const handleClose = () => {
		setCloneName('');
		onClose();
	};

	return (
		<CustomModal isOpen={isOpen} closeModal={handleClose} title={name}>
			<Typography id="modal-modal-description" mt={2} mb={1}>
				{text}
			</Typography>

			<TextField
				autoFocus
				margin="dense"
				id="cloneName"
				label="Clone Name"
				type="text"
				fullWidth
				required
				value={cloneName}
				onChange={(e) => setCloneName(e.target.value)}
			/>
			{isLoading && (
				<Stack direction="row" justifyContent="flex-end">
					<LongRequestInfo isLoading={isLoading} />
				</Stack>
			)}
			<Stack spacing={2} direction="row" justifyContent="flex-end" mt={3}>
				<Button variant="cancel" onClick={handleClose}>
					NO, CANCEL
				</Button>
				<Button
					disabled={!cloneName?.length || isLoading}
					variant="contained"
					onClick={handleSubmit}
					startIcon={isLoading ? <CircularProgress size={20} /> : false}
				>
					Submit
				</Button>
			</Stack>
		</CustomModal>
	);
};

CloneModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	cloneName: PropTypes.string.isRequired,
	setCloneName: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default CloneModal;
