import axios from 'axios';
import responseHandler from '../responseHandler';

const psBaseUrl = process.env.REACT_APP_PS_INSIGHTS;
const redirectUrl = process.env.REACT_APP_CC_REDIRECT_URL;
const psTokenUrl = `${psBaseUrl}/v1/my/user-token`;

const psInstance = axios.create({
	baseURL: psBaseUrl,
	// timeout: 45000,
	headers: { 'Content-Type': 'application/json' }
});

const login = async (username, password) =>
	psInstance
		.post(
			`/login`,
			{ username, password, cognito: true, postLoginRedirect: redirectUrl },
			{ withCredentials: true }
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

const logout = async () => psInstance.get(`/logout`);

const getToken = () =>
	psInstance
		.get(psTokenUrl, { withCredentials: true })
		.then(responseHandler.success)
		.catch(responseHandler.error);

const getUser = async () =>
	psInstance
		.get(`/v1/my/user`, { withCredentials: true })
		.then(responseHandler.success)
		.catch(responseHandler.error);

const getCountries = async (controller) =>
	psInstance
		.get(`/v1/client-country-list?services=wtb`, {
			withCredentials: true,
			headers: {},
			signal: controller ? controller.signal : null
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);

const createTokensPS = (clientId) => {
	const url = `/v1/wtb4/client-token`;
	return psInstance
		.post(url, { client_id: clientId }, { withCredentials: true })
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getTokensPS = (clientId) => {
	const url = `/v1/wtb4/client-tokens?client_id=${clientId}`;

	return psInstance
		.get(url, { withCredentials: true })
		.then((res) => {
			if (!res?.data?.tokens) {
				return createTokensPS(clientId);
			}
			return responseHandler.success(res);
		})
		.catch((error) => {
			// eslint-disable-next-line no-console
			console.log(error);
			return createTokensPS(clientId);
		});
};

const getProductMatchIdBySellerAndProduct = (productId, sellerId, locale) => {
	const url = `v1/product-match?countryCode=${locale}&sellerIds=${sellerId}&productIds=${productId}`;

	return psInstance
		.get(url, { withCredentials: true })
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getSellers = async () =>
	psInstance
		.get(`v1/my/sellers?type=wtb`, { withCredentials: true })
		.then(responseHandler.success)
		.catch(responseHandler.error);

const getProductsPS = (query) =>
	psInstance
		.get(
			`/v1/products/search?from=0&size=20&countryCode=${query.country_id}&searchKeyword=${query.q}`,
			{ withCredentials: true }
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

const createConfigurationId = async (payload) => {
	if (payload.config_id) {
		return psInstance
			.post(`/v1/wtb4/config`, payload, {
				withCredentials: true
			})
			.then(responseHandler.success)
			.catch(responseHandler.error);
	}

	return psInstance
		.put(`/v1/wtb4/config`, payload, {
			withCredentials: true
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getSingleNgConfiguration = (query) =>
	psInstance
		.get(
			`v1/wtb4/config?config_id=${query.configId}&client_id=${query.clientId}`,
			{
				withCredentials: true
			}
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

export default {
	login,
	logout,
	getToken,
	getUser,
	getCountries,
	getSellers,
	getProductsPS,
	getTokensPS,
	createConfigurationId,
	getSingleNgConfiguration,
	getProductMatchIdBySellerAndProduct,
	createTokensPS
};
