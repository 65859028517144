import httpClient from 'services/httpClient';
import responseHandler from 'services/responseHandler';

const BASE_URL = process.env.REACT_APP_CC_BASE_URL;
const serviceType = 'SHOPPABLE';

const getGlobalTranslations = (language, brandId, abort) => {
	let url = `${BASE_URL}/translation/?lang=${language}&service=${serviceType}`;
	if (brandId) url += `&manufacturer_id=${brandId}`;

	return httpClient.api
		.get(url, {
			withCredentials: false,
			signal: abort ? abort.signal : null
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getConfigLabelTranslation = (params) =>
	httpClient.api
		.get(
			`${BASE_URL}/translation/configurator?service=${serviceType}&label=${
				params.name
			}&channel=${params.section.toUpperCase()}&widgetId=${
				params.widgetid
			}&manufacturer_id=${params.manufacturer_id}`,
			{
				withCredentials: false
			}
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

const getAllConfigTranslations = (params) =>
	httpClient.api
		.get(
			`${BASE_URL}/translation/configurator/${params.widgetId}?service=${serviceType}&language=${params.defaultLanguage}&extend=true`,
			{
				withCredentials: false
			}
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

const getLanguages = () =>
	httpClient.api
		.get(`${BASE_URL}/translation/languages?service=${serviceType}`, {
			withCredentials: false
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);

const saveTranslation = (payload, brandId) => {
	// eslint-disable-next-line
	if (brandId) payload.manufacturer_id = brandId;
	return httpClient.api
		.post(
			`${BASE_URL}/translation`,
			{
				...payload,
				service: serviceType
			},
			{
				withCredentials: false
			}
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const saveConfigTranslation = (widgetId, payload) =>
	httpClient.api
		.post(
			`${BASE_URL}/translation/configurator/${widgetId}`,
			{
				...payload,
				service: 'SHOPPABLE'
			},
			{
				withCredentials: false
			}
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

const resetTranslation = (params, brandId) => {
	const translationParams = params
		? {
				lang: params.selectedLanguage,
				label: params.name || params.string_type,
				service: serviceType
		  }
		: {
				lang: params.selectedLanguage,
				service: serviceType
		  };

	if (brandId) {
		translationParams.manufacturer_id = brandId;
	}

	return httpClient.api
		.patch(
			`${BASE_URL}/translation/reset/${params.translationsId}`,
			translationParams,
			{
				withCredentials: false
			}
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getDefaultTranslations = ({ brandId, defaultLanguage }) => {
	let url = `${BASE_URL}/translation/?lang=${defaultLanguage}&service=SHOPPABLE`;
	if (brandId) url += `&manufacturer_id=${brandId}`;

	return httpClient.api
		.get(url, {
			withCredentials: false
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);
};
export default {
	getGlobalTranslations,
	getLanguages,
	resetTranslation,
	getConfigLabelTranslation,
	getAllConfigTranslations,
	saveTranslation,
	saveConfigTranslation,
	getDefaultTranslations
};
