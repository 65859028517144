import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useTourGuideContext } from 'contexts/TourGuide/TourGuideContext';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Skeleton from '@mui/material/Skeleton';

import DropdownMenu from '../DropdownMenu/DropdownMenu';

const PageHeader = ({
	title,
	actionButton,
	handleAction,
	actionName,
	breadcrumbs,
	showBackButton,
	showDropdownButton,
	dropdownOptions,
	children,
	defaultRoute
}) => {
	const navigate = useNavigate();
	const { handleTourStepName } = useTourGuideContext();

	useEffect(() => {
		const timeout = setTimeout(() => {
			handleTourStepName(actionName);
		}, 2500);

		return () => clearTimeout(timeout);
	}, [actionName, handleTourStepName]);

	const handleGoBack = () => {
		if (window.history.length > 1) {
			navigate(-1);
		} else {
			const route = defaultRoute || '/';
			navigate(route);
		}
	};

	const commonStyle = {
		position: 'absolute',
		right: '25px'
	};

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="flex-start"
			marginBottom={3}
			sx={{ maxWidth: '80%' }}
		>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="flex-start"
				marginBottom={3}
			>
				{showBackButton && (
					<IconButton onClick={() => handleGoBack()}>
						<ArrowBackIcon />
					</IconButton>
				)}
				<Box>
					{title ? (
						<Typography
							color="rgba(0, 0, 0, 0.87)"
							fontSize="24px"
							textTransform="capitalize"
							style={{
								wordWrap: 'break-word',
								overflow: 'hidden'
							}}
						>
							{title}
						</Typography>
					) : (
						<Skeleton variant="rectangular" height={36} width={200} />
					)}

					{breadcrumbs && breadcrumbs}
				</Box>
			</Box>
			{/* TODO: deprecate this in favour of rendering children */}
			{actionButton && (
				<div style={commonStyle}>
					<Button
						variant="contained"
						onClick={handleAction}
						startIcon={<AddOutlinedIcon />}
						sx={{ height: '40px' }}
						data-tour={actionName.split(' ').join('-').toLowerCase()}
					>
						<span>{actionName}</span>
					</Button>
				</div>
			)}

			{/* TODO: deprecate this in favour of rendering children */}
			{showDropdownButton && (
				<div style={commonStyle}>
					<DropdownMenu
						actionText={actionName}
						options={dropdownOptions}
						handleAction={handleAction}
					/>
				</div>
			)}

			{<div style={commonStyle}>{children && children}</div>}
		</Box>
	);
};

PageHeader.propTypes = {
	title: PropTypes.any,
	actionButton: PropTypes.bool,
	handleAction: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
	actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	breadcrumbs: PropTypes.any,
	showBackButton: PropTypes.bool,
	showDropdownButton: PropTypes.bool,
	dropdownOptions: PropTypes.array,
	children: PropTypes.node,
	defaultRoute: PropTypes.string
};

PageHeader.defaultProps = {
	title: '',
	actionButton: false,
	handleAction: null,
	actionName: null,
	breadcrumbs: null,
	showBackButton: false,
	showDropdownButton: null,
	dropdownOptions: [],
	children: null,
	defaultRoute: '/'
};

export default PageHeader;
