import { useNavigate, useLocation } from 'react-router-dom';

import queryString from 'query-string';

const useSetQueryParams = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const updateQueryParam = (key, newValue) => {
		const parsedQuery = queryString.parse(location.search);

		if (newValue) {
			parsedQuery[key] = newValue;
		} else {
			delete parsedQuery[key];
		}

		navigate({
			...location,
			search: queryString.stringify(parsedQuery)
		});
	};

	const updateQueryParams = (newParams, replace = false) => {
		const parsedQuery = queryString.parse(location.search);

		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of Object.entries(newParams)) {
			parsedQuery[key] = value;
		}

		navigate(
			{
				...location,
				search: queryString.stringify(parsedQuery)
			},
			{
				replace
			}
		);
	};

	const getQueryParams = () => {
		const parsedQuery = queryString.parse(location.search);

		return parsedQuery;
	};

	const removeQueryParam = (keys) => {
		const parsedQuery = queryString.parse(location.search);

		// Ensure keys is always an array to simplify logic
		const keysArray = Array.isArray(keys) ? keys : [keys];

		keysArray.forEach((key) => {
			delete parsedQuery[key];
		});

		navigate({
			...location,
			search: queryString.stringify(parsedQuery),
			replace: true
		});
	};

	const resetQueryParams = () => {
		const defaultQuery = 'sort=created_at&sort_type=desc';

		navigate({
			...location,
			search: defaultQuery
		});
	};

	return {
		getQueryParams,
		updateQueryParam,
		updateQueryParams,
		resetQueryParams,
		removeQueryParam
	};
};

export default useSetQueryParams;
