import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import localStorage from 'services/localStorage';
import { TourGuideContext } from './TourGuideContext';

import { TOUR_NAME, TOUR_STEP } from './tours';

const TourGuideProvider = ({ children }) => {
	const [tourStepName, setTourStepName] = useState('');
	const [basicTourUnpaused, setBasicTourUnpaused] = useState(false);
	const [activeTour, setActiveTour] = useState({});

	const handleTourStepName = useCallback((stepName) => {
		const tourName = localStorage.read(TOUR_NAME);

		if (tourName) {
			setTourStepName(stepName);
		}
	}, []);

	const setTourInfo = useCallback(() => {
		const tourName = localStorage.read(TOUR_NAME);
		const tourStep = localStorage.read(TOUR_STEP);

		setActiveTour({ tourName, tourStep });
	}, []);

	const handleActiveTour = (tourName) => {
		localStorage.write({
			tourName
		});

		setActiveTour((prevState) => ({
			...prevState,
			tourName
		}));
	};

	const handleTourStep = (tourStep) => {
		localStorage.write({
			tourStep
		});

		setActiveTour((prevState) => ({
			...prevState,
			tourStep
		}));
	};

	const handleEndTour = () => {
		localStorage.remove([TOUR_NAME, TOUR_STEP]);
	};

	useEffect(() => {
		setTourInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const providerValue = {
		basicTourUnpaused,
		setBasicTourUnpaused,
		activeTour,
		setTourInfo,
		handleActiveTour,
		handleTourStep,
		tourStepName,
		handleTourStepName,
		handleEndTour
	};

	return (
		<TourGuideContext.Provider value={providerValue}>
			{children}
		</TourGuideContext.Provider>
	);
};

TourGuideProvider.propTypes = {
	children: PropTypes.any
};

TourGuideProvider.defaultProps = {
	children: null
};

export default TourGuideProvider;
