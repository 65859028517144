import { useQuery } from '@tanstack/react-query';

import { useSelector } from 'react-redux';
import { useMergedUrlAndQueryParams } from 'hooks';
import apiCampaign from 'services/apiCampaigns';

import { formatParams, removeProperties } from 'utils';

const ALLOWED_PARAMS = [
	'active',
	'ids',
	'hashIds',
	'locales',
	'manufacturers',
	'created_at',
	'sort',
	'sort_type',
	'limit',
	'offset'
];

const interceptorReadySelector = (state) => state?.auth?.interceptorsReady;

const useGetCampaigns = (params) => {
	const interceptorReady = useSelector(interceptorReadySelector);

	return useQuery({
		queryKey: ['campaigns', params],
		queryFn: async () => {
			const response = await apiCampaign.getAllCampaigns(formatParams(params));

			return response?.data?.response;
		},
		enabled: interceptorReady,
		refetchOnWindowFocus: true,
		retry: 1,
		keepPreviousData: true
	});
};

const useCampaigns = () => {
	const { getParams } = useMergedUrlAndQueryParams();
	const params = getParams();

	const { data, isFetching, isLoading, refetch } = useGetCampaigns(
		removeProperties(params, ALLOWED_PARAMS)
	);
	const { campaigns, filters } = data ?? { campaigns: {}, filters: {} };

	return {
		campaigns: campaigns.data,
		filters,
		isLoading,
		isFetching,
		count: campaigns.count,
		refetch
	};
};

export default useCampaigns;
