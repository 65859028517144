import { css } from '@emotion/react';

export const GlobalStyle = css`
	:root {
		--star-size: 12px;
		--star-color: #fff;
		--star-background: #fc0;
	}

	.starsRating {
		--percent: calc(var(--rating) / 5 * 100%);

		display: inline-block;
		font-size: var(--star-size);
		font-family: Times;
		line-height: 1;

		&::before {
			content: '★★★★★';
			letter-spacing: 3px;
			background: linear-gradient(
				90deg,
				var(--star-background) var(--percent),
				var(--star-color) var(--percent)
			);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	* {
		box-sizing: border-box;
	}

	html,
	body {
		height: 100%;
		margin: 0;
		box-sizing: border-box;
		font-family: 'Roboto' !important;

		@font-face {
			font-family: 'Roboto';
			src: url('/fonts/Roboto-Regular.ttf') format('truetype');
			font-weight: normal;
			font-style: normal;
		}

		@font-face {
			font-family: 'Roboto';
			src: url('/fonts/Roboto-Light.ttf') format('truetype');
			font-weight: 300;
			font-style: normal;
		}

		@font-face {
			font-family: 'Roboto';
			src: url('/fonts/Roboto-Medium.ttf') format('truetype');
			font-weight: 500;
			font-style: normal;
		}

		@font-face {
			font-family: 'Roboto';
			src: url('/fonts/Roboto-Bold.ttf') format('truetype');
			font-weight: bold;
			font-style: normal;
		}

		.drag-icon {
			cursor: move;
		}
	}

	#root {
		min-height: 100%;
	}

	body {
		.truncate-without-expand {
			width: 330px;
			padding-right: 20px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.truncate {
			width: 20vw;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 10px;

			:hover {
				white-space: normal;
				cursor: pointer;
			}

			@media (max-width: 1400px) {
				width: 160px;
			}
		}
	}

	.editor {
		position: relative;
		background: #fff;
		max-width: 312px;
		margin-left: 8px;
		margin-top: 30px !important;
		margin-bottom: 20px !important;

		.manage:hover {
			cursor: pointer;
		}
	}

	.ql-container {
		min-height: 50px;
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}

	.ql-toolbar {
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;
	}

	.ql-size-small {
		font-size: 0.75em;
	}

	.ql-size-large {
		font-size: 1.5em;
	}

	.ql-size-huge {
		font-size: 2.5em;
	}

	.ql-align-right {
		text-align: right;
	}

	.ql-align-center {
		text-align: center;
	}

	.ql-bold {
		font-weight: bold;
	}

	.ql-align-justify {
		text-align: justify;
	}

	.ql-error {
		.ql-toolbar {
			border: 1px solid red;
		}

		.ql-container {
			border: 1px solid red;
		}
	}

	.ql-editor {
		max-height: 300px;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
	}

	.ql-tooltip {
		top: -60.5px !important;
		margin-top: 0 !important;

		&:before {
			content: 'Link' !important;
		}
		.ql-preview {
			max-width: 110px !important;
		}

		.ql-preview:before {
			display: none;
		}

		input {
			width: 130px;
		}
		/* left: -15px !important; */
	}

	.colorPickerInput {
		input {
			caret-color: transparent !important;
		}
	}

	.ql-tooltip {
		left: 0 !important;
	}

	.tooltip-autocomplete
		+ .MuiAutocomplete-popperDisablePortal
		.MuiAutocomplete-listbox
		li[aria-disabled='true'] {
		pointer-events: inherit !important;
		opacity: 1 !important;
		cursor: not-allowed !important;

		p {
			opacity: 0.38 !important;
		}
	}

	.tooltip-autocomplete
		+ .MuiAutocomplete-popperDisablePortal
		.MuiAutocomplete-listbox
		li[aria-disabled='true']:hover,
	.tooltip-autocomplete
		.MuiAutocomplete-listbox
		li[aria-disabled='true']:focus {
		background: white !important;
	}

	.tooltip-autocomplete
		+ .MuiAutocomplete-popperDisablePortal
		.MuiAutocomplete-listbox
		li[aria-disabled='true']:active {
		background: white !important;
		pointer-events: none !important;
		// opacity: 0.38 !important;
	}

	input[type='file'] {
		visibility: hidden;
	}
`;
