import React from 'react';
import {
	SVGIcon,
	IconPropTypes,
	defaultIconSize,
	defaultIconColor
} from '../SVGIcon';

const OutOfStock = ({ color = defaultIconColor, size = defaultIconSize }) => (
	<SVGIcon
		width={size}
		height={size}
		viewBox="0 0 24 24"
		title="Out Of Stock Icon"
		uid="cc-icon-out-of-stock"
	>
		<path
			fill={color}
			d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"
		/>
	</SVGIcon>
);

OutOfStock.propTypes = IconPropTypes;

export default OutOfStock;
