import React, { useState, useEffect } from 'react';

import { useModalState, MODALS } from 'contexts/ModalProvider';
import { FormProvider, useForm } from 'react-hook-form';
import useCampaigns from 'modules/Campaign/hooks/useCampaigns';

import PageHeader from 'components/PageHeader/PageHeader';
import { Filters } from 'components/FiltersV2';
import CampaignMUITable from 'modules/Campaign/components/CampaignMUITable/CampaignMUITable';
import CreateCampaign from 'modules/Campaign/components/CreateCampaign/CreateCampaign';

export const Campaign = () => {
	const { campaigns, count, isLoading, isFetching, filters, refetch } =
		useCampaigns();
	const { isOpen, openModal, closeModal } = useModalState(
		MODALS.CAMPAIGN.create
	);

	const methods = useForm({
		mode: 'onChange',
		defaultValues: {
			name: '',
			manufacturer_id: null,
			country: null,
			end_date: null,
			active: 'active',
			token: null
		}
	});
	const [campaignDetails, setCampaignDetails] = useState(null);

	// --- in case user is coming from help section
	useEffect(() => {
		const activateCreateModal = sessionStorage.getItem('create') ?? false;

		if (activateCreateModal) {
			openModal();
			sessionStorage.removeItem('create');
		}
	}, [openModal]);

	const openCampaignModal = (campaign) => {
		if (campaign.name) {
			setCampaignDetails(campaign);
		}

		openModal();
	};

	const closeCampaignModal = (reload) => {
		const abortController = new AbortController();

		methods.reset();
		setCampaignDetails(null);
		closeModal();

		if (typeof reload === 'boolean') {
			refetch();
		}

		return () => abortController.abort();
	};

	return (
		<FormProvider {...methods}>
			<PageHeader
				title="Campaigns"
				actionButton
				actionName="New Campaign"
				handleAction={openCampaignModal}
			/>

			{isOpen && (
				<CreateCampaign
					campaignDetails={campaignDetails}
					isOpen={isOpen}
					closeModal={closeCampaignModal}
				/>
			)}

			<Filters page="CAMPAIGNS" apiFilters={filters} isLoading={isLoading} />

			<CampaignMUITable
				campaigns={campaigns}
				count={count}
				isLoading={isFetching}
				openCreateCampaignModal={openCampaignModal}
				refetch={refetch}
			/>
		</FormProvider>
	);
};
