import { PLATFORMS } from 'constants';
import { useEffect, useState } from 'react';

export const useUserCountryList = (userCountries, origin, selectedBrand) => {
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		if (selectedBrand && origin !== PLATFORMS.cc) {
			switch (origin) {
				case PLATFORMS.ps: {
					const availableCountriesPS = userCountries.filter((country) =>
						selectedBrand.locales.includes(country.value.locale)
					);
					setCountries(availableCountriesPS);
					break;
				}

				case PLATFORMS.hatch: {
					const availableCountriesHatch = userCountries.filter(
						(country) =>
							selectedBrand.value.countries?.length &&
							selectedBrand.value.countries.includes(country.value.id)
					);
					setCountries(availableCountriesHatch);
					break;
				}

				case PLATFORMS.cc: {
					if (!countries.length) {
						setCountries(userCountries);
					}
					break;
				}

				default:
					break;
			}
		} else if (!selectedBrand && origin !== PLATFORMS.cc) {
			setCountries([]);
		} else {
			setCountries(userCountries);
		}
	}, [origin, selectedBrand, userCountries, countries.length]);

	return countries;
};
