import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: '80vw',
	minWidth: '450px',
	maxHeight: '90vh',
	overflow: 'auto',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: '16px 24px',
	transition: 'all .2s'
};

const CustomModal = React.forwardRef(
	(
		{
			children,
			isOpen,
			closeModal,
			title,
			customStyle = {},
			displayCloseIcon = true,
			...props
		},
		ref
	) => (
		<Modal {...props} open={isOpen} onClose={closeModal}>
			<Box sx={{ ...style, ...customStyle }} ref={ref} data-testid="FullModal">
				<Box display="flex" justifyContent="space-between" alignItems="center">
					{title?.length && <Typography variant="h6">{title}</Typography>}
					{displayCloseIcon && (
						<IconButton onClick={closeModal}>
							<CloseIcon />
						</IconButton>
					)}
				</Box>
				{children}
			</Box>
		</Modal>
	)
);

CustomModal.propTypes = {
	children: PropTypes.any.isRequired,
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	title: PropTypes.string,
	customStyle: PropTypes.object,
	displayCloseIcon: PropTypes.bool
};

CustomModal.defaultProps = {
	title: '',
	customStyle: {},
	displayCloseIcon: true
};

export default CustomModal;
