export const actionTypes = {
	APP_LOADING_SUCCESS: 'APP/LOADING_SUCCESS',

	APP_MESSAGE_SET: 'APP/MESSAGE_SET'
};

export const initialState = {
	appLoading: true,
	messages: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.APP_LOADING_SUCCESS:
			return {
				...state,
				appLoading: false
			};
		case actionTypes.APP_MESSAGE_SET:
			return {
				...state,
				messages: [...state.messages, action.message]
			};
		default:
			return state;
	}
};

export const actionCreators = {
	completeLoading: () => ({
		type: actionTypes.APP_LOADING_SUCCESS
	}),
	setMessage: (message) => ({
		type: actionTypes.APP_MESSAGE_SET,
		message
	})
};
