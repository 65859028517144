import { useState } from 'react';

const useMessage = () => {
	const [message, setMessage] = useState(null);

	const sendMessage = (msg) => {
		setMessage(msg);
	};

	return { message, sendMessage };
};

export default useMessage;
