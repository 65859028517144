import { PLATFORMS } from 'constants';
import * as yup from 'yup';

const schema = (origin) =>
	yup.object().shape({
		name: yup
			.string()
			.trim()
			.required('Name is required')
			.max(90, "Name can't be longer than 90 characters")
			.min(3, 'Name must be at least 3 characters'),
		manufacturer_id:
			origin === PLATFORMS.cc
				? yup.mixed().nullable().notRequired()
				: yup.object().required('Brand is required'),
		country: yup.object().required('Country is required'),
		end_date: yup.date().notRequired(),
		token:
			origin !== PLATFORMS.hatch
				? yup.object().required('Token is required')
				: yup.object().nullable().notRequired(),
		active: yup.string().required().oneOf(['active', 'inactive'])
	});

export default schema;
