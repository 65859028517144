import * as React from 'react';
import PropTypes from 'prop-types';

import { useActiveConfigurations } from 'hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import CustomModal from 'components/CustomModal/CustomModal';

const SafetyNetModal = ({
	count,
	title,
	type,
	filterName,
	entityId,
	isSubmitting,
	isOpen,
	closeModal,
	handleSubmit,
	showActions
}) => {
	const { data, isLoading } = useActiveConfigurations(entityId, {
		filter: filterName
	});

	const landingPages = data?.response ?? [];

	const activeLandingPages = count || landingPages?.length;

	const handleCloseModal = React.useCallback(() => {
		closeModal('close');
	}, [closeModal]);

	const handleGoBack = React.useCallback(() => {
		closeModal('go-back');
	}, [closeModal]);

	return (
		<CustomModal
			isOpen={isOpen}
			closeModal={handleCloseModal}
			title={`${title} Landing Pages`}
			customStyle={{ minWidth: '900px' }}
		>
			<Box sx={{ position: 'relative' }}>
				{/* Info */}
				<Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
					This {type} is active on {activeLandingPages} landing{' '}
					{activeLandingPages === 1 ? 'page' : 'pages'}. Changes will be applied
					to respective landing {activeLandingPages === 1 ? 'page' : 'pages'}.
				</Alert>

				{/* Body */}
				{isLoading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						minHeight="100px"
					>
						<CircularProgress size={35} />
					</Box>
				) : (
					<TableContainer
						sx={{
							marginBottom: '24px',
							border: '1px solid rgba(0, 0, 0, 0.12)',
							borderRadius: '4px'
						}}
					>
						<Table
							sx={{
								width: '850px'
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>Landing Page</TableCell>
									<TableCell>Campaign</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{landingPages?.map((row) => (
									<TableRow
										key={row.landingPageId}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell>{row.landingPageName}</TableCell>
										<TableCell>{row.campaignName}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}

				{/* Actions */}
				<Box
					sx={{
						position: 'sticky',
						bottom: '-16px',
						width: '100%',
						background: 'white',
						paddingBottom: landingPages?.length > 17 ? '16px' : '0',
						paddingTop: landingPages?.length > 17 ? '10px' : '0'
					}}
					display="flex"
					justifyContent="space-between"
				>
					<Button
						sx={{ marginLeft: showActions ? 'none' : 'auto' }}
						variant="cancel"
						onClick={handleCloseModal}
					>
						Cancel
					</Button>

					{showActions && (
						<>
							<Button
								variant="outlined"
								onClick={handleGoBack}
								startIcon={<ArrowBackIcon />}
								sx={{ marginLeft: 'auto', marginRight: '8px' }}
							>
								Back
							</Button>

							<Button
								variant="contained"
								type="submit"
								onClick={handleSubmit}
								disabled={isSubmitting}
								startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
							>
								Confirm Changes
							</Button>
						</>
					)}
				</Box>
			</Box>
		</CustomModal>
	);
};

SafetyNetModal.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	filterName: PropTypes.string.isRequired,
	entityId: PropTypes.number.isRequired,
	isSubmitting: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func,
	isOpen: PropTypes.bool.isRequired,
	showActions: PropTypes.bool,
	count: PropTypes.number
};

SafetyNetModal.defaultProps = {
	isSubmitting: false,
	showActions: true,
	handleSubmit: () => {},
	count: undefined
};

export default SafetyNetModal;
