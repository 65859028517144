import React from 'react';
import PropTypes from 'prop-types';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Datepicker = ({ label, size = 'small', value, onChange, helperText }) => (
	<LocalizationProvider dateAdapter={AdapterDateFns}>
		<DatePicker
			size={size}
			label={label}
			value={value}
			onChange={(newValue) => onChange(newValue)}
			slotProps={{
				textField: {
					helperText
				}
			}}
		/>
	</LocalizationProvider>
);

Datepicker.propTypes = {
	label: PropTypes.string.isRequired,
	size: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	helperText: PropTypes.string
};

Datepicker.defaultProps = {
	value: null,
	size: '',
	helperText: ''
};

export default Datepicker;
