const LocalStorageRead = (state) => {
	if (Object.prototype.hasOwnProperty.call(localStorage, state)) {
		let value = localStorage.getItem(state);

		try {
			value = JSON.parse(value);
			return value;
		} catch (e) {
			return value;
		}
	} else {
		return '';
	}
};

const LocalStorageSave = (state) => {
	Object.keys(state).forEach((key) => {
		localStorage.setItem(key, JSON.stringify(state[key]));
	});
};

const LocalStorageRemove = (state) => {
	const keys = Array.isArray(state) ? state : Object.keys(state);
	keys.forEach((key) => {
		localStorage.removeItem(key, JSON.stringify(state[key]));
	});
};

export default {
	read: LocalStorageRead,
	write: LocalStorageSave,
	remove: LocalStorageRemove
};
