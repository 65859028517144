import { useCallback, useMemo } from 'react';

import { useUserData } from './user';

import useMergedUrlAndQueryParams from './useMergedUrlAndQueryParams';
import { PLATFORMS } from 'constants';

/**
 * Filter settings per page
 * Defines what filters will be rendered on what page
 */
const FILTERS = {
	CAMPAIGNS: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		hashIds: {
			name: 'Campaign Id',
			param: 'hashIds'
		},
		status: {
			name: 'Status',
			param: 'active'
		},
		manufacturers: {
			name: 'Brand',
			param: 'manufacturers'
		},
		locales: {
			name: 'Country',
			param: 'locales'
		}
	},
	LANDING_PAGE: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		hashIds: {
			name: 'Landing Page Id',
			param: 'hashIds'
		},
		status: {
			name: 'Status',
			param: 'active'
		},
		type: {
			name: 'Type',
			param: 'types'
		},
		createdBy: {
			name: 'Created By',
			param: 'created_by'
		}
	},
	TRACKING_PROFILES: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		status: {
			name: 'Status',
			param: 'active'
		},
		manufacturers: {
			name: 'Brand',
			param: 'manufacturers'
		},
		type: {
			name: 'Tracking Provider',
			param: 'types'
		},
		locales: {
			name: 'Country',
			param: 'locales'
		}
	},
	CUSTOM_FONTS: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		status: {
			name: 'Status',
			param: 'active'
		},
		manufacturers: {
			name: 'Brand',
			param: 'manufacturers'
		}
	},
	RETAILERS: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		status: {
			name: 'Status',
			param: 'active'
		},
		manufacturers: {
			name: 'Brand',
			param: 'manufacturers'
		},
		locales: {
			name: 'Country',
			param: 'locales'
		},
		type: {
			name: 'Type',
			param: 'types'
		}
	},
	MEDIA: {
		type: {
			name: 'File Type',
			param: 'types'
		},
		activeOnLp: {
			name: 'Landing Page Usage',
			param: 'activeOnLp'
		},
		keywords: {
			name: 'Keywords',
			param: 'keywords'
		}
	},
	MEDIA_CONFIGURATOR: {
		activeOnLp: {
			name: 'Landing Page Usage',
			param: 'activeOnLp'
		},
		keywords: {
			name: 'Keywords',
			param: 'keywords'
		}
	},
	LINKS: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		linkIds: {
			name: 'Link ID',
			param: 'linkIds'
		},
		status: {
			name: 'Status',
			param: 'active'
		},
		manufacturers: {
			name: 'Brand',
			param: 'manufacturers'
		},
		locales: {
			name: 'Country',
			param: 'locales'
		},
		type: {
			name: 'Type',
			param: 'types'
		},
		createdBy: {
			name: 'Created By',
			param: 'created_by'
		}
	},
	PRESETS: {
		ids: {
			name: 'Name',
			param: 'ids'
		},
		manufacturers: {
			name: 'Brand',
			param: 'manufacturers'
		},
		type: {
			name: 'Type',
			param: 'types'
		},
		createdBy: {
			name: 'Created By',
			param: 'created_by'
		}
	}
};

const isFilterSelected = (params, paramName, item) => {
	const itemValue = typeof item === 'number' ? item.toString() : item;
	const value = params[paramName];

	return value ? !!value.split(',').some((val) => val === itemValue) : false;
};

const renameTypeFilters = (label, type) => {
	const productTypes = ['shoppable-product', 'shoppable-dtr-product'];

	if (type === 'Tracking Provider') {
		return label === 'GTM' ? 'Google Tag Manager' : 'Tealium';
	}

	if (type === 'File Type') {
		return label === 'image' ? 'Image' : 'Video';
	}

	return productTypes.includes(label) ? 'Product' : 'Brand';
};

/**
 * A custom hook that formats filters based on the given page, filters object, and parameters.
 *
 * @export
 * @param {string} page - The name of the page where filters will be applied.
 * @param {Object} filters - The filters object containing various filter fields and their values.
 *
 * @returns {Array} formattedFilters - An array of formatted filter objects,
 * each containing a position, name, list of items, a flag indicating whether the
 * filter supports search functionality, and the parameter name for the filter.
 */
const useGeneratePageFilters = (page, filters) => {
	const { getParams } = useMergedUrlAndQueryParams();
	const { origin } = useUserData();
	const isCC = origin === PLATFORMS.cc;

	const FILTER_GROUP = useMemo(() => FILTERS[page], [page]);
	const params = getParams();
	const paramsMemo = useMemo(() => params, [params]);

	const generateFilters = useCallback(() => {
		if (!filters) {
			return [];
		}

		const data = Object.keys(filters).map((key) => {
			const filtersData = filters;

			switch (key) {
				case 'idsFields':
					return {
						display: true,
						position: 0,
						name: FILTER_GROUP.ids.name,
						fieldName: key,
						items: filtersData.idsFields.map((item) => ({
							label: item.name,
							value: item.id,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.ids.param,
								item.id
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.ids.param
					};
				case 'idFields':
					return {
						display: true,
						position: 1,
						name: FILTER_GROUP.ids.name,
						fieldName: key,
						items: filtersData.idFields.map((item) => ({
							label: item.name,
							value: item.id,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.ids.param,
								item.id
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.ids.param
					};
				case 'linkIdFields':
					return {
						display: true,
						position: 2,
						name: FILTER_GROUP.linkIds.name,
						fieldName: key,
						items: filtersData.linkIdFields.map((item) => ({
							label: item.name.toString(),
							value: item.id,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.linkIds.param,
								item.id
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.linkIds.param
					};
				case 'hashIdFields':
					return {
						display: true,
						position: 3,
						name: FILTER_GROUP.hashIds.name,
						fieldName: key,
						items: filtersData.hashIdFields.map((item) => ({
							label: item.name,
							value: item.id,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.hashIds.param,
								item.id
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.hashIds.param
					};
				case 'activeFields':
					return {
						display: true,
						position: 4,
						name: FILTER_GROUP.status.name,
						fieldName: key,
						items: filtersData.activeFields.map((item) => ({
							label: item ? 'Active' : 'Inactive',
							value: item,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.status.param,
								item.toString()
							)
						})),
						withSearch: false,
						paramName: FILTER_GROUP.status.param
					};
				case 'typeFields':
					return FILTER_GROUP?.type?.name
						? {
								display:
									origin === PLATFORMS.ps ||
									FILTER_GROUP.type.name === 'Tracking Provider' ||
									FILTER_GROUP.type.name === 'File Type',
								position: 5,
								name: FILTER_GROUP.type.name,
								fieldName: key,
								items: filtersData.typeFields.map((item) => ({
									label: renameTypeFilters(item, FILTER_GROUP.type.name),
									value: item,
									isSelected: isFilterSelected(
										paramsMemo,
										FILTER_GROUP.type.param,
										item
									)
								})),
								withSearch: false,
								paramName: FILTER_GROUP.type.param
						  }
						: null;
				case 'activeOnLpFields':
					return {
						display: true,
						position: 6,
						name: FILTER_GROUP.activeOnLp.name,
						fieldName: key,
						items: filtersData.activeOnLpFields.map((item) => ({
							label: item ? 'Used' : 'Not used',
							value: item,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.activeOnLp.param,
								item.toString()
							)
						})),
						withSearch: false,
						paramName: FILTER_GROUP.activeOnLp.param
					};
				case 'manufacturerFields':
					return FILTER_GROUP?.manufacturers
						? {
								display: !isCC,
								position: 7,
								name: FILTER_GROUP?.manufacturers?.name,
								fieldName: key,
								items: filtersData.manufacturerFields.map((item) => ({
									label: item?.name,
									value: item?.id,
									isSelected: isFilterSelected(
										paramsMemo,
										FILTER_GROUP?.manufacturers?.param,
										item?.id
									)
								})),
								withSearch: true,
								paramName: FILTER_GROUP.manufacturers.param
						  }
						: null;
				case 'localeFields':
					return {
						display: true,
						position: 8,
						name: FILTER_GROUP.locales.name,
						fieldName: key,
						items: filtersData.localeFields.map((item) => ({
							label: item?.name,
							value: item?.id,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.locales.param,
								item?.id
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.locales.param
					};
				case 'createdByFields':
					return {
						display: true,
						position: 9,
						name: FILTER_GROUP.createdBy.name,
						fieldName: key,
						items: filtersData.createdByFields.map((item) => ({
							label: item,
							value: item,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.createdBy.param,
								item
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.createdBy.param
					};
				case 'keywordsFields':
					return {
						display: true,
						position: 10,
						name: FILTER_GROUP.keywords.name,
						fieldName: key,
						items: filtersData.keywordsFields.map((item) => ({
							label: item.name,
							value: item.id,
							isSelected: isFilterSelected(
								paramsMemo,
								FILTER_GROUP.keywords.param,
								item.id
							)
						})),
						withSearch: true,
						paramName: FILTER_GROUP.keywords.param
					};
				default:
					return null;
			}
		});

		const formatted = data
			.filter((item) => !!item)
			.sort((a, b) => a.position - b.position);

		return formatted;
	}, [FILTER_GROUP, origin, filters, paramsMemo, isCC]);

	return { generateFilters };
};

export default useGeneratePageFilters;
