import { PLATFORMS } from 'constants';
import { actionCreators as authActionCreators } from 'redux/ducks/authDuck';
import { actionCreators as appActionCreators } from 'redux/ducks/appDuck';
import { history } from 'redux/store';

import localStorage from 'services/localStorage';
import psApi from './ps';
import ccApi from './cc';
import hatchApi from './hatch';

const authoriseCommerceConnector = async (store) => {
	const { data } = await ccApi.getToken();
	const { atoken } = data;

	if (atoken) {
		localStorage.write({ token: atoken, platform: PLATFORMS.cc });
		store.dispatch(authActionCreators.setOrigin(PLATFORMS.cc));
		store.dispatch(authActionCreators.setCCToken(atoken));
		store.dispatch(authActionCreators.getCCUser(store, history));

		return true;
	}

	throw new Error('nok');
};

const authorisePriceSpider = async (store) => {
	const data = await psApi.getToken();
	const { jwt } = data?.data;

	if (jwt) {
		localStorage.write({ token: jwt, platform: PLATFORMS.ps });
		store.dispatch(authActionCreators.setOrigin(PLATFORMS.ps));
		store.dispatch(authActionCreators.setPSToken(jwt));
		store.dispatch(authActionCreators.getPSUser(store, history));

		return true;
	}

	throw new Error('nok');
};

const authoriseWithCookies = async (store) => {
	try {
		return await authorisePriceSpider(store);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log('error', error);
		return authoriseCommerceConnector(store);
	}
};

const logout = (store) => {
	localStorage.remove(['token', 'platform', 'refresh']);
	store.dispatch(appActionCreators.completeLoading());
};

const determineHost = async (store) => {
	const token = localStorage.read('token');
	const refreshToken = localStorage.read('refresh');
	const platform = localStorage.read('platform');

	const ott = new URLSearchParams(window.location.search).get('ott');

	if (ott) {
		try {
			const { data } = await hatchApi.checkOttAuthorisation(ott);

			localStorage.write({
				token: data.accessToken,
				refresh: data.refreshToken,
				platform: PLATFORMS.hatch
			});

			store.dispatch(
				authActionCreators.setHatchTokens({
					refresh_token: data.refreshToken,
					access_token: data.accessToken
				})
			);

			store.dispatch(
				authActionCreators.setLoginOnRefresh({
					refresh_token: data.refreshToken,
					access_token: data.accessToken,
					origin: PLATFORMS.hatch
				})
			);

			store.dispatch(authActionCreators.getHatchUser(store, history));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}

		return true;
	}

	/**
	 * If no platform present, because of the way how authorisation is done on CC & PS side
	 * we need to check for authorisation of these 2 platform by sending request with withCredentials: true
	 */
	if (!platform) {
		try {
			await authoriseWithCookies(store);
		} catch (error) {
			logout(store);
		}
	}

	// if platform is present we want to refresh token for CC
	if (platform === PLATFORMS.cc) {
		try {
			await authoriseCommerceConnector(store);
		} catch (error) {
			logout(store);
		}
	}

	// if platform is present we want to refresh token for PS
	if (platform === PLATFORMS.ps) {
		try {
			await authorisePriceSpider(store);
		} catch (error) {
			logout(store);
		}
	}

	if (platform === PLATFORMS.hatch) {
		if (token && refreshToken) {
			store.dispatch(
				authActionCreators.setHatchTokens({
					refresh_token: refreshToken,
					access_token: token
				})
			);
			store.dispatch(
				authActionCreators.setLoginOnRefresh({
					refresh_token: refreshToken,
					access_token: token,
					origin: PLATFORMS.hatch
				})
			);
			store.dispatch(authActionCreators.getHatchUser(store, history));
		} else {
			logout(store);
		}
	}

	return true;
};

export default {
	determineHost
};
