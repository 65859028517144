import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

const EmptyResults = (props) => {
	const { title, text, children } = props;
	const theme = useTheme();

	return (
		<Box
			display="flex"
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			padding="20px 0"
			minHeight="300px"
			position="relative"
			zIndex="1"
		>
			<img
				width="102px"
				src="/assets/images/no-data-art.svg"
				alt="no results"
			/>

			<Typography
				sx={{ color: theme.palette.light.text.secondary, fontSize: '20px' }}
			>
				{title}
			</Typography>
			<Typography
				sx={{ color: theme.palette.light.text.secondary, fontSize: '12px' }}
			>
				{text}
			</Typography>
			{children && <Box sx={{ marginTop: '20px' }}>{children}</Box>}
		</Box>
	);
};

EmptyResults.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	children: PropTypes.node
};

EmptyResults.defaultProps = {
	text: '',
	children: null
};

export default EmptyResults;
