import { useEffect, useState, useCallback } from 'react';

/**
 * Global object for storing state values associated with specific keys.
 * @type {Object.<string, *>}
 */

const store = {};

/**
 * Global object for maintaining lists of listeners for each state key.
 * @type {Object.<string, Function[]>}
 */

const listeners = {};

/**
 * Custom React Hook for managing and listening to state associated with specific keys.
 *
 * @param {string} key - A unique identifier for the state.
 * @param {*} initialValue - The initial value for the state, used if the state doesn't exist.
 *
 * @returns {[*, function]} - An array containing two elements:
 *   - The current state value associated with the provided key.
 *   - A function to update the state associated with the provided key.
 *
 * @example
 * // Usage:
 * // Import the hook:
 * // import useStateListener from 'hooks';
 *
 * // Create and manage state for 'count1' with an initial value of 0:
 * // const [count1, setCount1] = useStateListener('count1', 0);
 *
 * // Create and manage state for 'count2' with an initial value of 0:
 * // const [count2, setCount2] = useStateListener('count2', 0);
 */

const useStateListener = (key, initialValue) => {
	const [state, _setState] = useState(store[key] || initialValue);
	const setState = useCallback(
		(stateOrSetter) => {
			let next = stateOrSetter;
			if (typeof stateOrSetter === 'function') {
				next = stateOrSetter(store[key]);
			}
			listeners[key].forEach((l) => l(next));
			store[key] = next;
		},
		[key]
	);

	useEffect(() => {
		if (!store[key]) {
			store[key] = initialValue;
		}

		if (!listeners[key]) {
			listeners[key] = [];
		}

		const listener = (obsState) => _setState(obsState);
		listeners[key].push(listener);

		return () => {
			const index = listeners[key].indexOf(listener);
			listeners[key].splice(index, 1);
		};
	}, [initialValue, key]);

	return [state, setState];
};

export default useStateListener;
