import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { tooltipSettings, getEndDateInfo } from '../../utils/campaignUtils';

export const CampaginTimebox = ({ endDate }) => {
	const timeframe = getEndDateInfo(endDate);
	const { end, daysLeft } = timeframe;

	if (daysLeft !== null && daysLeft < 3) {
		return (
			<Box display="flex" alignItems="center" gap="5px">
				<Tooltip
					placement="top"
					title={
						daysLeft < 0
							? tooltipSettings.expired.text
							: tooltipSettings.expiring_soon.text
					}
				>
					<InfoOutlinedIcon
						fontSize="12px"
						sx={{
							color:
								daysLeft < 0
									? tooltipSettings.expired.color
									: tooltipSettings.expiring_soon.color
						}}
					/>
				</Tooltip>
				{end}
			</Box>
		);
	}

	return <Box>{end}</Box>;
};

CampaginTimebox.propTypes = {
	endDate: PropTypes.any
};

CampaginTimebox.defaultProps = {
	endDate: null
};

export default CampaginTimebox;
