import { PLATFORMS } from 'constants';
import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { isEmpty } from 'utils';

const userSelector = (state) => state?.auth?.user;
const originSelector = (state) => state?.auth?.origin;

const CC_INTERNAL_USER_FLAG = 't';
const isStage = process.env.REACT_APP_IS_STAGE === 'true';
const INTERNAL_USER_EMAILS = [
	'@odt.net',
	'@commerce-connector.com',
	'@pricespider.com'
];

const EXCEPTIONS = [
	'kathrin@commerce-connector.com',
	'aleksandar.mikic@odt.net'
];

const Pendo = () => {
	const user = useSelector(userSelector);
	const origin = useSelector(originSelector);

	const getBrand = useCallback(
		(userInfo) => {
			let brand;

			switch (origin) {
				case PLATFORMS.cc:
					brand = userInfo.payload.user_info.manufacturer_name;
					break;
				case PLATFORMS.hatch:
					brand = userInfo.organizationName;
					break;
				default:
					brand = userInfo.client.name;
					break;
			}

			return brand;
		},
		[origin]
	);

	const isCCEmulatingUser = useCallback(
		(userInfo) => {
			if (origin === PLATFORMS.cc && userInfo?.payload?.emulated_by) {
				const isAllowed = EXCEPTIONS.includes(userInfo?.payload?.emulated_by);

				if (isAllowed) {
					return false;
				}

				return !!INTERNAL_USER_EMAILS.find((email) =>
					userInfo?.payload?.emulated_by?.includes(email)
				);
			}

			return false;
		},
		[origin]
	);

	const isCCInternalUser = useCallback(
		(userInfo) => {
			if (origin === PLATFORMS.cc) {
				const isAllowed = EXCEPTIONS.includes(userInfo?.payload?.emulated_by);

				return (
					userInfo?.payload?.user_info?.gender === CC_INTERNAL_USER_FLAG &&
					!isAllowed
				);
			}

			return false;
		},
		[origin]
	);

	useEffect(() => {
		if (
			user &&
			!isStage &&
			!isEmpty(user) &&
			!isCCInternalUser(user) &&
			!isCCEmulatingUser(user)
		) {
			const { id, user_id: userId } = user;

			window.pendo.initialize({
				visitor: {
					id: id ?? userId, // Required if user is logged in, default creates anonymous ID
					// email:        // Recommended if using Pendo Feedback, or NPS Email
					// full_name:    // Recommended if using Pendo Feedback
					// role:         // Optional
					origin
					// You can add any additional visitor level key-values here,
					// as long as it's not one of the above reserved names.
				},

				account: {
					id: id ?? userId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
					brand: getBrand(user)
					// You can add any additional visitor level key-values here,
					// as long as it's not one of the above reserved names.
				}
			});
		}
	}, [getBrand, isCCEmulatingUser, isCCInternalUser, origin, user]);

	return null;
};

export default Pendo;
