export const actionTypes = {
	TRANSLATION_LANGUAGES_REQUESTED: 'TRANSLATION/LANGUAGES_REQUEST',
	TRANSLATION_LANGUAGES_SUCCEEDED: 'TRANSLATION/LANGUAGES_SUCCEEDED',
	TRANSLATION_LANGUAGES_FAILED: 'TRANSLATION/LANGUAGES_FAILED'
};

export const initialState = {
	languages: [],
	isLoading: false,
	error: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TRANSLATION_LANGUAGES_REQUESTED:
			return {
				...state,
				isLoading: true
			};
		case actionTypes.TRANSLATION_LANGUAGES_SUCCEEDED:
			return {
				...state,
				isLoading: false,
				languages: action.languages
			};
		case actionTypes.TRANSLATION_LANGUAGES_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.error
			};

		default:
			return state;
	}
};

export const actionCreators = {
	getLanguages: () => ({
		type: actionTypes.TRANSLATION_LANGUAGES_REQUESTED
	})
};
