import * as React from 'react';
import PropTypes from 'prop-types';

import { useActiveConfigurations } from 'hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

import CustomModal from 'components/CustomModal/CustomModal';
import { formatDate } from 'utils';

export const ActiveLandingPagesModal = ({
	title,
	entityId,
	closeModal,
	isOpen,
	filterName
}) => {
	const { data, isLoading } = useActiveConfigurations(entityId, {
		filter: filterName
	});

	const landingPages = data?.response ?? [];

	return (
		<CustomModal
			isOpen={isOpen}
			closeModal={closeModal}
			title={`${title} Landing Pages`}
			customStyle={{ minWidth: '900px' }}
		>
			<Box sx={{ position: 'relative', marginTop: '15px' }}>
				{/* Body */}
				{isLoading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						minHeight="100px"
					>
						<CircularProgress size={35} />
					</Box>
				) : (
					<TableContainer
						sx={{
							marginBottom: '24px',
							border: '1px solid rgba(0, 0, 0, 0.12)',
							borderRadius: '4px'
						}}
					>
						<Table
							sx={{
								width: '850px'
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>Landing Page</TableCell>
									<TableCell>Campaign</TableCell>
									<TableCell>Country</TableCell>
									<TableCell>Applied on</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{landingPages?.map((row) => (
									<TableRow
										key={row.landingPageId}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell>{row.landingPageName}</TableCell>
										<TableCell>{row.campaignName}</TableCell>
										<TableCell>
											<Box display="flex" alignItems="center">
												<img
													width="16px"
													src={`/flags/${row?.country?.locale?.toLowerCase()}.svg`}
													alt="flag"
													style={{ marginRight: '5px' }}
												/>{' '}
												{row?.country?.name} ({row?.country?.locale})
											</Box>
										</TableCell>
										<TableCell>
											{row.global_preset_applied_at
												? formatDate(row.global_preset_applied_at)
												: ''}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}

				{/* Actions */}
				<Box
					sx={{
						position: 'sticky',
						bottom: '-16px',
						width: '100%',
						background: 'white',
						paddingBottom: landingPages?.length > 17 ? '16px' : '0',
						paddingTop: landingPages?.length > 17 ? '10px' : '0'
					}}
					display="flex"
					justifyContent="space-between"
				>
					<Button
						sx={{ marginLeft: 'auto' }}
						variant="cancel"
						onClick={closeModal}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</CustomModal>
	);
};

ActiveLandingPagesModal.propTypes = {
	title: PropTypes.string.isRequired,
	entityId: PropTypes.number.isRequired,
	closeModal: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	filterName: PropTypes.string.isRequired
};
