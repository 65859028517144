import { ERROR_MESSAGES, ENDPOINT_MAPPING } from 'utils/config/configUtils';

const getEndpointFromError = (error) => {
	if (error.config && error.config.url) {
		const { url } = error.config;

		const matchingEndpoint = Object.keys(ENDPOINT_MAPPING).find(
			(endpointPattern) => url.includes(endpointPattern)
		);

		if (matchingEndpoint) {
			return ENDPOINT_MAPPING[matchingEndpoint];
		}
	}
	return '';
};

const getErrorMessage = (statusCode, endpoint, customMessage) => {
	let errorMessage = `Error ${statusCode}: ${
		ERROR_MESSAGES[statusCode] || 'An error occurred'
	}`;

	if (customMessage) {
		errorMessage = `Error ${statusCode}: ${customMessage}`;
	} else if (endpoint) {
		errorMessage = `${endpoint} Temporarily Unavailable - ${errorMessage}`;
	}

	return errorMessage;
};

export { getEndpointFromError, getErrorMessage };
