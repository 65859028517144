export const STATUS = {
	OK: 200,
	NOT_AUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	MFA_REQUIRED: 427,
	SERVER_ERROR: 500
};

export const STATUS_MESSAGE = {
	force_reset: 'Your password expired. To reset your password please visit',
	invalid_grant: 'Bad Credentials. Please check your email or password.',
	error_description: 'Bad Credentials. Please check your email or password.'
};
