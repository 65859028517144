import { PLATFORMS } from 'constants';
import httpClient from 'services/httpClient';
import responseHandler from 'services/responseHandler';

const INSIGHTS_URL = process.env.REACT_APP_PS_INSIGHTS;

const getAllCampaigns = (params) => {
	const options = { withCredentials: false };

	return httpClient.api
		.post(`/widget/list`, params, options)
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getCampaign = (campaignId) =>
	httpClient.api
		.post(`/widget/${campaignId}`)
		.then((data) => {
			const newData = { ...data };
			if (
				data.data.response.campaign.origin === 'hatch-stage' ||
				data.data.response.campaign.origin === 'hatch-prod'
			) {
				newData.data.response.campaign.origin = 'hatch';
			}
			return responseHandler.success(newData);
		})
		.catch(responseHandler.error);

const createCampaign = (payload) =>
	httpClient.api
		.post(`/widget`, payload, { withCredentials: false })
		.then(responseHandler.success)
		.catch(responseHandler.error);

const updateCampaign = (id, campaign) =>
	httpClient.api
		.patch(`/widget/${id}`, campaign, { withCredentials: false })
		.then(responseHandler.success)
		.catch(responseHandler.error);

const cloneCampaign = (id, formData) =>
	httpClient.api
		.post(
			`/widget/${id}/clone`,
			{ name: formData.name },
			{ withCredentials: false }
		)
		.then(responseHandler.success)
		.catch(responseHandler.error);

const getCountries = (origin) => {
	if (origin === PLATFORMS.hatch) return [];
	const url =
		origin === PLATFORMS.cc
			? `/countries?page=1&pageSize=9999&activatedOn=online&sort=en`
			: `${INSIGHTS_URL}/v1/client-country-list?services=wtb`;

	return httpClient.api
		.get(url, {
			withCredentials: origin !== 'cc'
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

export default {
	getAllCampaigns,
	getCampaign,
	createCampaign,
	updateCampaign,
	cloneCampaign,
	getCountries
};
