import { useQuery } from '@tanstack/react-query';
import ps from 'services/auth/ps';

export const useGetPsClientCountries = () => {
	const { data, isFetching, isError, error } = useQuery({
		queryKey: ['ps-client-countries'],
		queryFn: () => ps.getCountries(),
		retry: false,
		refetchOnWindowFocus: false
	});

	return {
		isLoading: isFetching,
		countries: data?.data ?? [],
		isError,
		error
	};
};
