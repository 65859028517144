/* eslint-disable camelcase */
import { useMemo } from 'react';
import qs from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useQuery = () => {
	const sortValues = useLocation().search;
	return useMemo(() => new URLSearchParams(sortValues), [sortValues]);
};

export const useUpdateQueryString = () => {
	const queryParams = useQuery();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const updateQuery = (query) => {
		for (let x = 0; x < query.length; x += 1) {
			queryParams.set(query[x].param, query[x].value);
		}

		navigate(`?${queryParams.toString()}`, { replace: true });
	};

	const updateQueryWithFilters = (queryObject) => {
		const searchObject = qs.parse(location.search);
		const base = params.campaignPage ? 'campaigns' : 'products';

		const { param, value } = queryObject;
		const queryString = qs.stringify(
			{ [param]: value },
			{ arrayFormat: 'comma' }
		);

		const obj = qs.parse(queryString);
		searchObject[param] = obj[param];

		const url =
			base === 'products'
				? `/campaign/${params.id}/${base}/0/10?${qs.stringify(searchObject, {
						sort: false
				  })}`
				: `/${base}/0/10?${qs.stringify(searchObject, { sort: false })}`;

		navigate(url, { replace: true });
	};

	const removeQueryParams = () => {
		navigate(
			{
				search: 'sort=created_at'
			},
			{ replace: true }
		);
	};

	return { updateQueryWithFilters, updateQuery, removeQueryParams };
};
