import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';

export const RadioCard = ({
	value,
	label,
	description,
	isActive,
	disabled
}) => (
	<Card
		variant="outlined"
		sx={{
			width: 330,
			pt: 0.5,
			pl: 2.2,
			pb: 1.5,
			display: 'flex',
			flexDirection: 'column',
			background: isActive ? 'rgba(0, 71, 255, 0.04)' : '',
			border: isActive ? '1px solid #0047FF' : '',
			cursor: 'pointer'
		}}
	>
		<FormControlLabel
			value={value}
			control={<Radio disabled={disabled} />}
			label={label}
		/>
		<Typography variant="caption">{description}</Typography>
	</Card>
);

RadioCard.propTypes = {
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	disabled: PropTypes.bool
};

RadioCard.defaultProps = {
	isActive: false,
	disabled: false
};

export const RadioCardGroup = ({ name, options, field, disabled }) => (
	<RadioGroup aria-labelledby={name} name={name} {...field}>
		<Box display="flex" gap={2} maxWidth={600}>
			{options.map((option) => (
				<RadioCard
					key={option.value}
					value={option.value}
					isActive={field.value === option.value}
					label={option.label}
					description={option.description}
					disabled={disabled}
				/>
			))}
		</Box>
	</RadioGroup>
);

RadioCardGroup.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	field: PropTypes.object.isRequired,
	disabled: PropTypes.bool
};

RadioCardGroup.defaultProps = {
	disabled: false
};

export const RadioCardGroupControlled = ({
	name,
	options,
	control,
	disabled
}) => (
	<Controller
		name={name}
		control={control}
		render={({ field }) => (
			<RadioCardGroup
				name={name}
				options={options}
				field={field}
				disabled={disabled}
			/>
		)}
	/>
);

RadioCardGroupControlled.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	control: PropTypes.object.isRequired,
	disabled: PropTypes.bool
};

RadioCardGroupControlled.defaultProps = {
	disabled: false
};
