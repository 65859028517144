import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { useModalState, MODALS } from 'contexts/ModalProvider';

import SafetyNetModal from 'components/SafetyNetModal/SafetyNetModal';
import { ActiveLandingPagesModal } from 'components/SafetyNetModal/ActiveLandingPagesModal';

import { LANDING_PAGE_TYPE, DIRECT_TO_RETAILER_TYPE } from 'constants';

const TYPES = {
	[LANDING_PAGE_TYPE.SKU]: 'Product',
	[LANDING_PAGE_TYPE.GENERIC]: 'Brand',
	[DIRECT_TO_RETAILER_TYPE.SKU]: 'Product',
	[DIRECT_TO_RETAILER_TYPE.GENERIC]: 'Brand'
};

// COUNTRY DATA CELL

export const CountryDataCell = ({ row }) => (
	<Box display="flex" flexWrap="wrap" alignItems="center" gap="5px">
		<img
			width="16px"
			src={`/flags/${row.country.locale.toLowerCase()}.svg`}
			alt={row.country.name}
		/>
		{`${row.country.name} (${row.country.language})`}
	</Box>
);

CountryDataCell.propTypes = {
	row: PropTypes.any.isRequired
};

// TYPE DATA CELL

export const TypeDataCell = ({ row }) => (
	<Chip color="default" label={TYPES[row.type]} variant="filled" />
);

TypeDataCell.propTypes = {
	row: PropTypes.any.isRequired
};

// STATUS DATA CELL

export const StatusDataCell = ({ status }) => (
	<Chip
		icon={status ? <CheckCircleIcon /> : <PowerOffIcon />}
		color={status ? 'success' : 'default'}
		label={status ? 'Active' : 'Inactive'}
		variant="outlined"
	/>
);

StatusDataCell.propTypes = {
	status: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired
};

// RETAILER DATA CELL

export const RetailerDataCell = ({ row }) => (
	<Box display="flex" flexWrap="wrap" alignItems="center" gap="5px">
		{`${row.retailer_name} `}
	</Box>
);

RetailerDataCell.propTypes = {
	row: PropTypes.any.isRequired
};

// AFFECTED LANDING PAGES DATA CELL

export const AffectedLandingPagesDataCell = ({
	count,
	name,
	id,
	filterName,
	type
}) => {
	const { isOpen, openModal, closeModal } = useModalState(
		`${MODALS.LANDING_PAGE.affected}-${id}`
	);
	const hasActivePages = count > 0;

	const iconStyle = {
		cursor: hasActivePages ? 'pointer' : 'auto',
		color: hasActivePages ? '#0000008A' : '#00000042',
		pointerEvents: hasActivePages ? '#all' : 'none'
	};

	return (
		<Box>
			<Box display="flex" alignItems="center">
				<Typography marginRight="8px">{count}</Typography>
				<ListOutlinedIcon
					onClick={hasActivePages ? openModal : null}
					sx={iconStyle}
				/>
			</Box>
			{isOpen && (
				<SafetyNetModal
					count={count}
					title={name}
					entityId={id}
					isOpen={isOpen}
					filterName={filterName}
					type={type}
					showActions={false}
					closeModal={closeModal}
				/>
			)}
		</Box>
	);
};

AffectedLandingPagesDataCell.propTypes = {
	count: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	filterName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired
};

// ACTIVE LANDING PAGES DATA CELL

export const ActiveLandingPagesDataCell = ({ count, name, id, filterName }) => {
	const { isOpen, openModal, closeModal } = useModalState(
		`${MODALS.LANDING_PAGE.active}-${id}`
	);
	const hasActivePages = count > 0;

	const iconStyle = {
		cursor: hasActivePages ? 'pointer' : 'auto',
		color: hasActivePages ? '#0000008A' : '#00000042',
		pointerEvents: hasActivePages ? '#all' : 'none'
	};

	return (
		<Box>
			<Box display="flex" alignItems="center">
				<Typography marginRight="8px">{count}</Typography>
				<ListOutlinedIcon
					onClick={hasActivePages ? openModal : null}
					sx={iconStyle}
				/>
			</Box>
			{isOpen && (
				<ActiveLandingPagesModal
					entityId={id}
					closeModal={closeModal}
					isOpen={isOpen}
					title={name}
					filterName={filterName}
				/>
			)}
		</Box>
	);
};

ActiveLandingPagesDataCell.propTypes = {
	count: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	filterName: PropTypes.string.isRequired
};
