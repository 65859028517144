import { styled } from '@mui/system';

export const ErrorMessageWrapper = styled('div')({
	/* position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0, */
	paddingTop: '100px',

	'> p': {
		margin: 0,
		textAlign: 'center',
		fontSize: 'px'
	}
});
