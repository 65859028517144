export const actionTypes = {
	AUTH_HATCH_LOGIN_REQUESTED: 'AUTH/HATCH_LOGIN_REQUESTED',
	AUTH_HATCH_LOGIN_SUCCEEDED: 'AUTH/HATCH_LOGIN_SUCCEEDED',
	AUTH_HATCH_LOGIN_FAILED: 'AUTH/HATCH_LOGIN_FAILED',
	AUTH_HATCH_SET_TOKENS: 'AUTH/HATCH_SET_TOKENS',

	AUTH_HATCH_USER_REQUESTED: 'AUTH/HATCH_USER_REQUESTED',
	AUTH_HATCH_USER_SUCCEEDED: 'AUTH/HATCH_USER_SUCCEEDED',
	AUTH_HATCH_USER_FAILED: 'AUTH/HATCH_USER_FAILED',
	AUTH_HATCH_USER_MFA_REQUESTED: 'AUTH/HATCH_USER_MFA_REQUESTED',

	AUTH_HATCH_TOKEN_REFRESH_REQUESTED: 'AUTH/HATCH_REFRESH_TOKEN_REQUESTED',
	AUTH_HATCH_TOKEN_REFRESH_SUCCEEDED: 'AUTH/HATCH_REFRESH_TOKEN_SUCCEEDED',
	AUTH_HATCH_TOKEN_REFRESH_FAILED: 'AUTH/HATCH_REFRESH_TOKEN_FAILED',
	AUTH_HATCH_TOKEN_REFRESH_TOKEN_SCHEDULE_REQUESTED:
		'AUTH/HATCH_REFRESH_TOKEN_SCHEDULE_REQUESTED',

	AUTH_CC_TOKEN_REQUESTED: 'AUTH/CC_TOKEN_REQUESTED',
	AUTH_CC_TOKEN_SUCCEEDED: 'AUTH/CC_TOKEN_SUCCEEDED',
	AUTH_CC_TOKEN_FAILED: 'AUTH/CC_TOKEN_FAILED',

	AUTH_CC_LICENSE_REQUESTED: 'AUTH/CC_LICENSE_REQUESTED',
	AUTH_CC_LICENSE_SUCCEEDED: 'AUTH/CC_LICENSE_SUCCEEDED',
	AUTH_CC_LICENSE_FAILED: 'AUTH/CC_LICENSE_FAILED',
	AUTH_CC_LICENSE_RESET: 'AUTH/CC_LICENSE_RESET',

	AUTH_CC_USER_REQUESTED: 'AUTH/CC_USER_REQUESTED',
	AUTH_CC_USER_SUCCEEDED: 'AUTH/CC_USER_SUCCEEDED',
	AUTH_CC_USER_FAILED: 'AUTH/CC_USER_FAILED',

	AUTH_CC_TOKEN_REFRESH_REQUESTED: 'AUTH/CC_REFRESH_TOKEN_REQUESTED',
	AUTH_CC_TOKEN_REFRESH_SUCCEEDED: 'AUTH/CC_REFRESH_TOKEN_SUCCEEDED',
	AUTH_CC_TOKEN_REFRESH_FAILED: 'AUTH/CC_REFRESH_TOKEN_FAILED',
	AUTH_CC_TOKEN_REFRESH_TOKEN_SCHEDULE_REQUESTED:
		'AUTH/CC_REFRESH_TOKEN_SCHEDULE_REQUESTED',

	AUTH_PS_USER_REQUESTED: 'AUTH/PS_USER_REQUESTED',
	AUTH_PS_USER_SUCCEEDED: 'AUTH/PS_USER_SUCCEEDED',
	AUTH_PS_USER_FAILED: 'AUTH/PS_USER_FAILED',

	AUTH_PS_TOKEN_REQUESTED: 'AUTH/PS_TOKEN_REQUESTED',
	AUTH_PS_TOKEN_SUCCEEDED: 'AUTH/PS_TOKEN_SUCCEEDED',
	AUTH_PS_TOKEN_FAILED: 'AUTH/PS_TOKEN_FAILED',

	AUTH_LOGOUT_REQUESTED: 'AUTH/LOGOUT_REQUEST',
	AUTH_LOGOUT_SUCCEEDED: 'AUTH/LOGOUT_SUCCEEDED',
	AUTH_LOGOUT_FAILED: 'AUTH/LOGOUT_FAILED',
	AUTH_LOGOUT_REDIRECT_SUCEEDED: 'AUTH/LOGOUT_REDIRECT_SUCCEEDED',
	AUTH_LOGOUT_REDIRECT_FAILED: 'AUTH/LOGOUT_REDIRECT_FAILED',

	AUTH_TOKEN_REMOVED: 'AUTH/TOKEN_REMOVED',
	AUTH_SET_ORIGIN: 'AUTH/SET_ORIGIN_SUCCEEDED',
	AUTH_SET_INTERCEPTORS: 'AUTH/SET_INTERCEPTORS',

	AUTH_SET_ERROR: 'AUTH/SET_ERROR',
	AUTH_SET_FORBIDDEN_ERROR: 'AUTH/SET_FORBIDDEN_ERROR'
};

export const initialState = {
	user: {},
	manufacturer_id: null,
	brands: [],
	countries: [],
	tokens: [],
	clientType: null,
	permissions: [],
	error: null,
	psAuthError: null,
	forbiddenError: null,
	origin: null,
	token: null,
	refreshToken: null,
	restrictedAccess: false,
	mfa: false,
	interceptorsReady: false,
	loading: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		// CC
		case actionTypes.AUTH_CC_USER_REQUESTED:
			return {
				...state,
				restrictedAccess: initialState.restrictedAccess,
				loading: true
			};
		case actionTypes.AUTH_CC_USER_SUCCEEDED:
			return {
				...state,
				user: action.user,
				manufacturer_id: action.manufacturer_id,
				restrictedAccess: initialState.restrictedAccess,
				countries: action.countries,
				loading: false
			};
		case actionTypes.AUTH_CC_USER_FAILED:
			return {
				...state,
				user: null,
				error: action.error,
				loading: false
			};
		case actionTypes.AUTH_CC_LICENSE_SUCCEEDED:
			return {
				...state,
				tokens: action.tokens
			};
		case actionTypes.AUTH_CC_LICENSE_FAILED:
			return {
				...state,
				error: action.error
			};
		case actionTypes.AUTH_CC_LICENSE_RESET:
			return {
				...state,
				tokens: []
			};
		case actionTypes.AUTH_CC_TOKEN_REQUESTED:
			return {
				...state,
				restrictedAccess: initialState.restrictedAccess,
				loading: true
			};
		case actionTypes.AUTH_CC_TOKEN_SUCCEEDED:
			return {
				...state,
				origin: 'cc',
				token: action.token,
				restrictedAccess: initialState.restrictedAccess,
				loading: false
			};
		case actionTypes.AUTH_CC_TOKEN_FAILED:
			return {
				...state,
				token: initialState.token,
				error: action.error,
				loading: false
			};
		case actionTypes.AUTH_CC_TOKEN_REFRESH_SUCCEEDED:
			return {
				...state,
				token: action.payload.atoken,
				loading: false
			};
		case actionTypes.AUTH_CC_TOKEN_REFRESH_FAILED:
			return {
				...state,
				error: action.error,
				loading: false
			};

		// Hatch
		case actionTypes.AUTH_HATCH_USER_REQUESTED:
			return {
				...state,
				restrictedAccess: initialState.restrictedAccess,
				loading: true
			};
		case actionTypes.AUTH_HATCH_USER_SUCCEEDED:
			return {
				...state,
				user: action.user,
				brands: action.brands,
				countries: action.countries,
				origin: 'hatch',
				restrictedAccess: initialState.restrictedAccess,
				loading: false
			};
		case actionTypes.AUTH_HATCH_USER_FAILED:
			return {
				...state,
				user: initialState.user,
				error: action.error,
				loading: false
			};

		case actionTypes.AUTH_HATCH_LOGIN_REQUESTED:
			return {
				...state,
				loading: true
			};
		case actionTypes.AUTH_HATCH_LOGIN_SUCCEEDED:
			return {
				...state,
				token: action.payload.access_token,
				origin: action.payload.origin,
				refreshToken: action.payload.refresh_token,
				mfa: initialState.mfa,
				error: initialState.error,
				loading: false
			};
		case actionTypes.AUTH_HATCH_LOGIN_FAILED:
			return {
				...state,
				error: action.error,
				loading: false
			};
		case actionTypes.AUTH_HATCH_USER_MFA_REQUESTED:
			return {
				...state,
				mfa: action.mfa,
				loading: false
			};
		case actionTypes.AUTH_HATCH_TOKEN_REFRESH_REQUESTED:
			return {
				...state,
				loading: true
			};
		case actionTypes.AUTH_HATCH_TOKEN_REFRESH_SUCCEEDED:
			return {
				...state,
				token: action.payload.access_token,
				refreshToken: action.payload.refresh_token,
				loading: false
			};
		case actionTypes.AUTH_HATCH_TOKEN_REFRESH_FAILED:
			return {
				...state,
				error: action.error,
				loading: false
			};

		// PS
		case actionTypes.AUTH_PS_USER_REQUESTED:
			return {
				...state,
				restrictedAccess: initialState.restrictedAccess,
				loading: true
			};
		case actionTypes.AUTH_PS_USER_SUCCEEDED:
			return {
				...state,
				clientType: action.user.client.type,
				user: action.user,
				brands: action.brands,
				countries: action.countries,
				tokens: action.tokens,
				origin: 'ps',
				restrictedAccess: initialState.restrictedAccess,
				loading: false
			};

		// PS TOKEN
		case actionTypes.AUTH_PS_TOKEN_REQUESTED:
			return {
				...state,
				restrictedAccess: initialState.restrictedAccess,
				loading: true
			};
		case actionTypes.AUTH_PS_TOKEN_SUCCEEDED:
			return {
				...state,
				origin: 'ps',
				token: action.token,
				restrictedAccess: initialState.restrictedAccess,
				loading: false
			};

		// SHARED
		case actionTypes.AUTH_LOGOUT_SUCCEEDED:
			return {
				...state,
				user: initialState.user,
				error: initialState.error,
				mfa: initialState.mfa,
				token: initialState.token,
				refreshToken: initialState.refreshToken,
				interceptorsReady: initialState.interceptorsReady,
				loading: false
			};

		// AUTH_LOGOUT_REDIRECT_SUCEEDED

		case actionTypes.AUTH_LOGOUT_FAILED:
			return {
				...state,
				error: action.error
			};

		case actionTypes.AUTH_TOKEN_REMOVED:
			return {
				...state,
				token: initialState.token
			};
		case actionTypes.AUTH_SET_ORIGIN:
			return {
				...state,
				origin: action.origin
			};
		case actionTypes.AUTH_SET_INTERCEPTORS:
			return {
				...state,
				interceptorsReady: true
			};
		case actionTypes.AUTH_SET_ERROR:
			return {
				...state,
				psAuthError: action.payload,
				loading: false
			};
		case actionTypes.AUTH_SET_FORBIDDEN_ERROR:
			return {
				...state,
				forbiddenError: action.payload,
				loading: false
			};
		default:
			return state;
	}
};

export const actionCreators = {
	// --- Hatch
	login: (payload, store, history) => ({
		type: actionTypes.AUTH_HATCH_LOGIN_REQUESTED,
		payload,
		store,
		history
	}),
	setLoginOnRefresh: (payload) => ({
		type: actionTypes.AUTH_HATCH_LOGIN_SUCCEEDED,
		payload
	}),
	getHatchUser: (store, history) => ({
		type: actionTypes.AUTH_HATCH_USER_REQUESTED,
		store,
		history
	}),
	setHatchTokens: (payload) => ({
		type: actionTypes.AUTH_HATCH_TOKEN_REFRESH_SUCCEEDED,
		payload
	}),
	requestMFA: (mfa) => ({
		type: actionTypes.AUTH_HATCH_USER_MFA_REQUESTED,
		mfa
	}),

	// --- CC
	setCCToken: (token) => ({
		type: actionTypes.AUTH_CC_TOKEN_SUCCEEDED,
		token
	}),
	getCCToken: () => ({
		type: actionTypes.AUTH_CC_TOKEN_REQUESTED
	}),
	getCCLicenses: (country) => ({
		type: actionTypes.AUTH_CC_LICENSE_REQUESTED,
		country
	}),
	resetCCLicences: () => ({
		type: actionTypes.AUTH_CC_LICENSE_RESET
	}),
	getCCUser: (store, history) => ({
		type: actionTypes.AUTH_CC_USER_REQUESTED,
		store,
		history
	}),
	refreshCCToken: () => ({
		type: actionTypes.AUTH_CC_TOKEN_REFRESH_REQUESTED
	}),

	// --- PS
	getPSUser: (store, history) => ({
		type: actionTypes.AUTH_PS_USER_REQUESTED,
		store,
		history
	}),
	setPSToken: (token) => ({
		type: actionTypes.AUTH_PS_TOKEN_SUCCEEDED,
		token
	}),
	getPSToken: () => ({
		type: actionTypes.AUTH_PS_TOKEN_REQUESTED
	}),

	// --- Shared
	logout: (origin, history, unauthorized) => ({
		type: actionTypes.AUTH_LOGOUT_REQUESTED,
		origin,
		history,
		unauthorized
	}),
	setOrigin: (origin) => ({
		type: actionTypes.AUTH_SET_ORIGIN,
		origin
	}),
	setError: (errorMessage) => ({
		type: actionTypes.AUTH_SET_ERROR,
		payload: errorMessage
	}),
	setForbiddenError: (errorMessage) => ({
		type: actionTypes.AUTH_SET_FORBIDDEN_ERROR,
		payload: errorMessage
	})
};
