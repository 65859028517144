export const decodeJWT = (t) => {
	if (!t) return 0;
	const token = {};

	token.raw = t;
	token.header = JSON.parse(window.atob(t.split('.')[0]));
	token.payload = JSON.parse(window.atob(t.split('.')[1]));

	return token;
};

export const isTokenExpired = (expiry) =>
	Math.floor(new Date().getTime() / 1000) >= expiry;
