import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';

import FEATURES from './features.json';

const isStage = process.env.REACT_APP_IS_STAGE === 'true';
const environment = isStage ? 'staging' : 'production';

const FeatureFlagsContext = createContext(FEATURES.environments[environment]);

const FeatureFlagsProvider = ({ children }) => {
	const initialFeatureFlags = useContext(FeatureFlagsContext);

	return (
		<FeatureFlagsContext.Provider value={{ features: initialFeatureFlags }}>
			{children}
		</FeatureFlagsContext.Provider>
	);
};

FeatureFlagsProvider.propTypes = {
	children: PropTypes.any.isRequired
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export default FeatureFlagsProvider;
