import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StyledEngineProvider } from '@mui/material/styles';

import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMessageContext } from 'contexts/Message/MessageContext';

import Box from '@mui/material/Box';
import Sidebar from './Sidebar/Sidebar';

const errorSelector = (state) => state?.auth?.psAuthError;

const routesWithoutSidebar = [
	'/',
	'/login',
	'/shared-documentation',
	/^\/campaign\/[^/]+\/product\/?$/,
	/^\/campaign\/[^/]+\/product\/[^/]+$/,
	/^\/preset\/?[^/]*$/
];
const isRouteMatched = (pathname) =>
	routesWithoutSidebar.some((pattern) =>
		pattern instanceof RegExp ? pattern.test(pathname) : pattern === pathname
	);

const Layout = ({ children }) => {
	const location = useLocation();

	const currentRoute = location.pathname;
	const hideSidebar = isRouteMatched(currentRoute);
	const sendMessage = useMessageContext();

	const error = useSelector(errorSelector);

	useEffect(() => {
		if (error) {
			sendMessage({ text: error, duration: 8000, type: 'error' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	return (
		<StyledEngineProvider injectFirst>
			<Box sx={{ display: 'flex' }}>
				{!hideSidebar && <Sidebar />}

				<Box
					component="main"
					p={!hideSidebar ? '24px' : '0'}
					sx={{
						flexGrow: 1
					}}
				>
					{!error && children}
				</Box>
			</Box>
		</StyledEngineProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.any.isRequired
};

export default Layout;
