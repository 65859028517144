import { all, put, call, takeLatest } from 'redux-saga/effects';
import { actionTypes as translationActionTypes } from 'redux/ducks/translationDuck';
import apiTranslations from 'services/apiTranslations';
import { convertToArray } from 'utils/object/objectUtils';
import errorHandlerSaga from './errorHandlerSaga';

export function* getLanguages() {
	try {
		const response = yield call(apiTranslations.getLanguages);
		const { data } = response;
		const langObj = data.response;
		const objArray = convertToArray(langObj);

		yield put({
			type: translationActionTypes.TRANSLATION_LANGUAGES_SUCCEEDED,
			languages: objArray
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: translationActionTypes.TRANSLATION_LANGUAGES_FAILED
		});
	}
}

export default function* translationSaga() {
	yield all([
		takeLatest(
			translationActionTypes.TRANSLATION_LANGUAGES_REQUESTED,
			getLanguages
		)
	]);
}
