import React, { lazy } from 'react';
import LoginPage from 'screens/Login';
import Campaign from 'screens/Campaign';
import Analytics from 'screens/Analytics';

// lazy
const RetailerMgm = lazy(() => import('screens/RTM'));
const TrackingProfiles = lazy(() => import('screens/TrackingProfiles'));
const CustomFonts = lazy(() => import('screens/CustomFonts'));
const AuditTrail = lazy(() => import('screens/AuditTrail/container'));
const Documentation = lazy(() => import('screens/Documentation'));
const Translations = lazy(() => import('screens/Translations'));
const LandingPages = lazy(() => import('screens/LandingPages'));
const ConfiguratorLP = lazy(() => import('screens/ConfiguratorLP'));
const Links = lazy(() => import('screens/Links'));
const Presets = lazy(() => import('screens/Presets'));
const MediaHubPage = lazy(() => import('screens/MediaHub'));
const MediaHubListPage = lazy(() => import('screens/MediaHubList'));
const MediaHubFolderPage = lazy(() => import('screens/MediaHubFolder'));
const MediaHubFileDetailsPage = lazy(() =>
	import('screens/MediaHubFileDetails')
);

export const routes = [
	{
		element: <LoginPage />,
		name: 'Login',
		path: '/',
		restrict: true
	},
	{
		element: <LoginPage />,
		name: 'Login',
		path: '/login',
		restrict: false
	},
	{
		element: <Campaign />,
		name: 'Campaign',
		path: 'campaigns/:offset/:limit',
		restrict: true
	},
	{
		element: <Links />,
		name: 'Direct Links',
		path: 'links/:offset/:limit',
		restrict: true
	},
	{
		element: <RetailerMgm />,
		name: 'RTM',
		path: '/configurations/retailers/:offset/:limit',
		restrict: true
	},
	{
		element: <LandingPages />,
		name: 'LandingPages',
		path: '/campaign/:id/products/:offset/:limit',
		restrict: true
	},
	{
		element: <ConfiguratorLP />,
		name: 'Settings',
		path: '/campaign/:campaignid/product/',
		restrict: true
	},
	{
		element: <Presets />,
		name: 'Presets',
		path: '/configurations/presets/:offset/:limit',
		restrict: true
	},
	{
		element: <ConfiguratorLP />,
		name: 'Settings',
		path: '/preset/',
		restrict: true
	},
	{
		element: <ConfiguratorLP />,
		name: 'Settings',
		path: '/preset/:presetid/',
		restrict: true
	},
	{
		element: <ConfiguratorLP />,
		name: 'Settings',
		path: '/campaign/:campaignid/product/:productid',
		restrict: true
	},
	{
		element: <Translations />,
		name: 'Translations',
		path: '/configurations/translations',
		restrict: true
	},
	{
		element: <Documentation />,
		name: 'Documentation',
		path: '/documentation',
		restrict: true
	},
	{
		element: <Documentation />,
		name: 'Documentation',
		path: '/shared-documentation',
		restrict: false
	},
	{
		element: <Analytics />,
		name: 'Analytics',
		path: '/analytics',
		restrict: true
	},
	{
		element: <AuditTrail />,
		name: 'Audit',
		path: '/audit/campaign/:campaignid/:offset/:limit',
		restrict: true
	},
	{
		element: <AuditTrail />,
		name: 'Audit',
		path: '/audit/campaign/:campaignid/product/:productid/:offset/:limit',
		restrict: true
	},
	{
		element: <AuditTrail />,
		name: 'Audit',
		path: '/audit/configurations/third-party-tracking/:trackingProfileId/:offset/:limit',
		restrict: true
	},
	{
		element: <AuditTrail />,
		name: 'Audit',
		path: '/audit/configurations/presets/:presetid/:offset/:limit',
		restrict: true
	},
	{
		element: <AuditTrail />,
		name: 'Audit',
		path: '/audit/configurations/retailers/:businessRuleId/:offset/:limit',
		restrict: true
	},
	{
		element: <AuditTrail />,
		name: 'Audit',
		path: '/audit/assets/custom-fonts/:customFontId/:offset/:limit',
		restrict: true
	},
	{
		element: <TrackingProfiles />,
		name: 'Third Party Tracking',
		path: '/configurations/third-party-tracking/:offset/:limit',
		restrict: true
	},
	{
		element: <CustomFonts />,
		name: 'Custom Fonts',
		path: '/assets/custom-fonts/:offset/:limit',
		restrict: true
	},
	{
		element: <MediaHubPage />,
		name: 'Media',
		path: '/assets/media-hub/:offset/:limit',
		restrict: true
	},
	{
		element: <MediaHubListPage />,
		name: 'Media List',
		path: '/assets/media-hub/:manufacturer_name/:manufacturers/list/:offset/:limit',
		restrict: true
	},
	{
		element: <MediaHubFolderPage />,
		name: 'Media - Folder List',
		path: '/assets/media-hub/:manufacturer_name/:manufacturers/:folder_name/:folder_id/:offset/:limit',
		restrict: true
	},
	{
		element: <MediaHubFileDetailsPage />,
		name: 'Media - File Details',
		path: '/assets/media-hub/:manufacturer_name/:manufacturers/folder/:folder_id/file/:asset_id/:offset/:limit',
		restrict: true
	},
	{
		element: <LoginPage />,
		name: 'Login',
		path: '*',
		restrict: false
	}
];

export const getRouterPaths = () =>
	routes.map((route) => ({ name: route.name, path: route.path }));
