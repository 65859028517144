import React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CustomModal from 'components/CustomModal/CustomModal';
import CircularProgress from '@mui/material/CircularProgress';

const getModalTitle = (entityName, row, landingPageCount) => {
	const action = row.active ? 'Deactivate' : 'Activate';
	const notAllowedSuffix = landingPageCount ? ' is not allowed' : '';

	if (entityName === 'Custom Font') {
		return `${action} Custom Font`;
	}

	return `${action} ${entityName}${notAllowedSuffix}`;
};

/**
 * @param {boolean} props.isOpen - Determines whether the modal is open or not.
 * @param {Function} props.setIsOpen - Function to set the state of the modal (open or close).
 * @param {Function} props.handleStatusChange - Function to handle the status change when the action button is clicked.
 * @param {Object} props.row - The data row to be used in the modal.
 * @param {string} props.entityName - The name of the entity to be used in the modal title and description.
 */
const EntityStatusModal = ({
	isOpen,
	closeModal,
	handleStatusChange,
	row,
	entityName,
	landingPageCount,
	isLoading,
	onShowSafetyNetModal
}) => {
	const modalTitle = getModalTitle(entityName, row, landingPageCount);

	const renderContent = () => {
		if (isLoading) {
			return (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight="100px"
				>
					<CircularProgress size={35} />
				</Box>
			);
		}

		if (entityName === 'Custom Font' && landingPageCount) {
			return (
				<>
					<Typography variant="body1" component="div" sx={{ mb: 3, mt: 1 }}>
						This {entityName} is active on {landingPageCount} landing{' '}
						{landingPageCount === 1 ? 'page' : 'pages'}.
						<br />
						Deactivating the {entityName} will change respective landing{' '}
						{landingPageCount === 1 ? 'page' : 'pages'}.
						<br />
						To keep the same user experience, {entityName} font needs to stay
						active.
					</Typography>

					<Stack spacing={2} direction="row" justifyContent="flex-end">
						<Button variant="cancel" onClick={() => closeModal()}>
							CANCEL
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								onShowSafetyNetModal();
								closeModal();
							}}
						>
							NEXT
						</Button>
					</Stack>
				</>
			);
		}

		if (landingPageCount) {
			return (
				<>
					<Typography variant="body1" component="div" sx={{ mb: 3, mt: 1 }}>
						This {entityName} is active on {landingPageCount} landing{' '}
						{landingPageCount === 1 ? 'page' : 'pages'}.
						<br />
						To avoid breaking landing{' '}
						{landingPageCount === 1 ? 'page' : 'pages'}, it must remain active.
					</Typography>
					<Button
						variant="contained"
						onClick={() => closeModal()}
						startIcon={<ArrowBackIcon />}
						sx={{ marginLeft: 'auto', marginRight: '8px' }}
					>
						Back
					</Button>
				</>
			);
		}

		return (
			<>
				<Typography id="modal-modal-description" mb={1.6}>
					Are you sure you want to {row.active ? 'deactivate' : 'activate'} the
					following {entityName}:
				</Typography>
				<Typography id="modal-modal-description" mb={2.4} fontWeight="bold">
					{row.name}?
				</Typography>
				<Stack spacing={2} direction="row" justifyContent="flex-end">
					<Button variant="cancel" onClick={() => closeModal()}>
						NO, CANCEL
					</Button>
					<Button
						variant="contained"
						color={row.active ? 'error' : 'primary'}
						onClick={handleStatusChange}
					>
						Yes, {row.active ? 'DEACTIVATE' : 'ACTIVATE'}
					</Button>
				</Stack>
			</>
		);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			closeModal={() => closeModal()}
			title={modalTitle}
		>
			{renderContent()}
		</CustomModal>
	);
};

EntityStatusModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	handleStatusChange: PropTypes.func.isRequired,
	row: PropTypes.object.isRequired,
	entityName: PropTypes.string.isRequired,
	landingPageCount: PropTypes.number,
	isLoading: PropTypes.bool,
	onShowSafetyNetModal: PropTypes.func
};

EntityStatusModal.defaultProps = {
	landingPageCount: 0,
	isLoading: false,
	onShowSafetyNetModal: () => {}
};

export default EntityStatusModal;
