import { useSelector } from 'react-redux';

const brandsSelector = (state) => state?.auth?.brands;
const originSelector = (state) => state?.auth?.origin;
const countriesSelector = (state) => state?.auth?.countries;
const tokensSelector = (state) => state?.auth?.tokens;
const userSelector = (state) => state?.auth?.user;

export const useUserData = () => {
	const userBrands = useSelector(brandsSelector);
	const origin = useSelector(originSelector);
	const userCountries = useSelector(countriesSelector);
	const tokens = useSelector(tokensSelector);
	const user = useSelector(userSelector);

	return {
		user,
		userBrands,
		origin,
		userCountries,
		tokens
	};
};
