import { format } from 'date-fns';

const formatPsDescription = (item) =>
	item.description
		? item.description
		: `${item.manufacturerName} ${item.model} ${item.productName}`;

const formatPS = (item) => {
	const extractSku = (product) => product.skus[0].sku;

	const getProductID = (product) => {
		if (product.manufacturerPartNumber) {
			return product.manufacturerPartNumber.toString();
		}

		if (product.product_id) {
			return product.product_id;
		}
		return extractSku(product);
	};

	const getLargestImageKey = (images) => {
		if (images) {
			return Math.max(...Object.keys(images));
		}
		return null;
	};

	const largestImageKey = getLargestImageKey(item.images);

	return {
		product_id: getProductID(item),
		product_id_type: 'ean',
		description: formatPsDescription(item),
		product_image: item.images?.[largestImageKey] || null
	};
};

const formatCC = (item) => ({
	product_id: item.ean ? item.ean.toString() : item.product_id,
	product_id_type: 'ean',
	description:
		item.description || (item.ean ? item.ean.toString() : item.product_id),
	product_image: item.image_path || null
});

const formatHatch = (item) => ({
	product_id: item.mpn || item.product_id,
	product_id_type: 'ean',
	description: item.name || (item.mpn ? item.mpn : item.product_id),
	product_image: item.image_path || null
});

export const formatBrands = (brands) =>
	brands
		? brands.map((brand) => ({ value: brand, label: brand.name, id: brand.id }))
		: [];

const formatKey = (key) => {
	const formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
	return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
};

const formatDate = (dateString) => {
	const date = new Date(dateString);
	return format(date, 'dd MMM yyyy h:mm a');
};

const formatStringForUrl = (string) =>
	string.split(' ').join('-').toLowerCase();

const formatURIPortionForDisplaying = (string) => string.split('-').join(' ');

export {
	formatPsDescription,
	formatPS,
	formatCC,
	formatHatch,
	formatKey,
	formatDate,
	formatStringForUrl,
	formatURIPortionForDisplaying
};
