import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiCampaign from 'services/apiCampaigns';
import responseHandler from 'services/responseHandler';
import { useMessageContext } from 'contexts/Message/MessageContext';

function useUpdateCampaignStatus() {
	const queryClient = useQueryClient();
	const sendMessage = useMessageContext();

	const mutation = useMutation({
		mutationFn: async ({ campaign }) => {
			const payload = {
				active: campaign.active || false,
				country: campaign.country,
				manufacturer_id: campaign.manufacturer_id,
				name: campaign.name,
				token: campaign.token || undefined,
				manufacturer_name: campaign.manufacturer_name || undefined,
				affiliate_id: campaign.affiliate_id || undefined,
				end_date: campaign.end_date
			};

			try {
				const response = await apiCampaign.updateCampaign(campaign.id, payload);

				sendMessage(
					'Campaign successfully updated. If campaign is not visible anymore, please check your filter settings.'
				);

				return responseHandler.success(response);
			} catch (error) {
				sendMessage({ text: 'Something went wrong', type: 'error' });

				return responseHandler.error(error);
			}
		},
		onMutate: (campaign) => {
			queryClient.setQueryData(['campaigns', campaign.id], (prev) => {
				if (!prev) return prev;
				return {
					...prev,
					active: !prev.active
				};
			});
		},
		onSettled: () => {
			queryClient.invalidateQueries('campaigns');
		}
	});

	return mutation;
}

export default useUpdateCampaignStatus;
