import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useRequestTimeTracker from 'hooks/useRequestTimeTracker';

import Typography from '@mui/material/Typography';

const LongRequestInfo = ({ isLoading }) => {
	const hasExceededThreshold = useRequestTimeTracker(isLoading);

	const theme = useTheme();

	return hasExceededThreshold ? (
		<Typography
			sx={{ color: theme.palette.light.text.secondary, fontSize: '12px' }}
		>
			Operation is taking longer than usual. Thank you for your patience.
		</Typography>
	) : null;
};

LongRequestInfo.propTypes = {
	isLoading: PropTypes.bool.isRequired
};

export default LongRequestInfo;
