import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { actionCreators as authActionCreators } from 'redux/ducks/authDuck';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const isAppLoadingSelector = (state) => state.app?.appLoading;

const AppSkeleton = () => (
	<>
		<Box display="flex" justifyContent="space-between" mb={2}>
			<Skeleton variant="text" width={300} height={20} />
			<Skeleton variant="rectangular" width={160} height={40} />
		</Box>

		<Paper>
			<Stack direction="row" spacing={2} padding={2} mb={1.5}>
				<>
					<Skeleton variant="rounded" height={36} width={90} />
					<Skeleton variant="rounded" height={36} width={90} />
					<Skeleton variant="rounded" height={36} width={90} />
					<Skeleton variant="rounded" height={36} width={90} />
				</>
			</Stack>
		</Paper>

		<Paper p={2}>
			<Box position="relative" width="100%" height="400px" p={2}>
				<Skeleton variant="rectangular" width={160} height={40} />
				<CircularProgress
					sx={{
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, -50%)'
					}}
				/>
			</Box>
		</Paper>
	</>
);

const ProtectedRoute = ({ auth, children, ...props }) => {
	const location = useLocation();
	const { token } = auth;
	const isAppLoading = useSelector(isAppLoadingSelector);
	const isConfiguratorRoute =
		location.pathname.includes('product?') ||
		location.pathname.includes('product/');

	if (isAppLoading) {
		return isConfiguratorRoute ? (
			<CircularProgress
				sx={{
					position: 'fixed',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)'
				}}
			/>
		) : (
			<AppSkeleton />
		);
	}

	if (!token) {
		return <Navigate to="/login" state={{ from: props.location }} />;
	}

	return children;
};

ProtectedRoute.propTypes = {
	location: PropTypes.shape({
		hash: PropTypes.string,
		key: PropTypes.string,
		pathname: PropTypes.string,
		search: PropTypes.string,
		state: PropTypes.any
	}),
	exact: PropTypes.bool,
	auth: PropTypes.object.isRequired,
	children: PropTypes.any.isRequired
};

ProtectedRoute.defaultProps = {
	location: {},
	exact: false
};

const mapStateToProps = (state) => ({
	auth: state.auth
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			...authActionCreators
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
