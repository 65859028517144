export const MODALS = {
	SHARED: {
		audit: 'audit',
		clone: 'clone',
		deleteSingle: 'deleteSingle',
		entityStatus: 'entityStatus',
		safetyNet: 'safetyNet'
	},
	CAMPAIGN: {
		create: 'create'
	},
	CROPPING_TOOL: {
		preview: 'preview'
	},
	LANDING_PAGE: {
		active: 'active',
		affected: 'affected',
		type: 'type',
		copySource: 'copySource',
		list: 'list'
	},
	CONFIGURATOR: {
		add2Cart: 'add2Cart',
		customVariants: 'customVariants'
	},
	MEDIA_HUB: {
		rename: 'rename',
		move: 'move',
		delete: 'delete',
		open: 'open',
		upload: 'upload',
		editFolder: 'editFolder',
		deleteFolder: 'deleteFolder',
		editAsset: 'editAsset',
		deleteAsset: 'deleteAsset'
	},
	PRESETS: {
		create: 'create',
		update: 'update',
		saveViaConfigurator: 'saveViaConfigurator'
	},
	ADMIN: {
		documentation: 'documentation'
	}
};
