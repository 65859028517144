import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import RadarIcon from '@mui/icons-material/Radar';
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import FilterChip from './FilterChip';

const IconSet = {
	ids: SellTwoToneIcon,
	linkIds: SellTwoToneIcon,
	hashIds: SellTwoToneIcon,
	active: RadarIcon,
	manufacturers: BusinessIcon,
	locales: PublicTwoToneIcon,
	types: CategoryOutlinedIcon,
	created_by: PersonOutlineTwoToneIcon,
	activeOnLp: WebOutlinedIcon,
	keywords: KeyRoundedIcon
};

const ChipsGroup = ({ filters, handleChange }) => {
	const handleDeleteChip = (chip, index) => {
		const filter = filters[index];

		const selected = filter.items
			?.map((item) => {
				if (item.value === chip.value) {
					return { ...item, isSelected: !item.isSelected };
				}
				return item;
			})
			.filter((item) => item.isSelected);

		handleChange({
			name: filter.paramName,
			selected
		});
	};

	return (
		<>
			<Divider sx={{ margin: '10px 0 20px' }} />
			<Box
				display="flex"
				flexWrap="wrap"
				gap="8px"
				maxHeight="110px"
				overflow="auto"
			>
				{filters.map((filter, index) => (
					<Fragment key={filter.position}>
						{filter.items.map(
							(item) =>
								item.isSelected && (
									<FilterChip
										key={item.value || item.label}
										Icon={IconSet[filter.paramName]}
										item={item}
										handleDeleteChip={(chip) => handleDeleteChip(chip, index)}
									/>
								)
						)}
					</Fragment>
				))}
			</Box>
		</>
	);
};

ChipsGroup.propTypes = {
	filters: PropTypes.array,
	handleChange: PropTypes.func.isRequired
};

ChipsGroup.defaultProps = {
	filters: []
};

export default ChipsGroup;
