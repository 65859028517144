/* eslint-disable no-await-in-loop */
import { PLATFORMS } from 'constants';
import { openDB } from 'idb';
import { useEffect, useState } from 'react';

const DB_NAME = 'GalleryDatabase';
const STORE_NAME = 'recentImages';
const VERSION = 1;

const cleanupOldImages = async () => {
	const db = await openDB(DB_NAME, VERSION);
	const tx = db.transaction(STORE_NAME, 'readwrite');
	const store = tx.objectStore(STORE_NAME);
	const index = store.index('timestamp');

	// Get all records ordered by timestamp in descending order
	let cursor = await index.openCursor(null, 'prev');
	let count = 0;

	while (cursor) {
		count += 1;
		if (count > 20) {
			// Delete records older than the 20 most recent ones
			await cursor.delete();
		}
		cursor = await cursor.continue();
	}
};

const initDB = async () => {
	const database = await openDB(DB_NAME, VERSION, {
		upgrade(db) {
			if (!db.objectStoreNames.contains(STORE_NAME)) {
				const store = db.createObjectStore(STORE_NAME, {
					keyPath: 'id',
					autoIncrement: true
				});

				store.createIndex('timestamp', 'timestamp', { unique: false });
			}
		}
	});

	await cleanupOldImages();

	return database;
};

const useRecentImagesDatabase = (manufacturerId, type, origin) => {
	const [recentImages, setRecentImages] = useState([]);

	const fetchRecentImages = async () => {
		const db = await initDB();
		const tx = db.transaction(STORE_NAME, 'readonly');
		const store = tx.objectStore(STORE_NAME);
		const index = store.index('timestamp');

		const allEntries = [];
		let cursor = await index.openCursor(null, 'prev'); // Open cursor in reverse order.
		const count = 0;

		while (cursor && count < 8) {
			const isManIdRequired = origin !== PLATFORMS.cc;

			if (isManIdRequired) {
				if (
					cursor.value.file.type === type &&
					manufacturerId &&
					cursor.value.file.manufacturer_id &&
					cursor.value.file.manufacturer_id === manufacturerId
				) {
					allEntries.push(cursor.value); // Add entry if it matches the manufacturerId
				}
			} else if (cursor.value.file.type === type) {
				allEntries.push(cursor.value); // Add entry if it matches the manufacturerId
			}

			if (allEntries.length >= 8) break; // Stop if we have enough entries
			cursor = await cursor.continue(); // Move to the next entry
		}

		setRecentImages(allEntries);
	};

	useEffect(() => {
		fetchRecentImages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addImage = async (file) => {
		const db = await initDB();
		const tx = db.transaction(STORE_NAME, 'readwrite');
		const store = tx.objectStore(STORE_NAME);

		const { id } = file;

		// A helper function to recursively check for and delete existing images.
		const checkAndDeleteExisting = async (cursor) => {
			if (!cursor) return false; // Base case: no more entries in the cursor.

			if (cursor.value.file.id === id) {
				// Found an existing image with the same id, delete it.
				await cursor.delete();
				return false; // Assuming only one duplicate can exist, return immediately after deleting.
			}

			// Move to the next item in the cursor.
			return checkAndDeleteExisting(await cursor.continue().catch(() => null));
		};

		// Start the process by opening a cursor and using the helper function.
		const index = store.index('timestamp'); // Adjust based on your index.
		const cursor = await index.openCursor(null, 'prev');
		await checkAndDeleteExisting(cursor);

		// Now, add the new image.
		await store.add({ file, timestamp: Date.now() });
		await tx.done;

		fetchRecentImages(); // Refresh the recent images list.
	};

	return { addImage, recentImages };
};

export default useRecentImagesDatabase;
