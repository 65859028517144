import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';

import { useTourGuideContext } from 'contexts/TourGuide/TourGuideContext';

import { basicTourState, TOUR_STATUS } from 'contexts/TourGuide/tours';

const TOUR_ENDED_STATUSES = [TOUR_STATUS.SKIPPED, TOUR_STATUS.FINISHED];

const isTourEndedStatus = (status) => TOUR_ENDED_STATUSES.includes(status);
const shouldPauseTour = (status, type) =>
	status === TOUR_STATUS.PAUSED ||
	EVENTS.TARGET_NOT_FOUND === type ||
	isTourEndedStatus(status);
const getNextStepIndex = (index, action) =>
	index + (action === ACTIONS.PREV ? -1 : 1);

const BasicTour = () => {
	const location = useLocation();

	const {
		basicTourUnpaused,
		setBasicTourUnpaused,
		activeTour,
		handleTourStep,
		tourStepName,
		handleEndTour
	} = useTourGuideContext();

	const [tourConfig, setTourConfig] = useState(basicTourState);

	const handleStepAfter = useCallback(
		(index, action) => {
			const nextStepIndex = getNextStepIndex(index, action);

			handleTourStep(nextStepIndex);

			setTourConfig((prevState) => ({
				...prevState,
				stepIndex: nextStepIndex
			}));
		},
		[handleTourStep]
	);

	const handlePauseTour = useCallback(() => {
		setBasicTourUnpaused(false);

		setTourConfig((prevState) => ({
			...prevState,
			stepIndex: 0
		}));
	}, [setBasicTourUnpaused]);

	const tourCallback = useCallback(
		(data) => {
			const { action, index, status, type } = data;

			if (EVENTS.STEP_AFTER === type) {
				handleStepAfter(index, action);
			}

			if (isTourEndedStatus(status)) {
				handleEndTour();
			}

			if (shouldPauseTour(status, type)) {
				handlePauseTour();
			}
		},
		[handleStepAfter, handleEndTour, handlePauseTour]
	);

	useEffect(() => {
		setTourConfig((prevState) => ({
			...prevState,
			run: basicTourUnpaused
		}));
	}, [basicTourUnpaused]);

	useEffect(() => {
		// Check step for campaigns page
		const stepReady =
			tourStepName === 'New Campaign' &&
			location.pathname.includes('campaigns') &&
			activeTour?.tourStep === 1;

		if (stepReady) {
			setBasicTourUnpaused(true);

			setTourConfig((prevState) => ({
				...prevState,
				stepIndex: 1
			}));
		}
	}, [activeTour, location, setBasicTourUnpaused, tourStepName]);

	useEffect(() => {
		// Check step for landing pages page
		const landingPageRegex = /\/campaign\b/i;
		const stepReady =
			tourStepName === 'New Landing Page' &&
			landingPageRegex.test(location.pathname) &&
			activeTour?.tourStep === 2;

		if (stepReady) {
			setBasicTourUnpaused(true);

			setTourConfig((prevState) => ({
				...prevState,
				stepIndex: 2
			}));
		}
	}, [activeTour, location, setBasicTourUnpaused, tourStepName]);

	useEffect(() => {
		// Check step for configurator page
		const segments = location.pathname.split('/');
		const lastSegment = segments[segments.length - 1];
		const stepReady =
			tourStepName && lastSegment === 'product' && activeTour?.tourStep === 3;

		if (stepReady) {
			setBasicTourUnpaused(true);

			setTourConfig((prevState) => ({
				...prevState,
				stepIndex: 3
			}));
		}
	}, [activeTour, location, setBasicTourUnpaused, tourStepName]);

	return (
		<Joyride
			{...tourConfig}
			callback={tourCallback}
			styles={{
				options: {
					arrowColor: '#fff',
					backgroundColor: '#fff',
					overlayColor: 'rgba(0, 0, 0, 0.5)',
					textColor: '#333',
					primaryColor: '#0047FF'
				},
				tooltipTitle: {
					textAlign: 'left',
					fontSize: '16px',
					lineHeight: '1',
					fontWeight: '400'
				},
				tooltip: {
					paddingTop: '15px',
					before: {
						backgroundColor: '#0047FF'
					}
				}
			}}
		/>
	);
};

export default BasicTour;
