import { combineReducers } from 'redux';

import appDuck from './appDuck';
import authDuck from './authDuck';
import { routerReducer } from './routerDuck';
import translationDuck from './translationDuck';

const rootReducer = combineReducers({
	app: appDuck,
	router: routerReducer,
	auth: authDuck,
	translations: translationDuck
});

export default (state, action) => rootReducer(state, action);
