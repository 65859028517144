import { LANDING_PAGE_TYPE, PLATFORMS } from 'constants';

export const initDefaultConfiguration = (origin, config, type) => {
	const landingPageType = config?.GENERAL?.type ?? type;

	const data = {
		GENERAL: {
			name: config?.GENERAL?.name ?? '',
			type: landingPageType ?? LANDING_PAGE_TYPE.SKU,
			default_language: config?.GENERAL?.default_language ?? {
				language_name: 'English',
				language_code: 'en_US'
			},
			global_preset: config?.GENERAL?.global_preset ?? {
				id: -1
			},
			custom_font: config?.GENERAL?.custom_font ?? {
				name: 'default',
				font_url: 'https://www.pricespider.com',
				id: '-1'
			},
			layout: config?.GENERAL?.layout ?? 'boxed',
			color_scheme: config?.GENERAL?.color_scheme ?? '#000000',
			brand_logo: config?.GENERAL?.brand_logo ?? true,
			background_color: config?.GENERAL?.background_color ?? '#ffffff',
			background_image: config?.GENERAL?.background_image ?? false,
			header: config?.GENERAL?.header ?? {
				display_type: 'image',
				header_image_corners_style: 'default',
				header_mobile_image_corners_style: 'default'
			},
			favicon_image: config?.GENERAL?.favicon_image ?? false,
			third_party_tracking: config?.GENERAL?.third_party_tracking ?? false,
			meta_tags: config?.GENERAL?.meta_tags ?? false,
			redirection_url: config?.GENERAL?.redirection_url ?? '',
			tracking_id: config?.GENERAL?.tracking_id ?? '',
			active: config?.GENERAL?.active ?? true,
			google_tag_manager_id: config?.GENERAL?.google_tag_manager_id ?? ''
		},
		BUY_NOW_ONLINE: {
			retailers: config?.BUY_NOW_ONLINE?.retailers ?? [],
			retailers_sort: config?.BUY_NOW_ONLINE?.retailers_sort ?? 'alphabet asc',
			max_retailer_per_page:
				config?.BUY_NOW_ONLINE?.max_retailer_per_page ?? '3',
			price:
				config?.BUY_NOW_ONLINE?.price ??
				(origin === PLATFORMS.ps && landingPageType === LANDING_PAGE_TYPE.SKU),
			buy_now_button_label: config?.BUY_NOW_ONLINE?.buy_now_button_label ?? '',
			a2c_button_label: config?.BUY_NOW_ONLINE?.a2c_button_label ?? 'Buy Now',
			business_rule: config?.BUY_NOW_ONLINE?.business_rule ?? null,
			buy_now_label_color:
				config?.BUY_NOW_ONLINE?.buy_now_label_color ?? '#757575',
			buy_now_border_color:
				config?.BUY_NOW_ONLINE?.buy_now_border_color ?? '#E0E0E0',
			buy_now_background_color:
				config?.BUY_NOW_ONLINE?.buy_now_background_color ?? '#E0E0E0',
			show_more_button_color:
				config?.BUY_NOW_ONLINE?.show_more_button_color ?? '#757575',
			product_reviews: config?.BUY_NOW_ONLINE?.product_reviews ?? false,
			product_quantity: config?.BUY_NOW_ONLINE?.product_quantity ?? false,
			retailer_name: config?.BUY_NOW_ONLINE?.retailer_name ?? false,
			delivery_time: config?.BUY_NOW_ONLINE?.delivery_time ?? false,
			cart_icon: config?.BUY_NOW_ONLINE?.cart_icon ?? false,
			retailer_selection: config?.BUY_NOW_ONLINE?.retailer_selection ?? '20',
			filters_color: config?.BUY_NOW_ONLINE?.filters_color ?? '#000000',
			fast_delivery_filter:
				config?.BUY_NOW_ONLINE?.fast_delivery_filter ?? false,
			retailer_type_b2b_b2c_filter:
				config?.BUY_NOW_ONLINE?.retailer_type_b2b_b2c_filter ?? false,
			sub_id: config?.BUY_NOW_ONLINE?.sub_id
				? {
						sub_id: config?.BUY_NOW_ONLINE?.sub_id,
						sub_id_uid: config?.BUY_NOW_ONLINE?.sub_id_uid
				  }
				: null,
			footer_disclaimer: config?.BUY_NOW_ONLINE?.footer_disclaimer ?? false,
			exit_intent_popup: config?.BUY_NOW_ONLINE?.exit_intent_popup ?? false
		},
		PRODUCT: {
			product_info_color: config?.PRODUCT?.product_info_color ?? '#000000',
			product_title: config?.PRODUCT?.product_title ?? false,
			product_description: config?.PRODUCT?.product_description ?? false,
			product_image: config?.PRODUCT?.product_image ?? false,
			variant_selection: config?.PRODUCT?.variant_selection ?? false,
			product_variants: config?.PRODUCT?.product_variants ?? false,
			product_variants_title: config?.PRODUCT?.product_variants_title ?? ''
		}
	};

	if (landingPageType === LANDING_PAGE_TYPE.SKU) {
		data.PRODUCT.campaign_source = {
			type: config?.PRODUCT?.campaign_source.type ?? 'dynamic',
			products: config?.PRODUCT?.campaign_source.products ?? [],
			campaign_url: config?.PRODUCT?.campaign_source.campaign_url ?? ''
		};
	}

	// if (origin === PLATFORMS.ps) {
	// 	data.BUY_NOW_ONLINE.doordash = config?.BUY_NOW_ONLINE?.doordash ?? true;
	// }

	if (origin === PLATFORMS.hatch) {
		data.PRODUCT.campaign_source.campaign_url =
			config?.PRODUCT?.campaign_source.campaign_url ?? '';
		data.BUY_NOW_ONLINE.retailers_sort =
			config?.BUY_NOW_ONLINE?.retailers_sort || 'default';
		data.BUY_NOW_ONLINE.subtags = config?.BUY_NOW_ONLINE?.subtags || '';
		data.BUY_NOW_ONLINE.retailers_hide_out_of_stock =
			config?.BUY_NOW_ONLINE?.retailers_hide_out_of_stock || false;
		data.BUY_NOW_ONLINE.retailers_hide_pre_order =
			config?.BUY_NOW_ONLINE?.retailers_hide_pre_order || false;
	}

	if (origin === PLATFORMS.hatch || origin === PLATFORMS.cc) {
		data.PRODUCT.product_variants_title =
			config?.PRODUCT?.product_variants_title ?? '';
	}

	if (origin !== PLATFORMS.cc) {
		data.BUY_NOW_ONLINE.in_stock_status =
			config?.BUY_NOW_ONLINE?.in_stock_status ?? false;
		data.BUY_NOW_ONLINE.out_stock_status =
			config?.BUY_NOW_ONLINE?.out_stock_status ?? false;
	}

	if (origin === PLATFORMS.cc) {
		data.PRODUCT.product_info_type =
			config?.PRODUCT?.product_info_type ?? 'automatic';
	}

	return data;
};

export const initDefaultPresetConfiguration = (
	origin,
	config,
	type,
	name,
	brandName,
	brandId
) => {
	const landingPageType = config?.GENERAL?.type ?? type;

	const data = {
		GENERAL: {
			name: config?.GENERAL?.name ?? name,
			type: landingPageType ?? LANDING_PAGE_TYPE.SKU,
			default_language: config?.GENERAL?.default_language ?? {
				language_name: 'English',
				language_code: 'en_US'
			},
			custom_font: config?.GENERAL?.custom_font ?? {
				name: 'default',
				font_url: 'https://www.pricespider.com',
				id: '-1'
			},
			layout: config?.GENERAL?.layout ?? 'boxed',
			brand_logo: config?.GENERAL?.brand_logo ?? true,
			background_color: config?.GENERAL?.background_color ?? '#ffffff',
			header: config?.GENERAL?.header ?? {
				display_type: 'image',
				header_image_corners_style: 'default',
				header_mobile_image_corners_style: 'default'
			},
			favicon_image: config?.GENERAL?.favicon_image ?? false,
			redirection_url: config?.GENERAL?.redirection_url ?? ''
		},
		BUY_NOW_ONLINE: {
			price:
				config?.BUY_NOW_ONLINE?.price ??
				(origin === PLATFORMS.ps && landingPageType === LANDING_PAGE_TYPE.SKU),
			buy_now_label_color:
				config?.BUY_NOW_ONLINE?.buy_now_label_color ?? '#757575',
			buy_now_border_color:
				config?.BUY_NOW_ONLINE?.buy_now_border_color ?? '#E0E0E0',
			buy_now_background_color:
				config?.BUY_NOW_ONLINE?.buy_now_background_color ?? '#E0E0E0',
			max_retailer_per_page:
				config?.BUY_NOW_ONLINE?.max_retailer_per_page ?? '3',
			retailer_name: config?.BUY_NOW_ONLINE?.retailer_name ?? false,

			cart_icon: config?.BUY_NOW_ONLINE?.cart_icon ?? false
		}
	};

	if (origin !== PLATFORMS.ps) {
		data.GENERAL.background_image = config?.GENERAL?.background_image ?? false;
	}

	if (origin !== PLATFORMS.cc) {
		data.manufacturer = config?.manufacturer || {
			id: brandId,
			name: brandName
		};
		data.BUY_NOW_ONLINE.in_stock_status =
			config?.BUY_NOW_ONLINE?.in_stock_status ?? false;
		data.BUY_NOW_ONLINE.out_stock_status =
			config?.BUY_NOW_ONLINE?.out_stock_status ?? false;
	}

	if (origin === PLATFORMS.ps) {
		data.BUY_NOW_ONLINE.product_reviews =
			config?.BUY_NOW_ONLINE?.product_reviews ?? false;
		data.BUY_NOW_ONLINE.product_quantity =
			config?.BUY_NOW_ONLINE?.product_quantity ?? false;
	}

	if (origin === PLATFORMS.hatch) {
		data.BUY_NOW_ONLINE.retailers_sort =
			config?.BUY_NOW_ONLINE?.retailers_sort || 'default';
		data.BUY_NOW_ONLINE.retailers_hide_out_of_stock =
			config?.BUY_NOW_ONLINE?.retailers_hide_out_of_stock || false;
		data.BUY_NOW_ONLINE.retailers_hide_pre_order =
			config?.BUY_NOW_ONLINE?.retailers_hide_pre_order || false;
	}

	if (origin === PLATFORMS.cc) {
		data.BUY_NOW_ONLINE.retailer_selection =
			config?.BUY_NOW_ONLINE?.retailer_selection ?? '20';
		data.BUY_NOW_ONLINE.delivery_time =
			config?.BUY_NOW_ONLINE?.delivery_time ?? false;
	}

	return data;
};
