/* eslint-disable no-use-before-define */
import { format } from 'date-fns';
import { isObject } from 'utils';

// AUDIT CHANGE DESCRIPTION HELPER FUNCTIONS

let changedFieldsFinalDescription = [];

const CREATED_TYPES = {
	shoppable_campaign: 'Campaign',
	shoppable_product: 'Landing page',
	shoppable_tracking_profile: 'Tracking Profile',
	shoppable_business_rule: 'Business Rule',
	shoppable_custom_font: 'Custom Font',
	shoppable_global_preset: 'Global Preset'
};

const processChangedFieldNestedValue = (nestedFieldName, nestedFieldValue) =>
	isObject(nestedFieldValue) && nestedFieldValue.name
		? `Changed ${nestedFieldName} to ${nestedFieldValue.name}`
		: null;

const processChangedFieldValue = (changedFieldName, changedFieldValue) => {
	if (isObject(changedFieldValue)) {
		if (Array.isArray(changedFieldValue)) {
			changedFieldValue.forEach((item) =>
				processChangedFieldValue(changedFieldName, item)
			);
		} else {
			Object.entries(changedFieldValue).forEach(([nestedKey, nestedValue]) => {
				const nestedField = nestedKey.replaceAll('_', ' ');
				const nestedFieldChangeDescription = processChangedFieldNestedValue(
					nestedField,
					nestedValue
				);
				if (nestedFieldChangeDescription) {
					changedFieldsFinalDescription.push(nestedFieldChangeDescription);
				}
				processChangedFieldValue(nestedField, nestedValue);
			});
		}
	} else {
		const changedFieldNameFormatted = changedFieldName.replaceAll('_', ' ');
		const changedFieldValueFormatted = [null, ''].includes(changedFieldValue)
			? 'empty value'
			: changedFieldValue;
		changedFieldsFinalDescription.push(
			`Changed ${changedFieldNameFormatted} to ${changedFieldValueFormatted}`
		);
	}
};

const getDescriptionsArray = (changedFields) => {
	Object.keys(changedFields).forEach((key) => {
		const value = changedFields[key];
		processChangedFieldValue(key, value);
	});

	const changesDescription = changedFieldsFinalDescription;
	changedFieldsFinalDescription = [];
	return changesDescription;
};

const getDescriptionsProduct = (obj) => {
	const sections = Object.keys(obj).map((section) => obj[section]);
	return sections.flatMap((section) => getDescriptionsArray(section));
};

export const getDescriptions = (type, obj) =>
	type === 'shoppable_product'
		? getDescriptionsProduct(obj)
		: getDescriptionsArray(obj);

// AUDIT DATE RANGE AND PARAMS HELPER FUNCTIONS

const getOffset = (num, itemsPerPage) => ({ offset: num * itemsPerPage });

const getStartDate = () => new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

const getStartPickerDate = () => format(getStartDate(), 'yyyy-MM-dd HH:mm:ss');

const getEndPickerDate = () => {
	const endOfDay = new Date();
	endOfDay.setHours(23, 59, 59, 999);
	return format(endOfDay, 'yyyy-MM-dd HH:mm:ss');
};

const getFromTo = (startDate, endDate) => ({
	from: startDate
		? format(startDate, 'yyyy-MM-dd HH:mm:ss')
		: getStartPickerDate(format),
	to: endDate
		? format(endDate, 'yyyy-MM-dd HH:mm:ss')
		: getEndPickerDate(format)
});

const getIdAndType = ({
	productid,
	campaignid,
	presetid,
	trackingProfileId,
	businessRuleId,
	customFontId
}) => {
	if (trackingProfileId)
		return {
			id: trackingProfileId,
			type: 'shoppable_tracking_profile'
		};
	if (productid)
		return {
			id: productid,
			campaignid,
			type: 'shoppable_product'
		};
	if (presetid)
		return {
			id: presetid,
			campaignid,
			type: 'shoppable_global_preset'
		};
	if (businessRuleId)
		return {
			id: businessRuleId,
			type: 'shoppable_business_rule'
		};
	if (customFontId)
		return {
			id: customFontId,
			type: 'shoppable_custom_font'
		};
	return {
		id: campaignid,
		type: 'shoppable_campaign'
	};
};

const getQuery = (
	params,
	startDate,
	endDate,
	sortType,
	sortBy,
	pageNum,
	itemsPerPage
) => ({
	...getIdAndType(params),
	...getFromTo(startDate, endDate),
	sortType,
	sortBy,
	...getOffset(pageNum, itemsPerPage),
	itemsPerPage
});

const formatSortValue = (field) =>
	field === 'date' ? 'action_time' : field || 'action_time';

// URL HELPER FUNCTIONS

const constructPaginationChangeUrl = (
	type,
	params,
	location,
	page,
	perPage
) => {
	const {
		campaignid,
		productid,
		trackingProfileId,
		businessRuleId,
		customFontId
	} = params;
	const searchQuery = location.search;
	let url;

	switch (type) {
		case 'shoppable_campaign':
			url = `/audit/campaign/${campaignid}`;
			break;
		case 'shoppable_product':
			url = `/audit/campaign/${campaignid}/product/${productid}`;
			break;
		case 'shoppable_tracking_profile':
			url = `/audit/configurations/third-party-tracking/${trackingProfileId}`;
			break;
		case 'shoppable_business_rule':
			url = `/audit/configurations/retailers/${businessRuleId}`;
			break;
		case 'shoppable_custom_font':
			url = `/audit/assets/custom-fonts/${customFontId}`;
			break;
		default:
			return null;
	}

	url += `/${page}/${perPage}${searchQuery}`;
	return url;
};

export {
	CREATED_TYPES,
	getStartPickerDate,
	getEndPickerDate,
	getIdAndType,
	constructPaginationChangeUrl,
	formatSortValue,
	getQuery
};
