import { LANDING_PAGE_TYPE } from 'constants';
import { strToBool, filterOutNull } from '../data/dataUtils';

export const formatOffset = (offset, limit) => parseInt(offset * limit, 10);

export const formatLimit = (limit) => parseInt(limit, 10);

const formatIds = (ids) => ids.split(',');

const formatStatusFilters = (active) => {
	const val = active.split(',');
	if (val.length === 1) {
		return strToBool(active);
	}

	// Return null or another desired default value if 'active' should be deleted.
	return null;
};

const formatType = (type) => {
	if (!type) {
		return [LANDING_PAGE_TYPE.SKU, LANDING_PAGE_TYPE.GENERIC];
	}

	if (typeof type === 'string') {
		return type.split(',');
	}

	return [LANDING_PAGE_TYPE.SKU, LANDING_PAGE_TYPE.GENERIC];
};

export const formatParams = (params) => {
	const isStringNumberRegex = /^\d+$/;
	const formatted = { ...params };

	if (params?.offset) {
		formatted.offset = formatOffset(params.offset, params.limit);
	}

	if (params?.limit) {
		formatted.limit = formatLimit(params.limit);
	}

	if (params?.ids) {
		formatted.ids = formatIds(params.ids);
	}

	if (params?.linkIds) {
		formatted.linkIds = formatIds(params?.linkIds);
	}

	if (params?.hashIds) {
		formatted.hashIds = formatIds(params.hashIds);
	}

	if (params.manufacturers) {
		formatted.manufacturers = params.manufacturers
			.split(',')
			.map((val) => (isStringNumberRegex.test(val) ? Number(val) : val));
	}

	if (params.locales) {
		formatted.locales = params.locales
			.split(',')
			.map((val) => (isStringNumberRegex.test(val) ? Number(val) : val));
	}

	if (params?.created_by) {
		formatted.created_by = params?.created_by.split(',');
	}

	if (params?.active) {
		formatted.active = formatStatusFilters(params.active);
	}

	if (params?.activeOnLp) {
		formatted.activeOnLp = formatStatusFilters(params.activeOnLp);
	}

	if (params.types) {
		formatted.types = formatType(params.types);
	}

	if (params.folder_id) {
		formatted.folder_id = Number(formatted.folder_id);
	}

	if (params.keywords) {
		formatted.keywords = params.keywords
			.split(',')
			.map((val) => (isStringNumberRegex.test(val) ? Number(val) : val));
	}

	return filterOutNull(formatted);
};

export const formatNextPage = (nextPage) => {
	if (!nextPage) return {};

	// Remove the initial '&' if present and split the string into its components
	const queryParams = nextPage.startsWith('&') ? nextPage.slice(1) : nextPage;
	const paramsArray = queryParams.split('&');

	// Reduce the array of "key=value" strings into an object
	const paramsObject = paramsArray.reduce((acc, currentParam) => {
		const [key, value] = currentParam.split('=');
		if (key && value) {
			acc[key] = Number(decodeURIComponent(value)); // Ensure decoding in case of encoded characters
		}
		return acc;
	}, {});

	return paramsObject;
};
