import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup
} from '@mui/material';

/**
 * Reusable Radio Group form control for use with React Hook Form.
 *
 * @param {object} control - Control object from 'react-hook-form' useController hook.
 * @param {string} name - Name of the control, should be unique within the form.
 * @param {string} label - Label to be displayed for the control.
 * @param {Array} options - Array of options to be displayed in the RadioGroup. Each option should be an object with 'value' and 'label'.
 */
const RadioGroupInput = ({ control, name, label, options }) => (
	<Controller
		control={control}
		name={name}
		render={({ field }) => (
			<FormControl fullWidth mt={2}>
				<FormLabel sx={{ color: '#000' }}>{label}</FormLabel>
				<RadioGroup {...field} row>
					{options.map((option) => (
						<FormControlLabel
							key={option.value}
							value={option.value}
							control={<Radio />}
							label={option.label}
						/>
					))}
				</RadioGroup>
			</FormControl>
		)}
	/>
);

RadioGroupInput.propTypes = {
	control: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired
		})
	).isRequired
};

export default RadioGroupInput;
