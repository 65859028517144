/* eslint-disable no-use-before-define */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import FilledInput from '@mui/material/FilledInput';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import FilterDropdownItem from './FilterDropdownItem';

const ITEM_HEIGHT = 50;

const FilterDropdownV2 = ({ filter, onSubmit }) => {
	const {
		name,
		fieldName,
		paramName,
		withSearch = false,
		items: options
	} = filter;

	const [anchorEl, setAnchorEl] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [optionsList, setOptionsList] = useState(options);

	const open = Boolean(anchorEl);
	const selectedOptions = useMemo(
		() => optionsList.filter((item) => item.isSelected),
		[optionsList]
	);
	const isAllSelected = selectedOptions?.length >= optionsList?.length;
	const dropdownHeight =
		optionsList.length > 10
			? 400
			: Math.max(optionsList.length, 2) * ITEM_HEIGHT;

	useEffect(() => {
		const sorted = sortArrayBySelection(options, selectedOptions, 'value');
		setOptionsList(sorted);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	const handleOpen = (event) => setAnchorEl(event.currentTarget);

	const handleClose = useCallback(() => {
		setAnchorEl(null);

		if (!optionsList?.length || !selectedOptions.length) {
			setTimeout(() => {
				setSearchValue('');
				const sorted = sortArrayBySelection(options, selectedOptions, 'value');
				setOptionsList(sorted);
			}, 500);
		}
	}, [optionsList?.length, options, selectedOptions]);

	const handleLocalSearch = useCallback(
		({ target: { value } }) => {
			const searchTerm = value.toLowerCase();
			const filteredOptions = options.filter(
				({ label, value: optionValue }) =>
					label.toLowerCase().includes(searchTerm) ||
					optionValue.toString().toLowerCase().includes(searchTerm)
			);

			const sorted = sortArrayBySelection(
				filteredOptions,
				selectedOptions,
				'value'
			);

			setOptionsList(sorted);
			setSearchValue(value);
		},
		[options, selectedOptions]
	);

	const handleChange = useCallback(
		(option) => {
			setOptionsList((prevOptions) =>
				prevOptions.map((prevOption) => {
					if (prevOption.value === option.value) {
						return { ...prevOption, isSelected: !prevOption.isSelected };
					}
					return prevOption;
				})
			);
		},
		[setOptionsList]
	);

	const handleAllSelect = useCallback(() => {
		if (isAllSelected) {
			setOptionsList((prevOptions) =>
				prevOptions.map((prevOption) => ({ ...prevOption, isSelected: false }))
			);
		} else {
			setOptionsList((prevOptions) =>
				prevOptions.map((prevOption) => ({ ...prevOption, isSelected: true }))
			);
		}
	}, [isAllSelected, setOptionsList]);

	const handleSubmit = useCallback(() => {
		handleClose();
		onSubmit({ name: paramName, selected: selectedOptions, fieldName });
	}, [handleClose, onSubmit, paramName, selectedOptions, fieldName]);

	return (
		<Box>
			<Button
				variant="text"
				data-testid={`${name}Filter`}
				onClick={handleOpen}
				sx={{
					color: 'rgba(0, 0, 0, 0.87)',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					maxWidth: '100%' // Adjust as needed to fit your layout
				}}
				endIcon={<ArrowDropDownIcon />}
			>
				{name}
			</Button>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<Box sx={{ minWidth: '200px' }}>
					{withSearch && (
						<Box padding={1}>
							<FilledInput
								fullWidth
								autoFocus
								hiddenLabel
								data-testid={`${name}Search`}
								value={searchValue}
								disableUnderline
								startAdornment={<SearchIcon />}
								placeholder="Search..."
								size="small"
								onChange={handleLocalSearch}
							/>
						</Box>
					)}

					<FixedSizeList
						height={dropdownHeight}
						width="100%"
						itemSize={ITEM_HEIGHT}
						itemCount={optionsList.length}
						overscanCount={5}
					>
						{({ index, style }) => (
							<FilterDropdownItem
								index={index}
								style={style}
								optionsList={optionsList}
								handleChange={handleChange}
								paramName={paramName}
							/>
						)}
					</FixedSizeList>

					{optionsList.length > 0 && (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								background: '#FAFAFA',
								padding: '8px 16px 8px 8px'
							}}
						>
							<Typography fontSize="12px" color="rgba(0, 0, 0, 0.6)">
								{optionsList.length} options available
							</Typography>

							<Button
								variant="text"
								sx={{
									fontSize: '12px',
									color: isAllSelected ? '#D32F2F' : '#0047FF',
									textTransform: 'none',
									fontWeight: '400',
									padding: '0'
								}}
								onClick={handleAllSelect}
							>
								{isAllSelected ? 'Unselect all' : 'Select all'}
							</Button>
						</Box>
					)}

					<Box
						display="flex"
						justifyContent="space-evenly"
						padding="8px"
						borderTop="1px solid rgba(0, 0, 0, 0.12)"
					>
						<Button variant="cancel" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={!optionsList.length}
							data-testid="ApplyButton"
							onClick={handleSubmit}
						>
							Apply
						</Button>
					</Box>
				</Box>
			</Popover>
		</Box>
	);
};

function sortArrayBySelection(options, selection, key) {
	const selected = options.filter((element) =>
		selection.some((selectedElement) => selectedElement[key] === element[key])
	);
	const unselected = options.filter(
		(element) =>
			!selection.some(
				(selectedElement) => selectedElement[key] === element[key]
			)
	);
	return [...selected, ...unselected];
}

FilterDropdownV2.propTypes = {
	filter: PropTypes.shape({
		name: PropTypes.string.isRequired,
		fieldName: PropTypes.string.isRequired,
		paramName: PropTypes.string.isRequired,
		withSearch: PropTypes.bool,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				isSelected: PropTypes.bool.isRequired,
				label: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
					PropTypes.bool
				]).isRequired
			})
		).isRequired
	}).isRequired,
	onSubmit: PropTypes.func.isRequired
};

export default FilterDropdownV2;
