const env = process.env.REACT_APP_IS_STAGE;
const isStage = env === 'true';

const scriptSrc = isStage
	? 'https://shoppable-test.global.commerce-connector.com/shoppablelp.min.js'
	: 'https://shop.pricespider.com/shoppablelp.min.js';

const sharedDocumentation = (location) =>
	location.pathname === `/shared-documentation`;

export const languageCodes = [
	{
		language: 'Arabic',
		code: 'ar'
	},
	{
		language: 'Arabic (Saudi Arabia)',
		code: 'ar-sa'
	},
	{
		language: 'Bulgarian',
		code: 'bg'
	},
	{
		language: 'Chinese (PRC)',
		code: 'zh'
	},
	{
		language: 'Chinese (Traditional)',
		code: 'zn'
	},
	{
		language: 'Chinese (Hong Kong)',
		code: 'zn-hk'
	},
	{
		language: 'Chinese (Taiwan)',
		code: 'zn-tw'
	},
	{
		language: 'Croatian',
		code: 'hr'
	},
	{
		language: 'Czech',
		code: 'cs'
	},
	{
		language: 'Danish',
		code: 'da'
	},
	{
		language: 'Dutch',
		code: 'nl'
	},
	{
		language: 'Dutch (Belgium)',
		code: 'nl-be'
	},
	{
		language: 'English (Australia)',
		code: 'en-au'
	},
	{
		language: 'English (Canada)',
		code: 'en-ca'
	},
	{
		language: 'English (Hong Kong)',
		code: 'en-hk'
	},
	{
		language: 'English (Singapore)',
		code: 'en-sg'
	},
	{
		language: 'English (United Kingdom)',
		code: 'en-gb'
	},
	{
		language: 'English (United States)',
		code: 'en-us'
	},
	{
		language: 'Estonian',
		code: 'et'
	},
	{
		language: 'Finnish',
		code: 'fi'
	},
	{
		language: 'French',
		code: 'fr'
	},
	{
		language: 'French (Belgium)',
		code: 'fr-be'
	},
	{
		language: 'French (Canada)',
		code: 'fr-ca'
	},
	{
		language: 'French (Morocco)',
		code: 'fr-ma'
	},
	{
		language: 'French (Switzerland)',
		code: 'fr-ch'
	},
	{
		language: 'German',
		code: 'de'
	},
	{
		language: 'German (Austria)',
		code: 'de-at'
	},
	{
		language: 'German (Switzerland)',
		code: 'de-ch'
	},
	{
		language: 'Greek',
		code: 'el'
	},
	{
		language: 'Hebrew',
		code: 'he'
	},
	{
		language: 'Hungarian',
		code: 'hu'
	},
	{
		language: 'Indonesian',
		code: 'id'
	},
	{
		language: 'Italian',
		code: 'it'
	},
	{
		language: 'Japanese',
		code: 'ja'
	},
	{
		language: 'Korean',
		code: 'ko'
	},
	{
		language: 'Latvian',
		code: 'lv'
	},
	{
		language: 'Lithuanian',
		code: 'lt'
	},
	{
		language: 'Norwegian',
		code: 'no'
	},
	{
		language: 'Polish',
		code: 'pl'
	},
	{
		language: 'Portuguese',
		code: 'pt'
	},
	{
		language: 'Portuguese (Brazil)',
		code: 'pt-br'
	},
	{
		language: 'Romanian',
		code: 'ro'
	},
	{
		language: 'Russian',
		code: 'ru'
	},
	{
		language: 'Serbian',
		code: 'sr'
	},
	{
		language: 'Slovakian',
		code: 'sk'
	},
	{
		language: 'Slovenian',
		code: 'sl'
	},
	{
		language: 'Spanish (Spain)',
		code: 'es'
	},
	{
		language: 'Spanish (Argentina)',
		code: 'es-ar'
	},
	{
		language: 'Spanish (Chile)',
		code: 'es-cl'
	},
	{
		language: 'Spanish (Colombia)',
		code: 'es-co'
	},
	{
		language: 'Spanish (Mexico)',
		code: 'es-mx'
	},
	{
		language: 'Spanish (Peru)',
		code: 'es-pe'
	},
	{
		language: 'Swedish',
		code: 'sv'
	},
	{
		language: 'Thai',
		code: 'th'
	},
	{
		language: 'Turkish',
		code: 'tr'
	},
	{
		language: 'Ukrainian',
		code: 'uk'
	},
	{
		language: 'Vietnamese',
		code: 'vi'
	}
];

const getScriptSnippet = () => `
		<script
			id="shoppable-lp"
			type="text/javascript"
			src="${scriptSrc}"
			data-campaignid="{Enter_campaignid}"
			data-productid="{Enter_productid}"
			data-displaylanguage="{Enter_displaylanguage}"
			async>
		</script>
`;

const getMultipleScriptSnippet = () => `
		<script
			id="shoppable-lp"
			type="text/javascript"
			src="${scriptSrc}"
			data-displaylanguage="{Enter_displaylanguage}"
      data-multiple="true"
      data-configs='[
        {
          "campaignid": "{Enter_campaignid}",
          "productid": "{Enter_productid}",
          "target": "{Enter_target}"
        },
        {
          "campaignid": "{Enter_campaignid}",
          "productid": "{Enter_productid}",
          "target":  "{Enter_target}"
        }
      ]'
			async>
		</script>
`;

const getMultipleScriptSnippetSecond = () => `
		<script
			id="shoppable-lp"
			type="text/javascript"
			src="${scriptSrc}"
			data-displaylanguage="{Enter_displaylanguage}"
      data-utmsource="{Enter_utmsourcevalue}"
      data-utmmedium="{Enter_utmmediumevalue}"
      data-multiple="true"
      data-configs='[
        {
          "campaignid": "{Enter_campaignid}",
          "productid": "{Enter_productid}",
          "target": "{Enter_target}",
          "utmcontent": "{Enter_utmcontentvalue"}
        },
        {
          "campaignid": "{Enter_campaignid}",
          "productid": "{Enter_productid}",
          "target":  "{Enter_target}",
          "utmcontent": "{Enter_utmcontentvalue"}
        }
      ]'
			async>
		</script>
`;

export {
	sharedDocumentation,
	getScriptSnippet,
	getMultipleScriptSnippet,
	getMultipleScriptSnippetSecond
};
