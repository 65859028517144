import React from 'react';
import { SVGIcon, IconPropTypes, defaultIconColor } from '../SVGIcon';

const PlatformLogo = ({ color = defaultIconColor }) => (
	<SVGIcon
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		uid="cc-icon-platform"
		title="shoppable"
	>
		<path
			d="M15.9997 28.381C15.8563 28.3808 15.7156 28.3424 15.5919 28.2696C15.4683 28.1968 15.3662 28.0923 15.2961 27.9668L7.86227 14.7229C6.95513 13.2643 6.45386 11.5886 6.41054 9.86981C6.36722 8.15106 6.78344 6.45203 7.61595 4.9493C8.44845 3.44657 9.66685 2.19498 11.1445 1.32462C12.6222 0.454254 14.3052 -0.00312496 16.0187 1.60697e-05C17.7321 0.0031571 19.4135 0.466704 20.8879 1.34248C22.3624 2.21826 23.5763 3.4743 24.4033 4.98007C25.2303 6.48585 25.6404 8.18639 25.5908 9.90497C25.5412 11.6236 25.0338 13.2975 24.1214 14.7527L16.704 27.9707C16.6248 28.0878 16.5204 28.1855 16.3985 28.2565C16.2765 28.3276 16.1402 28.3701 15.9997 28.381ZM15.9997 1.37937C14.5303 1.379 13.0878 1.77392 11.8223 2.52299C10.5567 3.27207 9.5145 4.3479 8.80409 5.63845C8.09368 6.929 7.7411 8.38706 7.78305 9.86079C7.825 11.3345 8.25996 12.77 9.04262 14.0178L15.9997 26.4161L22.9403 14.0476C23.7287 12.8004 24.169 11.3636 24.215 9.88734C24.2611 8.41109 23.9113 6.94951 23.2021 5.65526C22.4929 4.36101 21.4504 3.28157 20.1834 2.5297C18.9165 1.77782 17.4716 1.38109 15.9997 1.38094V1.37937Z"
			fill={color}
		/>
		<path
			d="M15.998 31.9999C13.327 31.9999 0 31.7583 0 26.9988C0 24.6301 3.00403 23.1202 9.18563 22.3837C9.36543 22.367 9.54475 22.4203 9.68648 22.5325C9.82821 22.6448 9.92148 22.8075 9.94699 22.9868C9.95768 23.0766 9.95063 23.1676 9.92624 23.2547C9.90184 23.3417 9.86059 23.4231 9.80483 23.4941C9.74907 23.5651 9.6799 23.6244 9.60129 23.6686C9.52267 23.7128 9.43614 23.741 9.34665 23.7516C4.27897 24.3556 1.37343 25.5392 1.37343 26.9988C1.37343 29.639 10.3449 30.6226 15.9988 30.6226C21.6528 30.6226 30.6266 29.639 30.6266 26.9988C30.6266 25.5172 27.6491 24.3257 22.4579 23.7296C22.3684 23.7193 22.2817 23.6914 22.2029 23.6475C22.1241 23.6036 22.0546 23.5446 21.9986 23.4737C21.8853 23.3307 21.8333 23.1483 21.8541 22.9668C21.8748 22.7853 21.9665 22.6195 22.1091 22.5059C22.2517 22.3923 22.4334 22.3401 22.6143 22.3609C28.9295 23.0865 32 24.6034 32 26.9988C31.9984 31.7583 18.6699 31.9999 15.998 31.9999Z"
			fill={color}
		/>
		<path
			d="M19.4079 15.3919H12.5908C12.439 15.392 12.2915 15.3415 12.1714 15.2484C12.0513 15.1553 11.9654 15.0248 11.9271 14.8774L10.4896 9.34766C10.4631 9.24584 10.4602 9.13927 10.4811 9.03614C10.502 8.93301 10.5462 8.83607 10.6103 8.75276C10.6744 8.66944 10.7566 8.60198 10.8508 8.55554C10.9449 8.5091 11.0484 8.48492 11.1532 8.48486H20.8485C20.9535 8.48476 21.057 8.50884 21.1512 8.55523C21.2454 8.60162 21.3278 8.6691 21.3919 8.75245C21.456 8.83581 21.5002 8.93282 21.521 9.03601C21.5419 9.1392 21.5389 9.24583 21.5122 9.34766L20.0715 14.8774C20.033 15.0246 19.9471 15.155 19.827 15.2481C19.707 15.3411 19.5596 15.3917 19.4079 15.3919ZM13.12 14.0146H18.8779L19.9597 9.8622H12.0381L13.12 14.0146Z"
			fill={color}
		/>
		<path
			d="M18.7879 9.42056C18.6059 9.42056 18.4313 9.348 18.3026 9.21885C18.1739 9.0897 18.1016 8.91454 18.1016 8.73189C18.1016 8.17231 17.88 7.63564 17.4857 7.23995C17.0913 6.84426 16.5565 6.62196 15.9988 6.62196C15.4411 6.62196 14.9063 6.84426 14.512 7.23995C14.1176 7.63564 13.8961 8.17231 13.8961 8.73189C13.8961 8.91454 13.8238 9.0897 13.6951 9.21885C13.5664 9.348 13.3918 9.42056 13.2098 9.42056C13.0277 9.42056 12.8532 9.348 12.7245 9.21885C12.5957 9.0897 12.5234 8.91454 12.5234 8.73189C12.5234 7.80701 12.8896 6.92001 13.5414 6.26603C14.1931 5.61204 15.0771 5.24463 15.9988 5.24463C16.9206 5.24463 17.8045 5.61204 18.4563 6.26603C19.1081 6.92001 19.4742 7.80701 19.4742 8.73189C19.4743 8.82236 19.4566 8.91196 19.4222 8.99556C19.3877 9.07916 19.3372 9.15512 19.2734 9.21909C19.2097 9.28305 19.134 9.33378 19.0507 9.36835C18.9673 9.40292 18.8781 9.42066 18.7879 9.42056Z"
			fill={color}
		/>
	</SVGIcon>
);

PlatformLogo.propTypes = IconPropTypes;

export default PlatformLogo;
