import React from 'react';
import PropTypes from 'prop-types';

export const defaultIconSize = 24;
export const defaultIconColor = '#0047FF';

export const SVGIcon = ({
	width,
	height,
	viewBox,
	title,
	children,
	uid,
	...otherProps
}) => (
	<svg
		aria-hidden="true"
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		viewBox={viewBox}
		data-ccid={uid}
		{...otherProps}
	>
		{title && <title id={`title-${uid}`}>{title}</title>}
		{children}
	</svg>
);

export const IconPropTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	secondColor: PropTypes.string,
	pointerEvents: PropTypes.string
};

SVGIcon.propTypes = {
	uid: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	title: PropTypes.string.isRequired,
	viewBox: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired
};

export default SVGIcon;
