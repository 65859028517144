import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import httpClient from 'services/httpClient';
import responseHandler from 'services/responseHandler';

const BASE_URL = process.env.REACT_APP_CC_BASE_SERVICE_URL;

const interceptorReadySelector = (state) => state?.auth?.interceptorsReady;

const fetchAnalyticsDashboards = async (id) => {
	const response = await httpClient.api.get(`${BASE_URL}/looker-url/${id}`);
	return responseHandler.success(response);
};

export const useAnalyticsDashboards = (id) => {
	const interceptorReady = useSelector(interceptorReadySelector);

	return useQuery({
		queryKey: ['analytics-dashboards', id],
		queryFn: () => fetchAnalyticsDashboards(id),
		enabled: interceptorReady,
		refetchOnWindowFocus: false
	});
};
