import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import useMessage from './useMessage';

const MessageContext = createContext();

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const MessageProvider = ({ children }) => {
	const { message, sendMessage } = useMessage();

	return (
		<MessageContext.Provider value={sendMessage}>
			{children}
			<Snackbar
				open={!!message}
				autoHideDuration={message?.duration || 3000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				onClose={() => sendMessage(null)}
				sx={{ zIndex: 999999 }}
			>
				{message && (
					<Alert
						onClose={() => sendMessage(null)}
						severity={message?.type}
						variant="filled"
						// variant={message?.variant || 'filled'}
						sx={{ width: '100%', bgColor: 'background.paper' }}
					>
						{message?.text ? message.text : message}
					</Alert>
				)}
			</Snackbar>
		</MessageContext.Provider>
	);
};

MessageProvider.propTypes = {
	children: PropTypes.any.isRequired
};

const useMessageContext = () => useContext(MessageContext);

export { MessageProvider, useMessageContext };
