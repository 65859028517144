export const LANDING_PAGE_TYPE = {
	SKU: 'shoppable-product',
	GENERIC: 'shoppable-generic'
};

export const DIRECT_TO_RETAILER_TYPE = {
	SKU: 'shoppable-dtr-product',
	GENERIC: 'shoppable-dtr-generic'
};

export const INSIGHT_DIRECT_TO_RETAILER_TYPE = {
	[DIRECT_TO_RETAILER_TYPE.SKU]: 'Shoppable Global DTR Product',
	[DIRECT_TO_RETAILER_TYPE.GENERIC]: 'Shoppable Global DTR Generic'
};
