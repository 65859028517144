import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import httpClient from 'services/httpClient';
import responseHandler from 'services/responseHandler';

const baseProductsUrl = process.env.REACT_APP_CC_BASE_SERVICE_URL;

const useActiveConfigurations = (id, params) => {
	const [isLoading, setIsLoading] = useState(false);

	const fetchActiveRules = useCallback(async () => {
		// eslint-disable-next-line camelcase
		const { manufacturer_id, locales, ...paramsWithoutManufacturerAndLocales } =
			params;

		const response = await httpClient.api
			.post(`${baseProductsUrl}/products`, {
				...paramsWithoutManufacturerAndLocales,
				id
			})
			.then(responseHandler.success)
			.catch(responseHandler.error);

		return response.data;
	}, [id, params]);

	const { data, error, isFetching } = useQuery({
		queryKey: ['active-rules', id, params],
		queryFn: () => fetchActiveRules({ ...params, id }),
		enabled: !!id,
		refetchOnWindowFocus: false
	});

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	return { isLoading, data, error };
};

export default useActiveConfigurations;
