/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessageWrapper } from './ErrorBoundaryStyled';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorInfo: null };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// eslint-disable-next-line no-console
		console.log({ error, errorInfo });
		this.setState({ errorInfo });
	}

	render() {
		const { children } = this.props;
		const { hasError } = this.state;

		if (hasError) {
			return (
				<ErrorMessageWrapper>
					<p>Something went wrong. We are sorry for the inconvenience.</p>
					<p>
						Please message your contact in PriceSpider if this happens again.
					</p>
					<p>
						Go back to <a href="/">Home</a>
					</p>
				</ErrorMessageWrapper>
			);
		}

		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired
};

export default ErrorBoundary;
