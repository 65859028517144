import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { actionCreators as authActionCreators } from 'redux/ducks/authDuck';

export const useSetUserLicenses = () => {
	const dispatch = useDispatch();

	const updateUserLicenses = useCallback(
		(locale) => {
			dispatch(authActionCreators.getCCLicenses(locale));
		},
		[dispatch]
	);

	const resetUserLicenses = useCallback(() => {
		dispatch(authActionCreators.resetCCLicences());
	}, [dispatch]);

	return {
		updateUserLicenses,
		resetUserLicenses
	};
};
