import React from 'react';

export const TOUR_STATUS = {
	SKIPPED: 'skipped',
	FINISHED: 'finished',
	PAUSED: 'paused'
};

export const TOUR_NAME = 'tourName';
export const TOUR_STEP = 'tourStep';

// tours

export const basicTourState = () => ({
	run: false,
	showSkipButton: true,
	continuous: true,
	stepIndex: 0,
	steps: [
		{
			target: "[data-tour='campaigns']",
			content: (
				<div style={{ textAlign: 'left' }}>
					<p>Let&apos;s get started!</p>
					<p>
						Click <span style={{ fontWeight: 'bold' }}>Campaigns</span> to
						create a new campaign or manage already created ones.
					</p>
				</div>
			),
			placement: 'top',
			disableBeacon: true,
			title: '1/5'
		},
		{
			target: "[data-tour='new-campaign']",
			content: (
				<div style={{ textAlign: 'left' }}>
					<p>Click here to create a new campaign. </p>
					<p>
						We will guide you automatically to the next step where you can add
						landing pages to your campaign.
					</p>
					<p>
						By opening your campaign on the table below, you can manage landing
						pages at any time.
					</p>
				</div>
			),
			placement: 'bottom',
			disableBeacon: true,
			title: '2/5',
			styles: {
				spotlight: {
					border: '3px solid white'
				}
			}
		},
		{
			target: "[data-tour='new-landing-page']",
			content: (
				<div style={{ textAlign: 'left' }}>
					<p>Click here to create a new landing page.</p>
					<p>
						By opening your landing page on the table below, you can manage it
						at any time.
					</p>
				</div>
			),
			placement: 'bottom',
			disableBeacon: true,
			title: '3/5',
			styles: {
				spotlight: {
					border: '3px solid white'
				}
			}
		},
		{
			target: "[data-tour='landing-page-obligatory-fields']",
			content: (
				<div style={{ textAlign: 'left' }}>
					<p>Welcome to the Landing Page Configurator!</p>
					<p>
						In order to save the landing page, please fill out the mandatory
						fields as mentioned above.
					</p>
				</div>
			),
			placement: 'bottom',
			disableBeacon: true,
			title: '4/5'
		},
		{
			target: "[data-tour='save-landing-page']",
			content: (
				<div style={{ textAlign: 'left' }}>
					<p>To finish the landing page set-up, click SAVE!</p>
				</div>
			),
			placement: 'bottom',
			disableBeacon: true,
			title: '5/5'
		}
	]
});
