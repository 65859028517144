import styled from '@emotion/styled';

export const LinkSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	label: LinkSectionWrapper;

	a {
		text-decoration: none;
		color: rgba(0, 0, 0, 0.87);
	}

	ul {
		padding-top: 0;
	}

	.active {
		background: rgba(0, 71, 255, 0.04);
		color: #0047ff;

		> * {
			background: rgba(0, 71, 255, 0.04);
		}

		svg {
			fill: #0047ff;
		}

		svg {
			> * {
				fill: '#0047ff' !important;
			}
		}
	}

	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
`;
