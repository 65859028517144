import React, { useState, useEffect, Suspense } from 'react';

import { QueryClient } from '@tanstack/react-query';
import { Route, Routes } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import store, { history } from 'redux/store';

import ContextTree from 'contexts/ContextTree';
import ProtectedRoute from 'services/protectedRoute';
import authService from 'services/auth/authService';

import Snack from 'components/Snack/Snack';
import Layout from 'components/Layout/Layout';
import Pendo from 'components/Pendo/Pendo';
import TourGuide from 'components/TourGuide/TourGuide';
import CircularProgress from '@mui/material/CircularProgress';

import { routes } from './routes';
import theme from './theme';

const isStage = process.env.REACT_APP_IS_STAGE;
const queryClient = new QueryClient();

authService.determineHost(store);

const App = () => {
	const [showStageInfo, setShowStageInfo] = useState(false);

	useEffect(() => {
		const isBannerActivated = isStage === 'true';

		if (isBannerActivated) {
			setShowStageInfo(true);
		}
	}, []);

	return (
		<React.StrictMode>
			<ContextTree
				queryClient={queryClient}
				store={store}
				theme={theme}
				history={history}
			>
				<Layout>
					<Suspense
						fallback={
							<CircularProgress
								sx={{
									position: 'fixed',
									left: '50%',
									top: '50%',
									transform: 'translate(-50%, -50%)'
								}}
							/>
						}
					>
						<Routes>
							{routes.map((route) =>
								!route.disabled ? (
									<Route
										key={route.path}
										path={route.path}
										element={
											route.restrict ? (
												<ProtectedRoute>{route.element}</ProtectedRoute>
											) : (
												route.element
											)
										}
									/>
								) : null
							)}
						</Routes>
					</Suspense>
					<Snack />
					<Pendo />
					<TourGuide />
				</Layout>
				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={showStageInfo}
					message="You are currently on STAGE environment"
				/>
			</ContextTree>
		</React.StrictMode>
	);
};

export default App;
