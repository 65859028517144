import React from 'react';
import PropTypes from 'prop-types';

import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Global } from '@emotion/react';

import FeatureFlagsProvider from './FeatureFlag/FeatureFlagProvider';
import { MessageProvider } from 'contexts/Message/MessageContext';
import { ModalProvider } from './ModalProvider/ModalProvider';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import TourGuideProvider from 'contexts/TourGuide/TourGuideProvider';

import { GlobalStyle } from 'GlobalStyle';

const ContextTree = ({ queryClient, store, theme, history, children }) => (
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<Global styles={GlobalStyle} />
				<FeatureFlagsProvider>
					<Router location={history.location} history={history}>
						<ErrorBoundary history={history}>
							<ModalProvider>
								<TourGuideProvider>
									<MessageProvider>{children}</MessageProvider>
								</TourGuideProvider>
							</ModalProvider>
						</ErrorBoundary>
					</Router>
				</FeatureFlagsProvider>
			</ThemeProvider>
		</QueryClientProvider>
	</Provider>
);

ContextTree.propTypes = {
	queryClient: PropTypes.object.isRequired,
	store: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,

	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

export default ContextTree;
