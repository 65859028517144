const getDefaultLanguageDropdownOptions = (languagesObject) => {
	if (languagesObject) {
		const languageList = Object.entries(languagesObject).map(
			([languageCode, languageObject]) => {
				const languageName = languageObject.lang;
				return {
					label: `${languageName} (${languageCode})`,
					value: {
						language_name: languageName,
						language_code: languageCode
					}
				};
			}
		);

		return languageList;
	}

	return [];
};

const getDropdownOptions = (dropdownItems) =>
	dropdownItems.map((item) => {
		if (item.label && item.value) return item;
		if (item.token)
			return {
				value: item.token,
				label: item.description
					? `${item.description} ${item.token}`
					: item.token
			};

		return {
			value: { ...item },
			label: `${item.en ? item.en : item.name} (${
				item.language ? item.language : item.abbreviation
			})`
		};
	});

export { getDefaultLanguageDropdownOptions, getDropdownOptions };
