import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actionCreators as authActionCreators } from 'redux/ducks/authDuck';

import LoginComponent from './component';

const mapStateToProps = (state) => {
	const { user, error, origin, token, tokens, restrictedAccess, mfa, loading } =
		state.auth;

	return { user, error, origin, token, tokens, restrictedAccess, mfa, loading };
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			...authActionCreators
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
