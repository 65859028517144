/* eslint-disable no-console */
const handleResponse = async (response) => {
	if (response.status === 'error') {
		console.error(`ERROR: ${response.data.message}`);
		return Promise.reject(new Error(response.data.message));
	}

	return { data: response.data };
};

const handleError = async (error, customErrorMessage) => {
	const err = error?.response || error;
	const errorMessage = customErrorMessage || 'An error ocurred';
	console.error(errorMessage);
	return Promise.reject(err);
};

export default {
	success: handleResponse,
	error: handleError
};
