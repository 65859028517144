import axios from 'axios';
import responseHandler from '../responseHandler';

const ccLoginUrl = process.env.REACT_APP_AUTH_LOGIN_URL;
const ccAuthUrl = process.env.REACT_APP_AUTH_URL;
const ccServiceUrl = process.env.REACT_APP_CC_BASE_URL;

const ccLoginInstance = axios.create({
	baseURL: ccLoginUrl,
	timeout: 8000,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

const ccUserInstance = axios.create({
	baseURL: ccAuthUrl,
	timeout: 8000,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

const ccServiceInstance = axios.create({
	baseURL: ccServiceUrl,
	timeout: 8000,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: false
});

const getStorageToken = () => JSON.parse(localStorage.getItem('token'));

const logout = () => ccLoginInstance.post('/?method=logout');
const getToken = () => ccLoginInstance.post(`/?method=refresh`);

const getUser = () =>
	ccUserInstance
		.get(
			`/resource.php?access_token=${JSON.parse(
				localStorage.getItem('token')
			)}&scope=shoppable`,
			{ withCredentials: false }
		)
		.then(responseHandler.success)
		.catch((error) => Promise.reject(error));

const getCountries = async (controller) => {
	const options = {};

	if (controller) {
		options.signal = controller.signal;
	}

	const url = `${ccServiceUrl}/countries?page=1&pageSize=9999&activatedOn=online&sort=en&token=${getStorageToken()}`;
	return ccServiceInstance
		.get(url, options)
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getSubids = async (query) => {
	const url = `${ccServiceUrl}/subids?country_id=${
		query.country_id
	}&activatedOn=online&page=1&pageSize=999&token=${getStorageToken()}`;

	return ccUserInstance
		.get(url, { withCredentials: false })
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

const getTokens = async (country) => {
	const url = `${ccServiceUrl}/widget-licence?country=${country}&offset=0&limit=999999&token=${JSON.parse(
		localStorage.getItem('token')
	)}`;

	return ccServiceInstance
		.get(url, {
			withCredentials: false
		})
		.then(responseHandler.success)
		.catch(responseHandler.error);
};

export default {
	logout,
	getToken,
	getUser,
	getCountries,
	getTokens,
	getSubids
};
