import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useStore } from 'react-redux';

import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ps from 'services/auth/ps';

const ccRedirectUrl = process.env.REACT_APP_CC_REDIRECT_URL;
const ccLoginUrl = process.env.REACT_APP_CC_LOGIN_URL;
const psDomain = process.env.REACT_APP_PS_INSIGHTS;

const MSG = {
	errorCreds: 'Wrong credentials, please try again',
	redirect: 'Redirecting for an authentication...',
	apiUnavailable: 'API is not available for this user',
	login: 'Login in progress'
};

const TABS = [
	{ text: 'PriceSpider', id: 'ps' },
	{ text: 'Commerce-Connector', id: 'cc' },
	{ text: 'Hatch', id: 'hatch' }
];

const TABS_ENUM = {
	ps: 0,
	cc: 1,
	hatch: 2
};

const useQuery = () => {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
};

// eslint-disable-next-line no-unused-vars
const LoginForm = ({
	restrictedAccess,
	login,
	setOrigin,
	error,
	mfa,
	requestMFA,
	loading,
	forbiddenError
}) => {
	const { register, formState, clearErrors, getValues } = useForm({
		mode: 'onChange'
	});
	const params = useQuery();
	const source = params.get('source');
	const unauthorized = params.get('redirectedWith');
	const store = useStore();
	const navigate = useNavigate();

	const [activeTab, setActiveTab] = useState(TABS_ENUM[source] || 0);
	const [loginError, setLoginError] = useState(null);

	useEffect(() => {
		if (mfa) {
			setLoginError(null);
		} else if (error) {
			if (error?.error_description === 'MFA required but not passed') {
				// Do nothing because MFA is required but not passed
			} else if (error?.type === 'force_reset') {
				setLoginError(error?.error_description);
			} else if (mfa) {
				setLoginError('Wrong MFA code.');
			} else {
				setLoginError(error?.error_description || 'Something went wrong.');
			}
		} else if (restrictedAccess) {
			setLoginError(MSG.apiUnavailable);
		} else if (forbiddenError) {
			setLoginError(forbiddenError);
		}
	}, [mfa, error, restrictedAccess, forbiddenError, register]);

	const onSubmitHatch = useCallback(
		(values) => {
			const form = {
				username: values.email,
				password: values.password,
				grant_type: 'password',
				code: values.code,
				origin: 'hatch'
			};

			setLoginError(null);
			login(form, store, navigate);
		},
		[login, navigate, store]
	);

	const onSubmitPs = async (values) => {
		const ssoResponse = await ps.login(values.email, values.password);

		if (
			ssoResponse?.data?.clientType === 1 &&
			ssoResponse?.data?.message === 'login success'
		) {
			window.location.href = '/campaigns';
			return;
		}

		// window.location.href = '../';
		if (!ssoResponse.data.ssoRedirectLink) {
			setLoginError(MSG.errorCreds);
			return;
		}

		window.location.assign(`${psDomain}${ssoResponse.data.ssoRedirectLink}`);
	};

	const onSubmitCC = (values) => {
		window.location.assign(
			`${ccLoginUrl}?redirectUrl=${ccRedirectUrl}&username=${values.email}`
		);
	};

	const onSubmit = useCallback(() => {
		setLoginError(null);
		const values = getValues();

		switch (activeTab) {
			case TABS_ENUM.cc:
				onSubmitCC(values);
				break;
			case TABS_ENUM.ps:
				onSubmitPs(values);
				break;
			case TABS_ENUM.hatch:
				onSubmitHatch(values);
				break;
			default:
				return false;
		}

		return null;
	}, [activeTab, getValues, onSubmitHatch]);

	const handleKeyDown = useCallback(
		(event) => {
			if (event.key === 'Enter' && !loading && formState.isValid) {
				onSubmit();
			}
		},
		[formState.isValid, loading, onSubmit]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	const tabChanged = (event, newValue) => {
		setOrigin(newValue);
		setActiveTab(newValue);
		clearErrors();

		if (mfa) {
			requestMFA(false);
		}
	};

	return (
		<>
			<Paper>
				<Box
					component="form"
					name="login"
					display="flex"
					flexDirection="column"
					gap="10px"
					sx={{ padding: '32px' }}
					noValidate
					autoComplete="off"
				>
					<Tabs value={activeTab} onChange={tabChanged}>
						{TABS.map((tab) => (
							<Tab key={tab.text} label={tab.text} />
						))}
					</Tabs>

					<Box
						display="flex"
						flexDirection="column"
						gap="10px"
						marginTop="22px"
					>
						<TextField
							autoFocus
							required
							sx={{ display: mfa && 'none' }}
							name="email"
							type="email"
							label="Email Address"
							{...register('email', {
								required: true,
								pattern: /\S+@\S+\.\S+/
							})}
						/>

						{(activeTab === TABS_ENUM.ps || activeTab === TABS_ENUM.hatch) && (
							<TextField
								required
								sx={{ display: mfa && 'none' }}
								name="password"
								type="password"
								label="Password"
								{...register('password', { required: true })}
							/>
						)}

						{mfa && (
							<TextField
								autoFocus
								required
								name="code"
								type="text"
								label="MFA"
								{...register('code', { required: true })}
							/>
						)}
					</Box>

					<Button
						variant="contained"
						type="button"
						onClick={onSubmit}
						disabled={loading || !formState.isValid}
						sx={{ height: '40px', marginTop: '12px' }}
					>
						{loading && (
							<CircularProgress size={20} sx={{ marginRight: '10px' }} />
						)}
						Login
					</Button>
				</Box>
				{unauthorized && (
					<Alert severity="warning">
						Oops, seems like your login session has expired or your user is not
						allowed to access Shoppable Landing Pages.
						<br />
						Please log in again or reach out to us via our{' '}
						<a
							href="https://help.pricespider.com/hc/en-us"
							target="_blank"
							rel="noreferrer"
						>
							{' '}
							Help Center
						</a>{' '}
						if this problem persists.
					</Alert>
				)}
				{(error?.error_description || loginError) && (
					<Box>
						{loginError && (
							<Alert severity="error">
								{loginError}
								{loginError.includes('reset your password') && (
									<a
										href="https://my.gethatch.com/"
										target="_blank"
										rel="noreferrer"
									>
										{' '}
										Hatch Dashboard
									</a>
								)}
							</Alert>
						)}
					</Box>
				)}
			</Paper>
		</>
	);
};

LoginForm.propTypes = {
	restrictedAccess: PropTypes.bool,
	mfa: PropTypes.bool.isRequired,
	login: PropTypes.func.isRequired,
	setOrigin: PropTypes.func.isRequired,
	requestMFA: PropTypes.func.isRequired,
	error: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.oneOf([null])
	]),
	loading: PropTypes.bool,
	forbiddenError: PropTypes.string
};

LoginForm.defaultProps = {
	restrictedAccess: false,
	error: null,
	loading: false,
	forbiddenError: null
};

export default LoginForm;
