import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

const FilterDropdownItem = ({
	index,
	style,
	optionsList,
	handleChange,
	paramName
}) => {
	const option = optionsList[index];
	const { isSelected } = option;

	const handleFilterChange = useCallback(() => {
		handleChange(option);
	}, [handleChange, option]);

	return (
		<ListItemButton
			style={style}
			sx={{ padding: '0 5px' }}
			onClick={handleFilterChange}
		>
			<ListItemIcon sx={{ minWidth: '25px' }}>
				<Checkbox
					checked={isSelected}
					inputProps={{ 'aria-labelledby': option.label }}
				/>
			</ListItemIcon>

			{paramName === 'locales' && (
				<ListItemIcon sx={{ minWidth: '25px' }}>
					<Typography
						variant="span"
						minWidth="1em"
						minHeight="1em"
						sx={{
							backgroundImage: `url("/flags/${option.value.toLowerCase()}.svg")`,
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center center'
						}}
					/>
				</ListItemIcon>
			)}
			<Tooltip title={option.label} placement="top-end">
				<ListItemText
					primary={option.label}
					color="rgba(0, 0, 0, 0.87)"
					variant="two-line-ellipsis"
					primaryTypographyProps={{ sx: { fontSize: '14px' } }}
				/>
			</Tooltip>
		</ListItemButton>
	);
};

FilterDropdownItem.propTypes = {
	index: PropTypes.number.isRequired,
	style: PropTypes.object.isRequired,
	optionsList: PropTypes.arrayOf(
		PropTypes.shape({
			isSelected: PropTypes.bool.isRequired,
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
				PropTypes.bool
			]).isRequired
		})
	).isRequired,
	handleChange: PropTypes.func.isRequired,
	paramName: PropTypes.string.isRequired
};

export default FilterDropdownItem;
