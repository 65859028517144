/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
const getBrandAvailableCountries = (id, brands) => {
	const countries = brands
		.filter((brand) => brand.brandId === id)
		.map((brand) => brand.countryCode);

	return countries;
};

export const getManufacturerIds = (sgUserMappings) => {
	const manufacturer_ids = [];
	const manufacturer_countries = [];

	for (const countryCode in sgUserMappings) {
		manufacturer_countries.push(...sgUserMappings[countryCode]);
	}

	for (const countryCode in sgUserMappings) {
		manufacturer_ids.push(
			...sgUserMappings[countryCode].map((manufacturer) => ({
				label: manufacturer.brandName,
				locales: getBrandAvailableCountries(
					manufacturer.brandId,
					manufacturer_countries
				),
				value: {
					id: manufacturer.brandId,
					name: manufacturer.brandName
				}
			}))
		);
	}

	const uniqueArray = manufacturer_ids.filter(
		(item, index) =>
			index ===
			manufacturer_ids.findIndex((obj) => obj.value.id === item.value.id)
	);

	return Array.from(new Set(uniqueArray));
};
