import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [modals, setModals] = useState({});

	const openModal = (modalName) => {
		setModals((prev) => ({ ...prev, [modalName]: true }));
	};

	const closeModal = (modalName) => {
		setModals((prev) => ({ ...prev, [modalName]: false }));
	};

	const isOpen = (modalName) => !!modals[modalName];

	return (
		<ModalContext.Provider value={{ isOpen, openModal, closeModal }}>
			{children}
		</ModalContext.Provider>
	);
};

ModalProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export const useModalState = (modalName) => {
	const { isOpen, openModal, closeModal } = useContext(ModalContext);

	return {
		isOpen: isOpen(modalName),
		openModal: () => openModal(modalName),
		closeModal: () => closeModal(modalName)
	};
};
