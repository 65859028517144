import styled from '@emotion/styled';

export const IframeWrapper = styled.div`
	position: relative;
	padding-top: 56.25%;
`;

export const Iframe = styled.iframe`
	position: absolute;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	border: none;
`;

export const Container = styled.div`
	max-width: 100%;
	max-height: 100%;
`;
