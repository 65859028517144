import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { history } from 'redux/store';
import { actionCreators as authActionCreators } from 'redux/ducks/authDuck';
import { useTourGuideContext } from 'contexts/TourGuide/TourGuideContext';
import { useSelector, useDispatch } from 'react-redux';

import { PLATFORMS } from 'constants';
import { PlatformLogoIcon } from 'components/Icon';

import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Collapse from '@mui/material/Collapse';
import PreviewIcon from '@mui/icons-material/Preview';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import { Typography } from '@mui/material';
import { sidebarItems } from './sidebarItems';

import { LinkSectionWrapper } from './SidebarStyled';

const originSelector = (state) => (state ? state.auth.origin : null);
const userSelector = (state) => (state ? state.auth.user : null);

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: '10px',
	padding: '0 0 0 12px',

	// necessary for content to be below app bar
	...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

const Sidebar = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [showNested, setShowNested] = useState({
		assets: false,
		configurations: false
	});
	const [open, setOpen] = useState(true);

	const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
	const [isDrawerClickedAndClosed, setIsDrawerClickedAndClosed] =
		useState(false);

	const { setBasicTourUnpaused, handleActiveTour, handleTourStep } =
		useTourGuideContext();

	const location = useLocation();
	const dispatch = useDispatch();

	const origin = useSelector(originSelector);
	const user = useSelector(userSelector);

	const toggleNested = (nested) => {
		setShowNested((prevState) => ({
			...prevState,
			[nested.toLowerCase()]: !prevState[nested.toLowerCase()]
		}));
	};

	const handleAccountOpen = (event) => {
		setAnchorEl(event.currentTarget);
		setIsDrawerExpanded(true);
	};

	const handleAccountClose = () => {
		setAnchorEl(null);
		setIsDrawerExpanded(false);
	};

	const openPopover = Boolean(anchorEl);
	const id = openPopover ? 'simple-popover' : undefined;

	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	const startBasicTour = () => {
		setBasicTourUnpaused(true);

		handleActiveTour('basicTour');
		handleTourStep(0);
	};

	const handleResize = () => {
		setDimensions({
			width: window.innerWidth,
			height: window.innerHeight
		});
	};

	useEffect(() => {
		const { pathname } = location;

		const activeNestedItem = sidebarItems.reduce((acc, item) => {
			const foundChild = item.children.find((child) =>
				pathname.includes(child.path)
			);
			return foundChild || acc;
		}, null);

		if (activeNestedItem) {
			setShowNested((prevState) => ({
				...prevState,
				[activeNestedItem?.name.toLowerCase()]: true
			}));
		}
	}, [location]);

	useEffect(() => {
		window.addEventListener('resize', handleResize, false);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (dimensions.width < 1301) {
			setOpen(false);
		}
	}, [dimensions]);

	useEffect(() => {
		const { pathname } = location ?? {};

		if (pathname.includes('settings')) {
			setShowNested(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDrawerOpen = () => {
		setOpen(true);
		setIsDrawerExpanded(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
		setIsDrawerExpanded(false);
		setIsDrawerClickedAndClosed(true);
	};

	const handleMouseEnterDrawer = () => {
		const shouldOpenDrawer =
			(!isDrawerExpanded && isDrawerClickedAndClosed) ||
			dimensions.width < 1301;

		if (shouldOpenDrawer) {
			setOpen(true);
		}
	};

	const handleMouseLeaveDrawer = () => {
		const shouldCloseDrawer =
			(!isDrawerExpanded && isDrawerClickedAndClosed) ||
			dimensions.width < 1301;

		if (shouldCloseDrawer) {
			setOpen(false);
		}
	};

	const logoutUser = async () => {
		dispatch(authActionCreators.logout(origin, history));
	};

	const getClassName = (pathKeys, isDisabled) => {
		const currentPath = location.pathname;

		if (isDisabled) {
			return 'disabled';
		}

		return pathKeys && pathKeys.some((p) => currentPath.includes(p))
			? 'active'
			: '';
	};

	return (
		<Drawer
			elevation={0}
			variant="permanent"
			open={open}
			sx={{
				'& .MuiDrawer-paper': {
					border: 'none',
					zIndex: 96
				}
			}}
		>
			<DrawerHeader>
				{open ? (
					<>
						<IconButton onClick={handleDrawerClose}>
							<MenuIcon />
						</IconButton>
						<NavLink to="/campaigns" style={{ textDecoration: 'none' }}>
							<div
								style={{ width: '100%', display: 'flex', alignItems: 'center' }}
							>
								<PlatformLogoIcon />
								<Typography
									variant="p"
									component="p"
									color="#1B0C75"
									fontSize="17px"
									marginLeft="10px"
								>
									Shoppable
								</Typography>
							</div>
						</NavLink>
					</>
				) : (
					<Tooltip title={!open ? 'Expand Sidebar' : ''} placement="right">
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
						>
							<MenuIcon />
						</IconButton>
					</Tooltip>
				)}
			</DrawerHeader>
			<LinkSectionWrapper
				onMouseEnter={!isDrawerExpanded ? handleMouseEnterDrawer : undefined}
				onMouseLeave={!isDrawerExpanded ? handleMouseLeaveDrawer : undefined}
			>
				<List>
					{sidebarItems.map(
						(parent) =>
							parent.scope.includes(origin) && (
								<Box key={parent.name} marginBottom="8px">
									<Typography
										sx={{
											padding: '8px 20px',
											fontSize: '12px',
											color: '#00000099',
											textDecoration: 'uppercase'
										}}
									>
										{open ? parent?.name : parent?.name[0]}
									</Typography>

									{parent?.children &&
										parent?.children.map(
											(item) =>
												item.scope.includes(origin) && (
													<Fragment key={item.name}>
														<Tooltip
															title={!open ? item.name : ''}
															placement="right"
														>
															<ListItem
																data-tour={
																	item.name === 'Campaigns' ? 'campaigns' : ''
																}
																disablePadding
																sx={{ display: 'block' }}
															>
																{item.children ? (
																	<ListItemButton
																		onClick={() => toggleNested(item?.name)}
																		sx={{
																			justifyContent: open
																				? 'initial'
																				: 'center',
																			px: 2.5
																		}}
																	>
																		<ListItemIcon
																			sx={{
																				minWidth: 0,
																				mr: open ? 3 : 'auto',
																				justifyContent: 'center'
																			}}
																		>
																			{item.icon()}
																		</ListItemIcon>
																		<ListItemText
																			primary={item.name}
																			sx={{ opacity: open ? 1 : 0 }}
																			primaryTypographyProps={{
																				fontSize: 14,
																				textDecoration: 'none'
																			}}
																		/>
																		{showNested[item?.name.toLowerCase()]
																			? open && <ExpandLess />
																			: open && <ExpandMore />}
																	</ListItemButton>
																) : (
																	<NavLink
																		to={item.path}
																		className={getClassName(
																			item.activePaths,
																			item.disabled
																		)}
																	>
																		<ListItemButton
																			sx={{
																				justifyContent: open
																					? 'initial'
																					: 'center',
																				px: 2.5
																			}}
																		>
																			<ListItemIcon
																				sx={{
																					minWidth: 0,
																					mr: open ? 3 : 'auto',
																					justifyContent: 'center'
																				}}
																			>
																				{item.icon()}
																			</ListItemIcon>
																			<ListItemText
																				primary={item.name}
																				sx={{ opacity: open ? 1 : 0 }}
																				primaryTypographyProps={{
																					fontSize: 14,
																					textDecoration: 'none'
																				}}
																			/>
																		</ListItemButton>
																	</NavLink>
																)}
															</ListItem>
														</Tooltip>

														{item.children &&
															item.children.map(
																(subitem) =>
																	subitem.scope.includes(origin) && (
																		<Collapse
																			key={subitem.path}
																			in={showNested[item?.name.toLowerCase()]}
																			timeout="auto"
																			unmountOnExit
																		>
																			<List component="div" disablePadding>
																				<Tooltip
																					title={!open ? subitem.name : ''}
																					placement="right"
																				>
																					<NavLink
																						to={subitem.path}
																						className={getClassName(
																							subitem.activePaths,
																							subitem.disabled
																						)}
																					>
																						<ListItemButton
																							sx={{ pl: open ? 8.5 : 2.5 }}
																						>
																							{!open && (
																								<ListItemIcon>
																									{subitem.icon()}
																								</ListItemIcon>
																							)}
																							{open && (
																								<ListItemText
																									primary={subitem.name}
																									primaryTypographyProps={{
																										fontSize: 14,
																										textDecoration: 'none'
																									}}
																								/>
																							)}
																						</ListItemButton>
																					</NavLink>
																				</Tooltip>
																			</List>
																		</Collapse>
																	)
															)}
													</Fragment>
												)
										)}
								</Box>
							)
					)}
				</List>

				<Box
					sx={{
						display: 'flex',
						flexGrow: '1',
						alignItems: 'flex-end',
						width: '100%'
					}}
				>
					<List sx={{ width: '100%' }}>
						<Divider sx={{ marginBottom: '10px' }} />
						<ListItem
							disablePadding
							sx={{ display: 'block' }}
							onClick={startBasicTour}
						>
							<Tooltip title={!open ? 'Demo' : ''} placement="right">
								<ListItemButton
									sx={{
										justifyContent: open ? 'initial' : 'center',
										px: 2.5
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center'
										}}
									>
										<PreviewIcon />
									</ListItemIcon>
									<ListItemText
										primary="Demo"
										sx={{ opacity: open ? 1 : 0, fontSize: '14px' }}
										primaryTypographyProps={{
											fontSize: 14,
											textDecoration: 'none'
										}}
									/>
								</ListItemButton>
							</Tooltip>
						</ListItem>
						<ListItem disablePadding sx={{ display: 'block' }}>
							<NavLink
								to="/documentation"
								className={(isActive) =>
									`nav-link${!isActive ? ' unselected' : ''}`
								}
							>
								<Tooltip title={!open ? 'Help' : ''} placement="right">
									<ListItemButton
										sx={{
											justifyContent: open ? 'initial' : 'center',
											px: 2.5
										}}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : 'auto',
												justifyContent: 'center'
											}}
										>
											<SupportOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary="Help"
											sx={{ opacity: open ? 1 : 0, fontSize: '14px' }}
											primaryTypographyProps={{
												fontSize: 14,
												textDecoration: 'none'
											}}
										/>
									</ListItemButton>
								</Tooltip>
							</NavLink>
						</ListItem>
						<Tooltip title={!open ? 'Account' : ''} placement="right">
							<ListItem
								disablePadding
								sx={{ display: 'flex' }}
								onClick={handleAccountOpen}
								secondaryAction={
									open ? (
										<IconButton edge="end" aria-label="delete">
											<MoreHorizOutlinedIcon />
										</IconButton>
									) : null
								}
							>
								<ListItemButton
									sx={{
										justifyContent: open ? 'initial' : 'center',
										px: 2.5
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center'
										}}
									>
										<PersonOutlineOutlinedIcon />
									</ListItemIcon>
									<ListItemText
										primary="Account"
										sx={{ opacity: open ? 1 : 0, fontSize: '14px' }}
										primaryTypographyProps={{
											fontSize: 14,
											textDecoration: 'none'
										}}
									/>
								</ListItemButton>
							</ListItem>
						</Tooltip>
					</List>
				</Box>
			</LinkSectionWrapper>

			<Popover
				id={id}
				open={openPopover}
				anchorEl={anchorEl}
				onClose={handleAccountClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<List>
					<ListItem disablePadding>
						<ListItemButton>
							{origin === PLATFORMS.ps && (
								<ListItemText
									primary={`${user?.firstName || 'Unknown'} ${
										user?.lastName || 'User'
									}`}
									secondary={user?.email}
								/>
							)}
							{origin === PLATFORMS.hatch && (
								<ListItemText
									primary={user?.organizationName || 'Unknown Organization'}
									secondary={user?.organizationType}
								/>
							)}

							{origin === PLATFORMS.cc && (
								<ListItemText
									primary={
										user?.payload?.user_info?.first_name || 'Unknown User'
									}
									secondary={user?.payload?.user_info?.email}
								/>
							)}
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton>
							{origin === PLATFORMS.ps && (
								<Link
									rel="noreferrer"
									href="https://insights.pricespider.com/"
									data-testid="insights-link"
									target="_blank"
									underline="none"
									color="inherit"
								>
									PriceSpider Insights
								</Link>
							)}
							{origin === PLATFORMS.hatch && (
								<Link
									rel="noreferrer"
									href="https://my.gethatch.com/campaigns-report"
									data-testid="hatch-link"
									target="_blank"
									underline="none"
									color="inherit"
								>
									HATCH Dashboard
								</Link>
							)}
							{origin === PLATFORMS.cc && (
								<Link
									rel="noreferrer"
									href="https://admin.global.commerce-connector.com/welcome/home"
									data-testid="admin-link"
									target="_blank"
									underline="none"
									color="inherit"
								>
									CommerceConnector Admin
								</Link>
							)}
						</ListItemButton>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<ListItemButton onClick={logoutUser}>
							<ListItemText
								primary="Logout"
								sx={{ color: '#D32F2F', fontSize: '13px' }}
							/>
						</ListItemButton>
					</ListItem>
				</List>
			</Popover>
		</Drawer>
	);
};

export default Sidebar;
