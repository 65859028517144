import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiCampaign from 'services/apiCampaigns';
import responseHandler from 'services/responseHandler';
import { useMessageContext } from 'contexts/Message/MessageContext';

function useCloneCampaign() {
	const queryClient = useQueryClient();
	const sendMessage = useMessageContext();

	const mutate = useMutation({
		mutationFn: async ({ campaign, name }) => {
			try {
				const response = await apiCampaign.cloneCampaign(campaign.id, { name });
				sendMessage('Campaign successfully cloned.');
				return responseHandler.success(response);
			} catch (error) {
				sendMessage({ text: 'Something went wrong', type: 'error' });

				return responseHandler.error(error);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries('campaigns');
		}
	});

	return mutate;
}

export default useCloneCampaign;
