/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageHeader from 'components/PageHeader/PageHeader';
import ChartSkeleton from './Loader';

import { useAnalyticsDashboards } from 'services/apiAnalytics';
import { EVENTS, handleEvent } from 'utils/eventHandling/eventUtils';
import { IframeWrapper, Iframe } from './styles';

const environment = process.env.REACT_APP_IS_STAGE;
const isStage = environment === 'true';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

const DASHBOARDS = {
	stage: {
		campaign_overview: 1718,
		shoppable_performance: 1721,
		product_performance: 1726,
		retailers_performance: 1725,
		sales_details: 1722
	},
	production: {
		campaign_overview: 1356,
		shoppable_performance: 1355,
		product_performance: 1359,
		retailers_performance: 1357,
		sales_details: 1358
	}
};

const LOOKER_IDS = {
	0: isStage
		? DASHBOARDS.stage.campaign_overview
		: DASHBOARDS.production.campaign_overview,
	1: isStage
		? DASHBOARDS.stage.shoppable_performance
		: DASHBOARDS.production.shoppable_performance,
	2: isStage
		? DASHBOARDS.stage.retailers_performance
		: DASHBOARDS.production.retailers_performance,
	3: isStage
		? DASHBOARDS.stage.product_performance
		: DASHBOARDS.production.product_performance,
	4: isStage
		? DASHBOARDS.stage.sales_details
		: DASHBOARDS.production.sales_details
};

const TAB_LABELS = {
	0: 'Overview',
	1: 'Shoppable Performance',
	2: 'Retailers Performance',
	3: 'Product Performance',
	4: 'Sales Details'
};

const Analytics = () => {
	const [tabValue, setTabValue] = useState(0);
	const [lookerUrl, setLookerUrl] = useState(null);
	const [isUrlReady, setIsUrlReady] = useState(false);

	const dashboardId = tabValue ? LOOKER_IDS[tabValue] : LOOKER_IDS[0];

	const { isLoading, data } = useAnalyticsDashboards(dashboardId);

	useEffect(() => {
		setIsUrlReady(false);

		if (!isLoading && data?.data?.response) {
			setLookerUrl(data?.data?.response);
		}
	}, [data, isLoading]);

	useEffect(() => {
		window.addEventListener(EVENTS.message, (event) =>
			handleEvent(event, EVENTS.complete, LOOKER_IDS[tabValue], setIsUrlReady)
		);
	}, [tabValue]);

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsUrlReady(true);
		}, 8000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [tabValue]);

	const renderIframe = () => (
		<IframeWrapper>
			<Iframe
				title={TAB_LABELS[tabValue]}
				src={lookerUrl}
				width="100%"
				height="100%"
				id={`looker-${LOOKER_IDS[tabValue]}`}
			/>
		</IframeWrapper>
	);

	return (
		<Box>
			<PageHeader title="Dashboard" />

			<Box
				sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '25px' }}
			>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="basic tabs example"
				>
					<Tab label={TAB_LABELS[0]} />
					<Tab label={TAB_LABELS[1]} />
					<Tab label={TAB_LABELS[2]} />
					<Tab label={TAB_LABELS[3]} />
					<Tab label={TAB_LABELS[4]} />
				</Tabs>
			</Box>

			{(isLoading || !isUrlReady) && <ChartSkeleton />}

			{lookerUrl && (
				<Paper>
					<TabPanel value={tabValue} index={0}>
						{renderIframe()}
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						{renderIframe()}
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						{renderIframe()}
					</TabPanel>
					<TabPanel value={tabValue} index={3}>
						{renderIframe()}
					</TabPanel>
					<TabPanel value={tabValue} index={4}>
						{renderIframe()}
					</TabPanel>
				</Paper>
			)}
		</Box>
	);
};

export default Analytics;
