import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 'none'
				}
			}
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'cancel' },
					style: {
						color: '#1c1c1c',
						fontWeight: '500',
						'&:hover': { backgroundColor: '#fff' }
					}
				},
				{
					props: { variant: 'reset' },
					style: {
						color: '#D32F2F',
						fontWeight: '500',
						'&:hover': { backgroundColor: '#fff' }
					}
				}
			]
		},
		MuiListItemText: {
			variants: [
				{
					props: { variant: 'two-line-ellipsis' },
					style: {
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical'
					}
				}
			]
		}
	},
	typography: {
		fontFamily: ['Roboto', '-apple-system', '"Helvetica Neue"'].join(',')
	},
	palette: {
		primary: {
			main: '#0047FF',
			success: '#2E7D32',
			error: 'red'
		},
		secondary: {
			main: '#EFF8FF'
		},
		light: {
			action: {
				selected: 'rgba(0, 0, 0, 0.08)'
			},
			text: {
				primary: 'rgba(0, 0, 0, 0.87)',
				secondary: 'rgba(0, 0, 0, 0.6)'
			}
		}
	}
});

export default theme;
