import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery, useUpdateQueryString } from 'contexts/hooks/useQuery';
import { useMergedUrlAndQueryParams } from 'hooks';

import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import EmptyResults from 'components/EmptyResults/EmptyResults';
import {
	formatSortValue,
	getIdAndType,
	constructPaginationChangeUrl
} from 'modules/AuditTrail/utils/auditTrailUtils';

import { getDataGridHeight } from 'utils';

const CustomDataGrid = ({
	isAudit,
	rows,
	rowCount,
	getColumns,
	loading,
	baseUrl,
	noRowsProps,
	onCellClick,
	...props
}) => {
	const query = useQuery();
	const { urlParams } = useMergedUrlAndQueryParams();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const { updateQuery } = useUpdateQueryString();
	const { type } = getIdAndType(params);

	const currentPage = Number(urlParams?.offset || 1);
	const itemsPerPage = Number(urlParams?.limit || 10);

	const getDirection = useCallback(
		(activeSort, sortBy) => {
			const direction = query.get('sort_type');

			if (activeSort === sortBy) {
				return direction === 'asc' ? 'desc' : 'asc';
			}

			return 'desc';
		},
		[query]
	);

	const handleSort = useCallback(
		(sortModel) => {
			const regularSortBy = sortModel[0] ? sortModel[0].field : 'created_at';
			const auditSortBy = formatSortValue(sortModel[0]?.field);

			const activeSort = query.get('sort');

			const sortBy = isAudit ? auditSortBy : regularSortBy;

			const dir = getDirection(activeSort, sortBy);

			updateQuery([
				{ param: 'sort', value: sortBy },
				{ param: 'sort_type', value: dir }
			]);
		},
		[getDirection, isAudit, query, updateQuery]
	);

	const handlePageChange = useCallback(
		(offset) => {
			let url;

			if (isAudit) {
				url = constructPaginationChangeUrl(
					type,
					params,
					location,
					offset,
					params.limit
				);
			} else {
				url = `${baseUrl}/${offset}/${itemsPerPage}?${query.toString()}`;
			}

			navigate(url);
		},
		[isAudit, navigate, type, params, location, baseUrl, itemsPerPage, query]
	);

	const handlePerPageChange = useCallback(
		(limit) => {
			let url;

			if (isAudit) {
				url = constructPaginationChangeUrl(type, params, location, 0, limit);
			} else {
				url = `${baseUrl}/${currentPage}/${limit}?${query.toString()}`;
			}

			navigate(url);
		},
		[isAudit, navigate, type, params, location, baseUrl, currentPage, query]
	);

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%'
			}}
		>
			<Paper sx={{ display: 'flex', width: '100%' }}>
				<div style={{ height: getDataGridHeight(rowCount), width: '100%' }}>
					<DataGrid
						{...props}
						disableSelectionOnClick
						disableColumnMenu
						rows={rows}
						rowCount={rowCount}
						columns={getColumns()}
						paginationMode="server"
						sortingMode="server"
						onSortModelChange={handleSort}
						page={currentPage}
						pageSize={itemsPerPage}
						onPageChange={(newPage) => handlePageChange(newPage)}
						rowsPerPageOptions={isAudit ? [10, 20, 50, 100] : [10, 25, 50, 100]}
						onPageSizeChange={(newPageSize) => handlePerPageChange(newPageSize)}
						onCellClick={onCellClick}
						loading={loading}
						columnBuffer={2}
						columnThreshold={2}
						components={{
							NoRowsOverlay: EmptyResults
						}}
						componentsProps={{
							noRowsOverlay: noRowsProps
						}}
						initialState={
							props.initialState ?? {
								sorting: {
									sortModel: [{ field: 'created_at', sort: 'desc' }]
								}
							}
						}
						sx={{
							'& .MuiDataGrid-iconSeparator': {
								display: 'none'
							},
							'& .MuiDataGrid-footerContainer': {
								borderTop: 'none'
							}
						}}
					/>
				</div>
			</Paper>
		</Box>
	);
};

CustomDataGrid.propTypes = {
	isAudit: PropTypes.bool,
	rows: PropTypes.array.isRequired,
	rowCount: PropTypes.number.isRequired,
	getColumns: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	baseUrl: PropTypes.string.isRequired,
	noRowsProps: PropTypes.object.isRequired,
	onCellClick: PropTypes.func,
	initialState: PropTypes.object
};

CustomDataGrid.defaultProps = {
	isAudit: false,
	onCellClick: () => {},
	initialState: null
};

export default CustomDataGrid;
