const HIDDEN_LABELS = {
	cc: [
		'in_stock_label',
		'out_stock_label',
		'product_quantity_label_singular',
		'product_quantity_label_plural',
		'terms_of_service_label'
	],
	hatch: [
		'availability_delivery_day',
		'availability_delivery_days',
		'b2b_filter_label',
		'b2c_filter_label',
		'cta_apply_filter',
		'cta_clear_filter',
		'fast_delivery',
		'product_quantity_label_singular',
		'product_quantity_label_plural',
		'token_expired',
		'terms_of_service_label'
	],
	ps: [
		'availability_delivery_day',
		'availability_delivery_days',
		'b2b_filter_label',
		'b2c_filter_label',
		'cta_apply_filter',
		'cta_clear_filter',
		'cta_more_results',
		'fast_delivery',
		'filter_label',
		'terms_of_service_label'
	]
};

const copyModalText = {
	SCRIPT_MODAL_TITLE: 'Landing Page Script',
	SCRIPT_MODAL_TYPE: 'script',
	URL_MODAL_TITLE: 'Landing Page URL',
	URL_MODAL_TYPE: 'url'
};

const ERROR_MESSAGES = {
	400: 'Bad Request',
	401: 'Unauthorized',
	403: 'Access Forbidden',
	404: 'Not Found',
	500: 'Internal Server Error',
	502: 'Bad Gateway',
	503: 'Service unavailable'
};

const ENDPOINT_MAPPING = {
	'client-tokens': 'Insights Client tokens service',
	'/v1/my/user': 'User Profile Data Service',
	'/v1/client-country-list': 'Supported Countries Service'
};

const FIELDS = {
	LOGO: 'brand_logo',
	PRODUCT_IMAGE: 'product_image',
	HEADER_MOBILE_IMAGE: 'GENERAL.header.header_mobile_image',
	HEADER_IMAGE: 'GENERAL.header.header_image',
	FAVICON_IMAGE: 'favicon_image'
};

export {
	HIDDEN_LABELS,
	copyModalText,
	ERROR_MESSAGES,
	ENDPOINT_MAPPING,
	FIELDS
};
