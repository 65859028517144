import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';

const FilterChip = ({ Icon, handleDeleteChip, item }) => (
	<Chip
		icon={<Icon />}
		label={item.label}
		style={{ maxWidth: 300 }}
		onDelete={() => handleDeleteChip(item)}
	/>
);

FilterChip.propTypes = {
	Icon: PropTypes.any.isRequired,
	handleDeleteChip: PropTypes.func.isRequired,
	item: PropTypes.object.isRequired
};

export default FilterChip;
