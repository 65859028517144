import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useUpdateCampaignStatus from 'modules/Campaign/hooks/useUpdateCampaignStatus';
import useCloneCampaign from 'modules/Campaign/hooks/useCloneCampaign';
import { useModalState, MODALS } from 'contexts/ModalProvider';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import EntityStatusModal from 'components/EntityStatusModal/EntityStatusModal';
import CloneModal from 'components/CloneModal/CloneModal';
import CustomDataGrid from 'components/CustomDataGrid/CustomDataGrid';
import LandingPageTypeModal from 'modules/LandingPages/components/Modals/LandingPageTypeModal';

import { getColumns } from './columns';
import { PLATFORMS } from 'constants';

const originSelector = (state) => state?.auth?.origin;

const CampaignMUITable = ({
	openCreateCampaignModal,
	campaigns,
	isLoading,
	count
}) => {
	const origin = useSelector(originSelector);
	const navigate = useNavigate();

	const updateCampaignStatusMutation = useUpdateCampaignStatus();
	const { mutateAsync: cloneCampaignMutation, isLoading: isCloning } =
		useCloneCampaign();

	const {
		isOpen: isCloneOpen,
		openModal: openCloneModal,
		closeModal: closeCloneModal
	} = useModalState(MODALS.SHARED.clone);
	const {
		isOpen: isStatusOpen,
		openModal: openStatusModal,
		closeModal: closeStatusModal
	} = useModalState(MODALS.SHARED.entityStatus);
	const {
		isOpen: isTypeModalOpen,
		openModal: openTypeModal,
		closeModal: closeTypeModal
	} = useModalState(`${MODALS.LANDING_PAGE.type}-campaign`);

	const [activeCampaign, setActiveCampaign] = useState({});
	const [cloneName, setCloneName] = useState('');

	const campaignsData = useMemo(() => campaigns, [campaigns]);

	const handleOpenProducts = useCallback(
		(cell) => {
			if (cell.field !== 'actions') {
				const url = `/campaign/${cell.id}/products/0/10?sort=modified_at&sort_type=desc`;
				navigate(url);
			}
		},
		[navigate]
	);

	const cloneSelectedCampaign = (name) => {
		if (name) {
			cloneCampaignMutation({ campaign: activeCampaign, name }).then(() => {
				closeCloneModal();
				setCloneName('');
				setActiveCampaign(null);
			});
		}
	};

	const addLandingPage = useCallback(
		(campaign) => {
			setActiveCampaign(campaign);

			if (origin === PLATFORMS.ps) {
				openTypeModal();
			} else {
				window.open(`/campaign/${campaign.id}/product?type=shoppable-product`);
			}
		},
		[origin, openTypeModal]
	);

	const handleCloneModal = useCallback(
		(campaign) => {
			openCloneModal();
			setActiveCampaign(campaign);
		},
		[openCloneModal]
	);

	const openAuditTrail = (campaign) => {
		navigate(
			`/audit/campaign/${campaign.id}/0/10?sort=action_time&sort_type=desc`
		);
	};

	const handleCampaignStatus = (campaign) => {
		setActiveCampaign(campaign);
		openStatusModal();
	};

	const handleStatusChange = () => {
		setActiveCampaign((prevCampaign) => ({
			...prevCampaign,
			active: !prevCampaign.active
		}));
		closeStatusModal();

		updateCampaignStatusMutation.mutate({
			campaign: { ...activeCampaign, active: !activeCampaign.active },
			origin
		});
	};

	const handleTypeModal = (show) => {
		if (show) {
			openTypeModal();
		} else {
			closeTypeModal();
		}
	};

	return (
		<Box>
			{isStatusOpen && (
				<EntityStatusModal
					isOpen={isStatusOpen}
					closeModal={closeStatusModal}
					handleStatusChange={handleStatusChange}
					row={activeCampaign}
					entityName="campaign"
				/>
			)}

			{isCloneOpen && (
				<CloneModal
					isOpen={isCloneOpen}
					onSubmit={cloneSelectedCampaign}
					onClose={closeCloneModal}
					isLoading={isCloning}
					setCloneName={setCloneName}
					cloneName={cloneName}
					text="What should be the name of cloned campaign?"
					name="Duplicate"
				/>
			)}

			<Paper sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<Box padding="20px 16px">
					<Typography>List of Campaigns</Typography>
				</Box>

				<CustomDataGrid
					rows={campaignsData}
					rowCount={count}
					getColumns={() =>
						getColumns(
							origin,
							openCreateCampaignModal,
							addLandingPage,
							handleCloneModal,
							openAuditTrail,
							handleCampaignStatus
						)
					}
					loading={isLoading}
					baseUrl="/campaigns"
					noRowsProps={{
						title: 'No Campaigns Available!',
						text: 'We couldn’t find any campaigns assigned to this brand',
						children: (
							<Button
								variant="outlined"
								onClick={openCreateCampaignModal}
								startIcon={<AddIcon />}
								style={{ marginRight: '10px' }}
							>
								New Campaign
							</Button>
						)
					}}
					onCellClick={handleOpenProducts}
				/>
			</Paper>

			{activeCampaign?.id && openTypeModal && (
				<LandingPageTypeModal
					campaignId={activeCampaign.id}
					isOpen={isTypeModalOpen}
					handleModal={handleTypeModal}
					brandId={activeCampaign.manufacturer_id}
				/>
			)}
		</Box>
	);
};

export default CampaignMUITable;

CampaignMUITable.propTypes = {
	openCreateCampaignModal: PropTypes.func.isRequired,
	campaigns: PropTypes.array,
	isLoading: PropTypes.bool,
	count: PropTypes.number
};

CampaignMUITable.defaultProps = {
	campaigns: [],
	count: 0,
	isLoading: false
};
