import { PLATFORMS } from 'constants';
import { format } from 'date-fns';
import { isEmpty } from 'utils';

const getEndDateValue = (endDateValue) => {
	if (endDateValue) {
		return endDateValue.split(' ')[0];
	}
	return null;
};

const formatCampaignCountries = (country, origin) => {
	if (!country.value) {
		return {
			id: country.id,
			name: country.en ? country.en : country.name,
			locale: country.country ? country.country : country.locale,
			language: country.language
		};
	}
	const { value } = country;
	const isPs = origin === PLATFORMS.ps;
	if (isPs)
		return {
			id: 0,
			name: value.name,
			locale: value.abbreviation,
			language: value.abbreviation
		};
	const isCC = origin === PLATFORMS.cc;
	if (isCC)
		return {
			id: value.id,
			name: value.en,
			locale: value.country,
			language: value.language
		};
	const { id, name, locale, language } = value;
	return {
		id,
		name,
		locale,
		language
	};
};

const formatCampaignPayload = (values, campaign, origin) => {
	const payload = {
		name: values.name,
		country: formatCampaignCountries(values.country, origin),
		active: values.active,
		end_date: getEndDateValue(values.end_date)
	};

	if (origin === 'cc' || origin === 'ps') {
		payload.token = values.token.value ? values.token.value : values.token;
	}

	if (origin === 'ps') {
		payload.manufacturer_id = campaign.manufacturer_id;
		payload.manufacturer_name = campaign.manufacturer_name;
	}

	if (origin === 'hatch') {
		if (isEmpty(campaign)) {
			const brandData = values.manufacturer_id;

			payload.manufacturer_id = brandData.value
				? brandData.value.id
				: brandData.id;

			payload.manufacturer_name = brandData.value
				? brandData.value.name
				: brandData.name;

			payload.affiliate_id = brandData.value
				? brandData.value.affiliateId.toString()
				: brandData.affiliateId.toString();
		} else {
			payload.manufacturer_id = campaign.manufacturer_id;
			payload.manufacturer_name = campaign.manufacturer_name;
			payload.affiliate_id = campaign.affiliate_id;
		}
	}
	return payload;
};

const getEndDateInfo = (endDate) => {
	if (endDate) {
		const end = format(new Date(endDate), "MMM dd, ''yy");

		const today = new Date();
		const expiringDate = new Date(endDate);
		const oneDay = 1000 * 60 * 60 * 24;

		const utcToday = Date.UTC(
			today.getFullYear(),
			today.getMonth(),
			today.getDate()
		);
		const utcEndDate = Date.UTC(
			expiringDate.getFullYear(),
			expiringDate.getMonth(),
			expiringDate.getDate()
		);

		const diffDays = Math.floor((utcEndDate - utcToday) / oneDay);

		return {
			daysLeft: diffDays,
			end
		};
	}
	return {
		end: 'Unlimited',
		daysLeft: null
	};
};

const tooltipSettings = {
	expired: {
		id: 'duration-expired',
		color: '#ff2e00',
		text: 'Expired'
	},
	expiring_soon: {
		id: 'duration-expirinig-soon',
		color: '#fec601',
		text: 'Expiring soon'
	}
};

export {
	getEndDateValue,
	formatCampaignCountries,
	formatCampaignPayload,
	getEndDateInfo,
	tooltipSettings
};
