const isNumberOrConvertible = (value) => {
	// Check if the value is a number
	if (typeof value === 'number' && !Number.isNaN(value)) {
		return true;
	}

	// Check if the value is a string that can be converted into a number
	if (
		typeof value === 'string' &&
		value.trim() !== '' &&
		!Number.isNaN(Number(value))
	) {
		return true;
	}

	return false;
};

export { isNumberOrConvertible };
