import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import { PlatformLogoIcon } from 'components/Icon';
import LoginForm from 'components/LoginForm/LoginForm';

const useAppSelector = (state) => (state && state.app ? state.app : null);
const originSelector = (state) => (state ? state.auth.origin : null);
const forbiddenErrorSelector = (state) =>
	state ? state.auth.forbiddenError : null;

const LoginScreen = ({
	login,
	error,
	setCCToken,
	token,
	user,
	restrictedAccess,
	setOrigin,
	mfa,
	requestMFA,
	loading
}) => {
	const navigate = useNavigate();

	const app = useSelector(useAppSelector);
	const origin = useSelector(originSelector);
	const forbiddenError = useSelector(forbiddenErrorSelector);

	useEffect(() => {
		if (token && user) {
			navigate('/campaigns/0/10?sort=created_at&sort_type=desc');
		}
	}, [token, navigate, user, origin]);

	return app.appLoading ? (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				background: '#fff'
			}}
		>
			<img
				style={{
					position: 'fixed',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)'
				}}
				src="/splash.gif"
				alt="splash intro"
			/>
		</div>
	) : (
		<div className="login">
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					paddingTop: '100px'
				}}
			>
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						marginBottom: '2rem',
						marginTop: '2rem'
					}}
				>
					<PlatformLogoIcon color="#0047FF" size="36" />
					<Typography
						style={{
							color: '#1b0c75',
							marginLeft: '1rem'
						}}
					>
						WTB Shoppable Solutions
					</Typography>
				</div>

				<LoginForm
					login={login}
					setOrigin={setOrigin}
					user={user}
					error={error}
					token={token}
					setCCToken={setCCToken}
					restrictedAccess={restrictedAccess}
					mfa={mfa}
					requestMFA={requestMFA}
					loading={loading}
					forbiddenError={forbiddenError}
				/>
			</div>
		</div>
	);
};

LoginScreen.propTypes = {
	login: PropTypes.func.isRequired,
	setOrigin: PropTypes.func.isRequired,
	error: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.oneOf([null])
	]),
	setCCToken: PropTypes.func.isRequired,
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
	token: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
	restrictedAccess: PropTypes.bool,
	mfa: PropTypes.bool.isRequired,
	requestMFA: PropTypes.func.isRequired,
	loading: PropTypes.bool
};

LoginScreen.defaultProps = {
	token: null,
	error: null,
	user: null,
	restrictedAccess: false,
	loading: false
};

export default LoginScreen;
