import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LANDING_PAGE_TYPE } from 'constants';

import CustomModal from 'components/CustomModal/CustomModal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { RadioCard } from 'components/RadioCard';

const LandingPageTypeModal = ({ isOpen, campaignId, brandId, handleModal }) => {
	const [type, setType] = useState(LANDING_PAGE_TYPE.SKU);

	const handleType = (event) => {
		setType(event.target.value);
	};

	const handleClose = () => {
		setType(LANDING_PAGE_TYPE.SKU);
		handleModal(false);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			closeModal={handleClose}
			title="New Landing Page"
		>
			<Box>
				<FormControl>
					<FormLabel
						id="landing-page-type"
						sx={{ fontSize: '14px', mb: 1.4 }}
						focused={false}
					>
						Page Type
					</FormLabel>
					<RadioGroup
						aria-labelledby="landing-page-type"
						name="page-type"
						value={type}
						onChange={handleType}
					>
						<Box display="flex" gap={2} maxWidth={600}>
							<RadioCard
								value={LANDING_PAGE_TYPE.SKU}
								label="Product Based"
								description="Build a landing page that is tailored to a SKU from your product list and benefit from our automatic retailer matches."
							/>
							<RadioCard
								value={LANDING_PAGE_TYPE.GENERIC}
								label="Brand Based"
								description="Build a landing page that allows you to add your desired URLs by retailer."
							/>
						</Box>
					</RadioGroup>
				</FormControl>
			</Box>

			<Stack spacing={2} direction="row" justifyContent="flex-end" mt={3}>
				<Button variant="cancel" onClick={handleClose}>
					CANCEL
				</Button>
				<Button
					variant="contained"
					data-testid="CreateNewLandingPage"
					href={`/campaign/${campaignId}/product?type=${type}&manufacturers=${brandId}`}
					target="_blank"
					onClick={handleClose}
				>
					Create
				</Button>
			</Stack>
		</CustomModal>
	);
};

LandingPageTypeModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	campaignId: PropTypes.number.isRequired,
	brandId: PropTypes.string.isRequired,
	handleModal: PropTypes.func.isRequired
};

export default LandingPageTypeModal;
