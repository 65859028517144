/* eslint-disable no-console */

const EVENTS = {
	message: 'message',
	complete: 'dashboard:run:complete'
};

const handleEventData = (parsedData, completeEvent, setIsUrlReady) => {
	if (parsedData.type === completeEvent) {
		setIsUrlReady(true);
	}
};

const handleEvent = (event, completeEvent, lookerId, setIsUrlReady) => {
	const lookerIframe = document.getElementById(`looker-${lookerId}`);

	if (lookerIframe) {
		try {
			if (event.source === lookerIframe.contentWindow) {
				const eventData = event.data;

				if (typeof eventData === 'string') {
					handleEventData(JSON.parse(eventData), completeEvent, setIsUrlReady);
				} else {
					console.error('Received event data is not a string:', eventData);
				}
			}
		} catch (error) {
			console.error('Error parsing event data:', error);
		}
	}
};

export { EVENTS, handleEventData, handleEvent };
