/* eslint-disable no-console */
import { PLATFORMS, STATUS } from 'constants';
import axios from 'axios';
import { isBefore, fromUnixTime, sub } from 'date-fns';

import { actionCreators as authActionCreators } from 'redux/ducks/authDuck';

import { decodeJWT } from 'services/tokenService';
import hatchApi from 'services/auth/hatch';
import localStorage from 'services/localStorage';

import psApi from './auth/ps';

const baseCCServiceUrl = process.env.REACT_APP_CC_BASE_SERVICE_URL;
const isStage = process.env.REACT_APP_IS_STAGE;

const api = axios.create({
	baseURL: baseCCServiceUrl,
	timeout: 40000, // long timeout because of the translation API service
	headers: { 'Content-Type': 'application/json' }
});

const isTokenExpired = (token) => {
	try {
		const decoded = decodeJWT(token);
		const exp = decoded.payload.exp || decoded.exp;
		const tokenExpirationDate = fromUnixTime(exp);
		const bufferInMinutes = 10;
		const tokenExpirationDateWithBuffer = sub(tokenExpirationDate, {
			minutes: bufferInMinutes
		});

		const currentDate = new Date();

		return exp && isBefore(tokenExpirationDateWithBuffer, currentDate);
	} catch (error) {
		return false;
	}
};

const checkHatchRefreshToken = async (request, auth, store, history) => {
	if (isTokenExpired(auth.token)) {
		try {
			const { data } = await hatchApi.refreshToken({
				refreshToken: auth.refreshToken
			});
			localStorage.write({
				token: data.access_token,
				refresh: data.refresh_token,
				platform: PLATFORMS.hatch
			});

			store.dispatch(
				authActionCreators.setHatchTokens({ ...data, origin: PLATFORMS.hatch })
			);

			request.headers.common.authorization = `Bearer ${data.access_token}`;
		} catch (err) {
			store.dispatch(authActionCreators.logout(PLATFORMS[origin], history));
			return Promise.reject();
		}
	}

	return true;
};

const checkPSRefreshToken = async (request, auth, store, history) => {
	if (isTokenExpired(auth.token)) {
		try {
			const data = await psApi.getToken();
			const { jwt } = data?.data;
			localStorage.write({ token: jwt, platform: PLATFORMS.ps });
			store.dispatch(authActionCreators.setPSToken(jwt));
			request.headers.common.authorization = `Bearer ${jwt}`;
		} catch (error) {
			// Handle token refresh failure (e.g., redirect to login)
			history.push('/login');
		}
	}
};

const checkCCRefreshToken = async (request, store, history) => {
	const savedExpireTime = localStorage.read('expire');

	const expireDate = new Date(Number(savedExpireTime)); // convert string to number and create a Date object
	const currentDate = new Date();

	if (currentDate > expireDate) {
		currentDate.setMinutes(currentDate.getMinutes() + 5);

		localStorage.write({
			expire: currentDate.getTime()
		});
		// -- refresh token in the background
		try {
			store.dispatch(authActionCreators.refreshCCToken());
		} catch (error) {
			console.info('error', error);
			history.push('/login');
		}
	}

	return request;
};

const handleError = async (store, error, history) => {
	const status = error.response ? error.response.status : 'unknown';
	const { auth } = store.getState();
	const { origin } = auth;

	const statusActionsMap = {
		[STATUS.MFA_REQUIRED]: () =>
			store.dispatch(authActionCreators.requestMFA(true)),
		[STATUS.NOT_AUTHORIZED]: () => {
			const unauthorized = true;
			store.dispatch(
				authActionCreators.logout(PLATFORMS[origin], history, unauthorized)
			);
		},
		[STATUS.FORBIDDEN]: () => {
			store.dispatch(authActionCreators.logout(PLATFORMS[origin], history));
			store.dispatch(
				authActionCreators.setForbiddenError(
					error?.response?.data?.message || 'Forbidden access'
				)
			);
		}
	};

	if (statusActionsMap[status]) {
		statusActionsMap[status]();
	}

	if (error.code === 'ERR_CANCELED') {
		throw new axios.Cancel('Operation canceled by the user.');
	}

	return Promise.reject(error);
};

const requestHandler = async (request, store, history) => {
	const { auth } = store.getState();
	const originIdHatch = isStage === 'true' ? 'hatch-stage' : 'hatch-prod';

	request.headers.common = {
		authorization: `Bearer ${auth.token}`,
		'X-Origin-Id': auth.origin === PLATFORMS.hatch ? originIdHatch : auth.origin
	};

	if (auth.origin === PLATFORMS.hatch) {
		await checkHatchRefreshToken(auth, store, history);
	}

	if (auth.origin === PLATFORMS.ps) {
		await checkPSRefreshToken(request, auth, store, history);
	}

	if (auth.origin === PLATFORMS.cc) {
		await checkCCRefreshToken(request, store, history);
	}

	return request;
};

const interceptor = {
	setupInterceptors: (store, history) => {
		api.interceptors.request.use(
			(request) => requestHandler(request, store, history),
			(error) => Promise.reject(error)
		);

		api.interceptors.response.use(
			(response) => {
				if ([401, 400].includes(response.status)) {
					return Promise.reject(response);
				}
				return response;
			},
			(error) => handleError(store, error, history)
		);
	}
};

export default {
	interceptor,
	api
};
