import React from 'react';
import { SVGIcon, IconPropTypes, defaultIconColor } from '../SVGIcon';

const Filter = ({ color = defaultIconColor }) => (
	<SVGIcon
		width="20"
		height="20"
		viewBox="0 0 24 24"
		title="Filter"
		uid="cc-icon-filter"
	>
		<path
			fill={color}
			d="M2.969,13h10.865c0.415,1.1,1.384,1.868,2.63,1.868c1.245,0,2.214-0.769,2.63-1.868h1.938C21.584,13,22,12.554,22,12 c-0.069-0.554-0.484-1-1.038-1h-1.938c-0.415-1.1-1.384-1.869-2.63-1.869S14.18,9.9,13.765,11H2.9C2.415,11,2,11.446,2,12 S2.415,13,2.969,13z"
		/>
		<path
			fill={color}
			d="M20.962,18H9.958c-0.415-1-1.384-1.868-2.63-1.868S5.114,17,4.699,18h-1.73C2.415,18,2,18.482,2,18.967 C2,19.52,2.415,20,2.969,20h1.73c0.346,1,1.384,1.868,2.63,1.868S9.543,21,9.958,20h11.003c0.554,0,0.969-0.48,0.969-1.033 C21.931,18.482,21.516,18,20.962,18z"
		/>
		<path
			fill={color}
			d="M2.9,6h6.367c0.415,1,1.384,1.868,2.629,1.868S14.111,7,14.525,6h6.437c0.554,0,0.969-0.446,0.969-0.999 C21.931,4.447,21.516,4,20.962,4h-6.366c-0.416-1-1.385-1.868-2.63-1.868S9.751,3,9.335,4H2.969C2.415,4,2,4.447,2,5.001 C2,5.554,2.415,6,2.9,6z"
		/>
	</SVGIcon>
);

Filter.propTypes = IconPropTypes;

export default Filter;
