import React from 'react';

import {
	BarChartOutlined,
	TextSnippetOutlined,
	StoreMallDirectoryOutlined,
	SettingsOutlined,
	RemoveOutlined,
	CampaignOutlined,
	FolderOutlined,
	LinkOutlined,
	ColorLensOutlined
} from '@mui/icons-material';

export const sidebarItems = [
	{
		name: 'Analytics',
		scope: ['ps'],
		children: [
			{
				name: 'Dashboards',
				icon: () => <BarChartOutlined />,
				path: '/analytics',
				scope: ['ps']
			}
		]
	},
	{
		name: 'Landing Page',
		scope: ['ps', 'cc', 'hatch'],
		children: [
			{
				name: 'Campaigns',
				icon: () => <CampaignOutlined />,
				path: '/campaigns/0/10?sort=created_at&sort_type=desc',
				activePaths: ['campaign', 'campaigns', 'audit/campaign'],
				scope: ['ps', 'cc', 'hatch']
				// disabled: true
			},
			{
				name: 'Assets',
				icon: () => <FolderOutlined />,
				path: '/assets',
				activePaths: ['assets'],
				scope: ['ps', 'cc', 'hatch'],
				children: [
					{
						name: 'Media',
						icon: () => <RemoveOutlined />,
						path: '/assets/media-hub/0/10',
						activePaths: ['media-hub'],
						scope: ['ps', 'cc', 'hatch']
					},
					{
						name: 'Custom Fonts',
						icon: () => <RemoveOutlined />,
						path: '/assets/custom-fonts/0/10?sort=created_at&sort_type=desc',
						activePaths: ['custom-fonts'],
						scope: ['ps', 'cc', 'hatch']
					}
				]
			},
			{
				name: 'Configurations',
				icon: () => <SettingsOutlined />,
				path: '/configurations',
				activePaths: ['configurations'],
				scope: ['ps', 'cc', 'hatch'],
				children: [
					{
						name: 'Retailers',
						icon: () => <StoreMallDirectoryOutlined />,
						path: '/configurations/retailers/0/10?sort=created_at&sort_type=desc',
						activePaths: ['retailers'],
						scope: ['ps']
						// disabled: true
					},
					{
						name: 'Style Presets',
						icon: () => <ColorLensOutlined />,
						path: '/configurations/presets/0/10?sort=created_at&sort_type=desc',
						activePaths: ['presets'],
						scope: ['ps', 'cc', 'hatch']
						// disabled: true
					},
					{
						name: 'Third-Party Tracking',
						icon: () => <RemoveOutlined />,
						path: '/configurations/third-party-tracking/0/10?sort=created_at&sort_type=desc',
						activePaths: [
							'third-party-tracking',
							'audit/configurations/third-party-tracking'
						],
						scope: ['ps', 'cc', 'hatch']
					},
					{
						name: 'Labels',
						icon: () => <TextSnippetOutlined />,
						path: '/configurations/translations',
						activePaths: ['translations'],
						scope: ['ps', 'cc', 'hatch']
					}
				]
			}
		]
	},
	{
		name: 'Click to Retailer',
		scope: ['ps'],
		children: [
			{
				name: 'Links',
				icon: () => <LinkOutlined />,
				path: '/links/0/10',
				scope: ['ps']
				// disabled: true
			}
		]
	}
];
