/* eslint-disable camelcase */
import { PLATFORMS } from 'constants';
import React from 'react';
import { format } from 'date-fns';

import { GridActionsCellItem } from '@mui/x-data-grid';
import { CountryDataCell, StatusDataCell } from 'components/CustomDataGrid';

import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';

import { CampaginTimebox } from './CampaignColumns';

export const getColumns = (
	origin,
	handleCampaignDetails,
	addLandingPage,
	handleCloneModal,
	openAuditTrail,
	handleCampaignStatus
) => [
	{
		field: 'name',
		headerClassName: 'header-strong',
		headerName: 'Campaign Name',
		flex: 2
	},
	{
		field: 'active',
		headerClassName: 'header-strong',
		headerName: 'Status',
		flex: 1,
		renderCell: ({ row }) => <StatusDataCell status={row?.active} />
	},
	{
		field: 'manufacturer_name',
		headerClassName: 'header-strong',
		headerName: 'Brand',
		flex: 1,
		hide: origin === PLATFORMS.cc
	},
	{
		field: 'country_name',
		headerClassName: 'header-strong',
		headerName: 'Country',
		flex: 1,
		renderCell: ({ row }) => <CountryDataCell row={row} />
	},
	{
		field: 'products_count',
		headerClassName: 'header-strong',
		headerName: 'Landing Pages',
		type: 'number',
		flex: 1,
		renderCell: ({ row }) => <Box>{row.products_count}</Box>
	},
	{
		field: 'end_date',
		headerClassName: 'header-strong',
		headerName: 'End Date',
		flex: 1,
		renderCell: ({ row }) => (
			<CampaginTimebox endDate={row.end_date} id={row.id} />
		)
	},
	{
		field: 'created_at',
		headerClassName: 'header-strong',
		headerName: 'Created on',
		type: 'dateTime',
		renderCell: ({ row }) =>
			row.created_at && `${format(new Date(row.created_at), "MMM dd, ''yy")}`,
		flex: 1
	},
	{
		field: 'created_by',
		headerClassName: 'header-strong',
		headerName: 'Created by',
		flex: 1,
		cellClassName: 'createdBy'
	},
	{
		field: 'actions',
		type: 'actions',
		headerClassName: 'header-strong',
		headerName: 'Actions',
		width: 100,
		getActions: (params) => {
			if (params.row.editable) {
				return [
					<GridActionsCellItem
						dense
						icon={<EditOutlinedIcon />}
						label="Edit"
						showInMenu
						onClick={() => handleCampaignDetails(params.row)}
					/>,
					<GridActionsCellItem
						dense
						icon={<AddIcon />}
						label="Create Landing Page"
						showInMenu
						onClick={() => addLandingPage(params.row)}
					/>,
					<GridActionsCellItem
						dense
						icon={<ControlPointDuplicateIcon />}
						label="Duplicate"
						showInMenu
						onClick={() => handleCloneModal(params.row)}
					/>,
					<GridActionsCellItem
						dense
						icon={<HistoryIcon />}
						label="Audit Trail"
						showInMenu
						onClick={() => openAuditTrail(params.row)}
					/>,
					<GridActionsCellItem
						dense
						icon={params.row.active ? <PowerOffOutlinedIcon /> : <CheckIcon />}
						label={params.row.active ? 'Deactivate' : 'Activate'}
						showInMenu
						onClick={() => handleCampaignStatus(params.row)}
					/>
				];
			}

			return [
				<GridActionsCellItem
					dense
					icon={<HistoryIcon />}
					label="Audit Trail"
					showInMenu
					onClick={() => openAuditTrail(params.row)}
				/>
			];
		}
	}
];
