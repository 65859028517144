/* eslint-disable */
const countriesCC = [
	{
		id: 55,
		country: 'AR',
		language: 'es',
		local: 1,
		country_timezone: 'America/Buenos_Aires',
		is_eu: 0,
		currency_code: 'ARS',
		en: 'Argentina',
		de: 'Argentinien',
		fr: 'Argentine'
	},
	{
		id: 2,
		country: 'AU',
		language: 'en',
		local: 1,
		country_timezone: 'Australia/Canberra',
		is_eu: 0,
		currency_code: 'AUD',
		en: 'Australia',
		de: 'Australien',
		fr: 'Australie'
	},
	{
		id: 1,
		country: 'AT',
		language: 'de',
		local: 1,
		country_timezone: 'Europe/Vienna',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Austria',
		de: 'Österreich',
		fr: 'Autriche'
	},
	{
		id: 3,
		country: 'BE',
		language: 'fr',
		local: 1,
		country_timezone: 'Europe/Brussels',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Belgium',
		de: 'Belgien',
		fr: 'Belgique'
	},
	{
		id: 4,
		country: 'BE',
		language: 'nl',
		local: 1,
		country_timezone: 'Europe/Brussels',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Belgium',
		de: 'Belgien',
		fr: 'Belgique'
	},
	{
		id: 57,
		country: 'BO',
		language: 'es',
		local: 1,
		country_timezone: 'America/La_Paz',
		is_eu: 0,
		currency_code: 'BOB',
		en: 'Bolivia',
		de: 'Bolivien',
		fr: 'Bolivie'
	},
	{
		id: 45,
		country: 'BR',
		language: 'pt',
		local: 1,
		country_timezone: 'America/Sao_Paulo',
		is_eu: 0,
		currency_code: 'BRL',
		en: 'Brazil',
		de: 'Brasilien',
		fr: 'Brésil'
	},
	{
		id: 5,
		country: 'CA',
		language: 'en',
		local: 1,
		country_timezone: 'America/Toronto',
		is_eu: 0,
		currency_code: 'CAD',
		en: 'Canada',
		de: 'Kanada',
		fr: 'Canada'
	},
	{
		id: 44,
		country: 'CA',
		language: 'fr',
		local: 0,
		country_timezone: 'America/Toronto',
		is_eu: 0,
		currency_code: 'CAD',
		en: 'Canada',
		de: 'Kanada',
		fr: 'Canada'
	},
	{
		id: 47,
		country: 'CL',
		language: 'es',
		local: 1,
		country_timezone: 'America/Santiago',
		is_eu: 0,
		currency_code: 'CLP',
		en: 'Chile',
		de: 'Chile',
		fr: 'Chili'
	},
	{
		id: 46,
		country: 'CN',
		language: 'zh',
		local: 1,
		country_timezone: 'Asia/Shanghai',
		is_eu: 0,
		currency_code: 'CNY',
		en: 'China',
		de: 'China',
		fr: 'Chine'
	},
	{
		id: 59,
		country: 'CO',
		language: 'es',
		local: 1,
		country_timezone: 'America/Bogota',
		is_eu: 0,
		currency_code: 'COP',
		en: 'Colombia',
		de: 'Kolumbien',
		fr: 'Colombie'
	},
	{
		id: 115,
		country: 'CC',
		language: 'cc',
		local: 1,
		country_timezone: '',
		is_eu: 0,
		currency_code: null,
		en: 'Commerce Connector Test Country / Cocos (Keeling) Islands',
		de: 'Commerce Connector Test Country / Kokosinseln',
		fr: 'Commerce Connector Test Country / Îles Cocos (Keeling)'
	},
	{
		id: 94,
		country: 'CR',
		language: 'es',
		local: 1,
		country_timezone: 'America/Costa_Rica',
		is_eu: 0,
		currency_code: 'CRC',
		en: 'Costa Rica',
		de: 'Costa Rica',
		fr: 'Costa Rica'
	},
	{
		id: 9,
		country: 'CZ',
		language: 'cs',
		local: 1,
		country_timezone: 'Europe/Prague',
		is_eu: 1,
		currency_code: 'CZK',
		en: 'Czech Republic',
		de: 'Tschechische Republik',
		fr: 'République Tchèque'
	},
	{
		id: 11,
		country: 'DK',
		language: 'da',
		local: 1,
		country_timezone: 'Europe/Copenhagen',
		is_eu: 1,
		currency_code: 'DKK',
		en: 'Denmark',
		de: 'Dänemark',
		fr: 'Danemark'
	},
	{
		id: 95,
		country: 'DO',
		language: 'es',
		local: 1,
		country_timezone: 'America/Santo_Domingo',
		is_eu: 0,
		currency_code: 'DOP',
		en: 'Dominican Republic',
		de: 'Dominikanische Republik',
		fr: 'République Dominicaine'
	},
	{
		id: 61,
		country: 'EC',
		language: 'es',
		local: 1,
		country_timezone: 'America/Guayaquil',
		is_eu: 0,
		currency_code: 'USD',
		en: 'Ecuador',
		de: 'Ecuador',
		fr: 'Équateur'
	},
	{
		id: 111,
		country: 'SV',
		language: 'es',
		local: 1,
		country_timezone: 'America/El_Salvador',
		is_eu: 0,
		currency_code: 'USD',
		en: 'El Salvador',
		de: 'El Salvador',
		fr: 'El Salvador'
	},
	{
		id: 13,
		country: 'FI',
		language: 'fi',
		local: 1,
		country_timezone: 'Europe/Helsinki',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Finland',
		de: 'Finnland',
		fr: 'Finlande'
	},
	{
		id: 14,
		country: 'FR',
		language: 'fr',
		local: 1,
		country_timezone: 'Europe/Paris',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'France',
		de: 'Frankreich',
		fr: 'France'
	},
	{
		id: 10,
		country: 'DE',
		language: 'de',
		local: 1,
		country_timezone: 'Europe/Berlin',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Germany',
		de: 'Deutschland',
		fr: 'Allemagne'
	},
	{
		id: 109,
		country: 'GT',
		language: 'es',
		local: 1,
		country_timezone: 'America/Guatemala',
		is_eu: 0,
		currency_code: 'GTQ',
		en: 'Guatemala',
		de: 'Guatemala',
		fr: 'Guatemala'
	},
	{
		id: 108,
		country: 'HN',
		language: 'es',
		local: 1,
		country_timezone: 'America/Tegucigalpa',
		is_eu: 0,
		currency_code: 'HNL',
		en: 'Honduras',
		de: 'Honduras',
		fr: 'Honduras'
	},
	{
		id: 64,
		country: 'HK',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Hong_Kong',
		is_eu: 0,
		currency_code: 'HKD',
		en: 'Hong Kong',
		de: 'Hongkong',
		fr: 'Hong-Kong'
	},
	{
		id: 134,
		country: 'HK',
		language: 'zn',
		local: 1,
		country_timezone: 'Asia/Hong_Kong',
		is_eu: 0,
		currency_code: 'HKD',
		en: 'Hong Kong',
		de: 'Hongkong',
		fr: 'Hong-Kong'
	},
	{
		id: 48,
		country: 'IN',
		language: 'hi',
		local: 0,
		country_timezone: 'Asia/Kolkata',
		is_eu: 0,
		currency_code: 'INR',
		en: 'India',
		de: 'Indien',
		fr: 'Inde'
	},
	{
		id: 49,
		country: 'IN',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Kolkata',
		is_eu: 0,
		currency_code: 'INR',
		en: 'India',
		de: 'Indien',
		fr: 'Inde'
	},
	{
		id: 65,
		country: 'ID',
		language: 'id',
		local: 1,
		country_timezone: 'Asia/Jakarta',
		is_eu: 0,
		currency_code: 'IDR',
		en: 'Indonesia',
		de: 'Indonesien',
		fr: 'Indonésie'
	},
	{
		id: 106,
		country: 'IL',
		language: 'iw',
		local: 1,
		country_timezone: 'Asia/Jerusalem',
		is_eu: 0,
		currency_code: 'ILS',
		en: 'Israel',
		de: 'Israel',
		fr: 'Israël'
	},
	{
		id: 20,
		country: 'IT',
		language: 'it',
		local: 1,
		country_timezone: 'Europe/Rome',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Italy',
		de: 'Italien',
		fr: 'Italie'
	},
	{
		id: 105,
		country: 'JM',
		language: 'en',
		local: 1,
		country_timezone: 'America/Jamaica',
		is_eu: 0,
		currency_code: 'JMD',
		en: 'Jamaica',
		de: 'Jamaika',
		fr: 'Jamaïque'
	},
	{
		id: 40,
		country: 'JP',
		language: 'ja',
		local: 1,
		country_timezone: 'Asia/Tokyo',
		is_eu: 0,
		currency_code: 'JPY',
		en: 'Japan',
		de: 'Japan',
		fr: 'Japon'
	},
	{
		id: 32,
		country: 'LU',
		language: 'de',
		local: 0,
		country_timezone: 'Europe/Luxembourg',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Luxembourg',
		de: 'Luxemburg',
		fr: 'Luxembourg'
	},
	{
		id: 22,
		country: 'LU',
		language: 'lb',
		local: 0,
		country_timezone: 'Europe/Luxembourg',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Luxembourg',
		de: 'Luxemburg',
		fr: 'Luxembourg'
	},
	{
		id: 21,
		country: 'LU',
		language: 'fr',
		local: 1,
		country_timezone: 'Europe/Luxembourg',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Luxembourg',
		de: 'Luxemburg',
		fr: 'Luxembourg'
	},
	{
		id: 71,
		country: 'MY',
		language: 'ms',
		local: 1,
		country_timezone: 'Asia/Kuala_Lumpur',
		is_eu: 0,
		currency_code: 'MYR',
		en: 'Malaysia',
		de: 'Malaysia',
		fr: 'Malaisie'
	},
	{
		id: 125,
		country: 'MY',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Kuala_Lumpur',
		is_eu: 0,
		currency_code: 'MYR',
		en: 'Malaysia',
		de: 'Malaysia',
		fr: 'Malaisie'
	},
	{
		id: 72,
		country: 'MX',
		language: 'es',
		local: 1,
		country_timezone: 'America/Mexico_City',
		is_eu: 0,
		currency_code: 'MXN',
		en: 'Mexico',
		de: 'Mexiko',
		fr: 'Mexique'
	},
	{
		id: 23,
		country: 'NL',
		language: 'nl',
		local: 1,
		country_timezone: 'Europe/Amsterdam',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Netherlands',
		de: 'Niederlande',
		fr: 'Pays-Bas'
	},
	{
		id: 25,
		country: 'NZ',
		language: 'en',
		local: 1,
		country_timezone: 'Pacific/Auckland',
		is_eu: 0,
		currency_code: 'NZD',
		en: 'New Zealand',
		de: 'Neuseeland',
		fr: 'Nouvelle-Zélande'
	},
	{
		id: 103,
		country: 'NI',
		language: 'es',
		local: 1,
		country_timezone: 'America/Managua',
		is_eu: 0,
		currency_code: 'NIO',
		en: 'Nicaragua',
		de: 'Nicaragua',
		fr: 'Nicaragua'
	},
	{
		id: 75,
		country: 'NG',
		language: 'en',
		local: 1,
		country_timezone: 'Africa/Lagos',
		is_eu: 0,
		currency_code: 'NGN',
		en: 'Nigeria',
		de: 'Nigeria',
		fr: 'Nigéria'
	},
	{
		id: 24,
		country: 'NO',
		language: 'no',
		local: 1,
		country_timezone: 'Europe/Oslo',
		is_eu: 1,
		currency_code: 'NOK',
		en: 'Norway',
		de: 'Norwegen',
		fr: 'Norvège'
	},
	{
		id: 77,
		country: 'PA',
		language: 'es',
		local: 1,
		country_timezone: 'America/Panama',
		is_eu: 0,
		currency_code: 'PAB',
		en: 'Panama',
		de: 'Panama',
		fr: 'Panama'
	},
	{
		id: 78,
		country: 'PY',
		language: 'es',
		local: 1,
		country_timezone: 'America/Asuncion',
		is_eu: 0,
		currency_code: 'PYG',
		en: 'Paraguay',
		de: 'Paraguay',
		fr: 'Paraguay'
	},
	{
		id: 79,
		country: 'PE',
		language: 'es',
		local: 1,
		country_timezone: 'America/Lima',
		is_eu: 0,
		currency_code: 'PEN',
		en: 'Peru',
		de: 'Peru',
		fr: 'Pérou'
	},
	{
		id: 80,
		country: 'PH',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Manila',
		is_eu: 0,
		currency_code: 'PHP',
		en: 'Philippines',
		de: 'Philippinen',
		fr: 'Philippines'
	},
	{
		id: 26,
		country: 'PL',
		language: 'pl',
		local: 1,
		country_timezone: 'Europe/Warsaw',
		is_eu: 1,
		currency_code: 'PLN',
		en: 'Poland',
		de: 'Polen',
		fr: 'Pologne'
	},
	{
		id: 27,
		country: 'PT',
		language: 'pt',
		local: 1,
		country_timezone: 'Europe/Lisbon',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Portugal',
		de: 'Portugal',
		fr: 'Portugal'
	},
	{
		id: 101,
		country: 'PR',
		language: 'es',
		local: 1,
		country_timezone: 'America/Argentina/San_Juan',
		is_eu: 0,
		currency_code: 'USD',
		en: 'Puerto Rico',
		de: 'Puerto Rico',
		fr: 'Porto Rico'
	},
	{
		id: 100,
		country: 'PR',
		language: 'en',
		local: 1,
		country_timezone: 'America/Argentina/San_Juan',
		is_eu: 0,
		currency_code: 'USD',
		en: 'Puerto Rico',
		de: 'Puerto Rico',
		fr: 'Porto Rico'
	},
	{
		id: 81,
		country: 'QA',
		language: 'ar',
		local: 1,
		country_timezone: 'Asia/Qatar',
		is_eu: 0,
		currency_code: 'QAR',
		en: 'Qatar',
		de: 'Katar',
		fr: 'Qatar'
	},
	{
		id: 30,
		country: 'RU',
		language: 'ru',
		local: 1,
		country_timezone: 'Europe/Moscow',
		is_eu: 0,
		currency_code: 'RUB',
		en: 'Russian Federation',
		de: 'Russische Föderation',
		fr: 'Fédération de Russie'
	},
	{
		id: 128,
		country: 'SA',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Riyadh',
		is_eu: 0,
		currency_code: 'SAR',
		en: 'Saudi Arabia',
		de: 'Saudi-Arabien',
		fr: 'Arabie Saoudite'
	},
	{
		id: 50,
		country: 'SA',
		language: 'ar',
		local: 1,
		country_timezone: 'Asia/Riyadh',
		is_eu: 0,
		currency_code: 'SAR',
		en: 'Saudi Arabia',
		de: 'Saudi-Arabien',
		fr: 'Arabie Saoudite'
	},
	{
		id: 82,
		country: 'SG',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Singapore',
		is_eu: 0,
		currency_code: 'SGD',
		en: 'Singapore',
		de: 'Singapur',
		fr: 'Singapour'
	},
	{
		id: 84,
		country: 'ZA',
		language: 'en',
		local: 1,
		country_timezone: 'Africa/Johannesburg',
		is_eu: 0,
		currency_code: 'ZAR',
		en: 'South Africa',
		de: 'Südafrika',
		fr: 'Afrique du Sud'
	},
	{
		id: 104,
		country: 'KR',
		language: 'ko',
		local: 1,
		country_timezone: 'Asia/Seoul',
		is_eu: 0,
		currency_code: 'KRW',
		en: 'South Korea',
		de: 'Südkorea',
		fr: 'Corée du Sud'
	},
	{
		id: 12,
		country: 'ES',
		language: 'es',
		local: 1,
		country_timezone: 'Europe/Madrid',
		is_eu: 1,
		currency_code: 'EUR',
		en: 'Spain',
		de: 'Spanien',
		fr: 'Espagne'
	},
	{
		id: 28,
		country: 'SE',
		language: 'sv',
		local: 1,
		country_timezone: 'Europe/Stockholm',
		is_eu: 1,
		currency_code: 'SEK',
		en: 'Sweden',
		de: 'Schweden',
		fr: 'Suède'
	},
	{
		id: 6,
		country: 'CH',
		language: 'de',
		local: 1,
		country_timezone: 'Europe/Zurich',
		is_eu: 1,
		currency_code: 'CHF',
		en: 'Switzerland',
		de: 'Schweiz',
		fr: 'Suisse'
	},
	{
		id: 8,
		country: 'CH',
		language: 'it',
		local: 0,
		country_timezone: 'Europe/Zurich',
		is_eu: 1,
		currency_code: 'CHF',
		en: 'Switzerland',
		de: 'Schweiz',
		fr: 'Suisse'
	},
	{
		id: 7,
		country: 'CH',
		language: 'fr',
		local: 0,
		country_timezone: 'Europe/Zurich',
		is_eu: 1,
		currency_code: 'CHF',
		en: 'Switzerland',
		de: 'Schweiz',
		fr: 'Suisse'
	},
	{
		id: 85,
		country: 'TW',
		language: 'zn',
		local: 1,
		country_timezone: 'Asia/Taipei',
		is_eu: 0,
		currency_code: 'TWD',
		en: 'Taiwan',
		de: 'Taiwan',
		fr: 'Taïwan'
	},
	{
		id: 87,
		country: 'TH',
		language: 'th',
		local: 1,
		country_timezone: 'Asia/Bangkok',
		is_eu: 0,
		currency_code: 'THB',
		en: 'Thailand',
		de: 'Thailand',
		fr: 'Thaïlande'
	},
	{
		id: 118,
		country: 'TH',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Bangkok',
		is_eu: 0,
		currency_code: 'THB',
		en: 'Thailand',
		de: 'Thailand',
		fr: 'Thaïlande'
	},
	{
		id: 98,
		country: 'TT',
		language: 'en',
		local: 1,
		country_timezone: 'America/Port_of_Spain',
		is_eu: 0,
		currency_code: 'TTD',
		en: 'Trinidad and Tobago',
		de: 'Trinidad und Tobago',
		fr: 'Trinité-et-Tobago'
	},
	{
		id: 36,
		country: 'TR',
		language: 'tr',
		local: 1,
		country_timezone: 'Europe/Istanbul',
		is_eu: 0,
		currency_code: 'TRY',
		en: 'Turkey',
		de: 'Türkei',
		fr: 'Turquie'
	},
	{
		id: 31,
		country: 'UA',
		language: 'uk',
		local: 1,
		country_timezone: 'Europe/Kiev',
		is_eu: 0,
		currency_code: 'UAH',
		en: 'Ukraine',
		de: 'Ukraine',
		fr: 'Ukraine'
	},
	{
		id: 51,
		country: 'AE',
		language: 'ar',
		local: 0,
		country_timezone: 'Asia/Dubai',
		is_eu: 0,
		currency_code: 'AED',
		en: 'United Arab Emirates',
		de: 'Vereinigte Arabische Emirate',
		fr: 'Émirats Arabes Unis'
	},
	{
		id: 52,
		country: 'AE',
		language: 'en',
		local: 1,
		country_timezone: 'Asia/Dubai',
		is_eu: 0,
		currency_code: 'AED',
		en: 'United Arab Emirates',
		de: 'Vereinigte Arabische Emirate',
		fr: 'Émirats Arabes Unis'
	},
	{
		id: 15,
		country: 'GB',
		language: 'en',
		local: 1,
		country_timezone: 'Europe/London',
		is_eu: 1,
		currency_code: 'GBP',
		en: 'United Kingdom',
		de: 'Großbritannien und Nordirland',
		fr: 'Royaume-Uni'
	},
	{
		id: 38,
		country: 'US',
		language: 'en',
		local: 1,
		country_timezone: 'America/New_York',
		is_eu: 0,
		currency_code: 'USD',
		en: 'United States',
		de: 'Vereinigte Staaten von Amerika',
		fr: 'États-Unis'
	},
	{
		id: 89,
		country: 'UY',
		language: 'es',
		local: 1,
		country_timezone: 'America/Montevideo',
		is_eu: 0,
		currency_code: 'UYU',
		en: 'Uruguay',
		de: 'Uruguay',
		fr: 'Uruguay'
	},
	{
		id: 90,
		country: 'VE',
		language: 'es',
		local: 1,
		country_timezone: 'America/Caracas',
		is_eu: 0,
		currency_code: 'VEF',
		en: 'Venezuela',
		de: 'Venezuela',
		fr: 'Venezuela'
	},
	{
		id: 91,
		country: 'VN',
		language: 'vi',
		local: 1,
		country_timezone: 'Asia/Ho_Chi_Minh',
		is_eu: 0,
		currency_code: 'VND',
		en: 'Vietnam',
		de: 'Vietnam',
		fr: 'Viet Nam'
	}
];

const countriesHatch = [
	{
		id: 1,
		name: 'Andorra',
		countryKey: 'AD',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 2,
		name: 'United Arab Emirates',
		countryKey: 'AE',
		currencyCode: 'AED',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 3,
		name: 'Afghanistan',
		countryKey: 'AF',
		currencyCode: 'AFN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 4,
		name: 'Antigua And Barbuda',
		countryKey: 'AG',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 5,
		name: 'Anguilla',
		countryKey: 'AI',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 6,
		name: 'Albania',
		countryKey: 'AL',
		currencyCode: 'ALL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 7,
		name: 'Armenia',
		countryKey: 'AM',
		currencyCode: 'AMD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 8,
		name: 'Netherlands Antilles',
		countryKey: 'AN',
		currencyCode: 'ANG',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 9,
		name: 'Angola',
		countryKey: 'AO',
		currencyCode: 'AOA',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 10,
		name: 'Antarctica',
		countryKey: 'AQ',
		currencyCode: 'XXX',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 11,
		name: 'Argentina',
		countryKey: 'AR',
		currencyCode: 'ARS',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 12,
		name: 'American Samoa',
		countryKey: 'AS',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 13,
		name: 'Austria',
		countryKey: 'AT',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 14,
		name: 'Australia',
		countryKey: 'AU',
		currencyCode: 'AUD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 15,
		name: 'Aruba',
		countryKey: 'AW',
		currencyCode: 'AWG',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 16,
		name: 'Aaland Islands',
		countryKey: 'AX',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 17,
		name: 'Azerbaijan',
		countryKey: 'AZ',
		currencyCode: 'AZN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 18,
		name: 'Bosnia And Herzegovina',
		countryKey: 'BA',
		currencyCode: 'BAM',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 19,
		name: 'Barbados',
		countryKey: 'BB',
		currencyCode: 'BBD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 20,
		name: 'Bangladesh',
		countryKey: 'BD',
		currencyCode: 'BDT',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 21,
		name: 'Belgium',
		countryKey: 'BE',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 22,
		name: 'Burkina Faso',
		countryKey: 'BF',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 23,
		name: 'Bulgaria',
		countryKey: 'BG',
		currencyCode: 'BGN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 24,
		name: 'Bahrain',
		countryKey: 'BH',
		currencyCode: 'BHD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 25,
		name: 'Burundi',
		countryKey: 'BI',
		currencyCode: 'BIF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 26,
		name: 'Benin',
		countryKey: 'BJ',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 27,
		name: 'Bermuda',
		countryKey: 'BM',
		currencyCode: 'BMD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 28,
		name: 'Brunei Darussalam',
		countryKey: 'BN',
		currencyCode: 'BND',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 29,
		name: 'Bolivia',
		countryKey: 'BO',
		currencyCode: 'BOB',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 30,
		name: 'Brazil',
		countryKey: 'BR',
		currencyCode: 'BRL',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 31,
		name: 'Bahamas',
		countryKey: 'BS',
		currencyCode: 'BSD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 32,
		name: 'Bhutan',
		countryKey: 'BT',
		currencyCode: 'BTN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 33,
		name: 'Bouvet Island',
		countryKey: 'BV',
		currencyCode: 'NOK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 34,
		name: 'Botswana',
		countryKey: 'BW',
		currencyCode: 'BWP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 35,
		name: 'Belarus',
		countryKey: 'BY',
		currencyCode: 'BYN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 36,
		name: 'Belize',
		countryKey: 'BZ',
		currencyCode: 'BZD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 37,
		name: 'Canada',
		countryKey: 'CA',
		currencyCode: 'CAD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 38,
		name: 'Democratic Republic of Congo (former Zaire)',
		countryKey: 'CD',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 39,
		name: 'Central African Republic',
		countryKey: 'CF',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 40,
		name: 'Congo',
		countryKey: 'CG',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 41,
		name: 'Switzerland',
		countryKey: 'CH',
		currencyCode: 'CHF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 42,
		name: 'Cote d&#146;Ivoire',
		countryKey: 'CI',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 43,
		name: 'Cook Islands',
		countryKey: 'CK',
		currencyCode: 'NZD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 44,
		name: 'Chile',
		countryKey: 'CL',
		currencyCode: 'CLP',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 45,
		name: 'Cameroon',
		countryKey: 'CM',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 46,
		name: 'China',
		countryKey: 'CN',
		currencyCode: 'CNY',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 47,
		name: 'Colombia',
		countryKey: 'CO',
		currencyCode: 'COP',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 48,
		name: 'Costa Rica',
		countryKey: 'CR',
		currencyCode: 'CRC',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 49,
		name: 'Serbia And Montenegro',
		countryKey: 'CS',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 50,
		name: 'Cuba',
		countryKey: 'CU',
		currencyCode: 'CUP',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 51,
		name: 'Cape Verde',
		countryKey: 'CV',
		currencyCode: 'CVE',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 52,
		name: 'Cyprus',
		countryKey: 'CY',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 53,
		name: 'Czech Republic',
		countryKey: 'CZ',
		currencyCode: 'CZK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 54,
		name: 'Germany',
		countryKey: 'DE',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 55,
		name: 'Djibouti',
		countryKey: 'DJ',
		currencyCode: 'DJF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 56,
		name: 'Denmark',
		countryKey: 'DK',
		currencyCode: 'DKK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 57,
		name: 'Dominica',
		countryKey: 'DM',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 58,
		name: 'Dominican Republic',
		countryKey: 'DO',
		currencyCode: 'DOP',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 59,
		name: 'Algeria',
		countryKey: 'DZ',
		currencyCode: 'DZD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 60,
		name: 'Ecuador',
		countryKey: 'EC',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 61,
		name: 'Estonia',
		countryKey: 'EE',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 62,
		name: 'Egypt',
		countryKey: 'EG',
		currencyCode: 'EGP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 63,
		name: 'Eritrea',
		countryKey: 'ER',
		currencyCode: 'ERN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 64,
		name: 'Spain',
		countryKey: 'ES',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 65,
		name: 'Ethiopia',
		countryKey: 'ET',
		currencyCode: 'ETB',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 66,
		name: 'Finland',
		countryKey: 'FI',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 67,
		name: 'Fiji',
		countryKey: 'FJ',
		currencyCode: 'FJD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 68,
		name: 'Falkland Islands (Malvinas)',
		countryKey: 'FK',
		currencyCode: 'FKP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 69,
		name: 'Federated States of Micronesia',
		countryKey: 'FM',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 70,
		name: 'Faroe Islands',
		countryKey: 'FO',
		currencyCode: 'DKK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 71,
		name: 'France',
		countryKey: 'FR',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 72,
		name: 'Gabon',
		countryKey: 'GA',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 74,
		name: 'Grenada',
		countryKey: 'GD',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 75,
		name: 'Georgia',
		countryKey: 'GE',
		currencyCode: 'GEL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 76,
		name: 'French Guiana',
		countryKey: 'GF',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 77,
		name: 'GGY',
		countryKey: 'GG',
		currencyCode: 'GNF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 78,
		name: 'Ghana',
		countryKey: 'GH',
		currencyCode: 'GHS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 79,
		name: 'Gibraltar',
		countryKey: 'GI',
		currencyCode: 'GIP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 80,
		name: 'Greenland',
		countryKey: 'GL',
		currencyCode: 'DKK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 81,
		name: 'Gambia',
		countryKey: 'GM',
		currencyCode: 'GMD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 82,
		name: 'Guinea',
		countryKey: 'GN',
		currencyCode: 'GNF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 83,
		name: 'Guadeloupe',
		countryKey: 'GP',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 84,
		name: 'Equatorial Guinea',
		countryKey: 'GQ',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 85,
		name: 'Greece',
		countryKey: 'GR',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 86,
		name: 'S. Georgia &amp; S. Sandwich Isls.',
		countryKey: 'GS',
		currencyCode: 'GBP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 87,
		name: 'Guatemala',
		countryKey: 'GT',
		currencyCode: 'GTQ',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 88,
		name: 'Guam',
		countryKey: 'GU',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 89,
		name: 'Guinea-Bissau',
		countryKey: 'GW',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 90,
		name: 'Guyana',
		countryKey: 'GY',
		currencyCode: 'GYD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 91,
		name: 'Hong Kong',
		countryKey: 'HK',
		currencyCode: 'HKD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 92,
		name: 'Honduras',
		countryKey: 'HN',
		currencyCode: 'HNL',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 93,
		name: 'Croatia',
		countryKey: 'HR',
		currencyCode: 'HRK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 94,
		name: 'Haiti',
		countryKey: 'HT',
		currencyCode: 'HTG',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 95,
		name: 'Hungary',
		countryKey: 'HU',
		currencyCode: 'HUF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 96,
		name: 'Indonesia',
		countryKey: 'ID',
		currencyCode: 'IDR',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 97,
		name: 'Ireland',
		countryKey: 'IE',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 98,
		name: 'Israel',
		countryKey: 'IL',
		currencyCode: 'ILS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 99,
		name: 'Isle of Man',
		countryKey: 'IM',
		currencyCode: 'GBP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 100,
		name: 'India',
		countryKey: 'IN',
		currencyCode: 'INR',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 101,
		name: 'IOT',
		countryKey: 'IO',
		currencyCode: 'GBP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 102,
		name: 'Iraq',
		countryKey: 'IQ',
		currencyCode: 'IQD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 103,
		name: 'Islamic Republic of Iran',
		countryKey: 'IR',
		currencyCode: 'IRR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 104,
		name: 'Iceland',
		countryKey: 'IS',
		currencyCode: 'ISK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 105,
		name: 'Italy',
		countryKey: 'IT',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 106,
		name: 'JEY',
		countryKey: 'JE',
		currencyCode: 'GBP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 107,
		name: 'Jamaica',
		countryKey: 'JM',
		currencyCode: 'JMD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 108,
		name: 'Jordan',
		countryKey: 'JO',
		currencyCode: 'JOD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 109,
		name: 'Japan',
		countryKey: 'JP',
		currencyCode: 'JPY',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 110,
		name: 'Kenya',
		countryKey: 'KE',
		currencyCode: 'KES',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 111,
		name: 'Kyrgyzstan',
		countryKey: 'KG',
		currencyCode: 'KGS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 112,
		name: 'Cambodia',
		countryKey: 'KH',
		currencyCode: 'KHR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 113,
		name: 'Kiribati',
		countryKey: 'KI',
		currencyCode: 'AUD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 114,
		name: 'Comoros',
		countryKey: 'KM',
		currencyCode: 'KMF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 115,
		name: 'Saint Kitts&mdash;Nevis',
		countryKey: 'KN',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 116,
		name: "Democratic People's Republic of Korea",
		countryKey: 'KP',
		currencyCode: 'KPW',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 117,
		name: 'Republic of Korea',
		countryKey: 'KR',
		currencyCode: 'KRW',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 118,
		name: 'Kuwait',
		countryKey: 'KW',
		currencyCode: 'KWD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 119,
		name: 'Cayman Islands',
		countryKey: 'KY',
		currencyCode: 'KYD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 120,
		name: 'Kazakhstan',
		countryKey: 'KZ',
		currencyCode: 'KZT',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 121,
		name: 'Lao People&#146;s Democratic Republic',
		countryKey: 'LA',
		currencyCode: 'LAK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 122,
		name: 'Lebanon',
		countryKey: 'LB',
		currencyCode: 'LBP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 123,
		name: 'Saint Lucia',
		countryKey: 'LC',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 124,
		name: 'Liechtenstein',
		countryKey: 'LI',
		currencyCode: 'CHF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 125,
		name: 'Sri Lanka',
		countryKey: 'LK',
		currencyCode: 'LKR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 126,
		name: 'Liberia',
		countryKey: 'LR',
		currencyCode: 'LRD',
		metricSystem: false,
		regionKey: 'EMEA'
	},
	{
		id: 127,
		name: 'Lesotho',
		countryKey: 'LS',
		currencyCode: 'LSL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 128,
		name: 'Lithuania',
		countryKey: 'LT',
		currencyCode: 'LTL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 129,
		name: 'Luxembourg',
		countryKey: 'LU',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 130,
		name: 'Latvia',
		countryKey: 'LV',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 131,
		name: 'Libyan Arab Jamahiriya',
		countryKey: 'LY',
		currencyCode: 'LYD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 132,
		name: 'Morocco',
		countryKey: 'MA',
		currencyCode: 'MAD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 133,
		name: 'Monaco',
		countryKey: 'MC',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 134,
		name: 'Republic of Moldova',
		countryKey: 'MD',
		currencyCode: 'MDL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 135,
		name: 'Montenegro',
		countryKey: 'ME',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 136,
		name: 'MAF',
		countryKey: 'MF',
		currencyCode: 'XXX',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 137,
		name: 'Madagascar',
		countryKey: 'MG',
		currencyCode: 'MGA',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 138,
		name: 'Marshall Islands',
		countryKey: 'MH',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 139,
		name: 'North Macedonia',
		countryKey: 'MK',
		currencyCode: 'MKD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 140,
		name: 'Mali',
		countryKey: 'ML',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 141,
		name: 'Myanmar',
		countryKey: 'MM',
		currencyCode: 'MMK',
		metricSystem: false,
		regionKey: 'EMEA'
	},
	{
		id: 142,
		name: 'Mongolia',
		countryKey: 'MN',
		currencyCode: 'MNT',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 143,
		name: 'Macau',
		countryKey: 'MO',
		currencyCode: 'MOP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 144,
		name: 'Northern Mariana Islands',
		countryKey: 'MP',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 145,
		name: 'Martinique',
		countryKey: 'MQ',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 146,
		name: 'Mauritania',
		countryKey: 'MR',
		currencyCode: 'MRO',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 147,
		name: 'Montserrat',
		countryKey: 'MS',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 148,
		name: 'Malta',
		countryKey: 'MT',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 149,
		name: 'Mauritius',
		countryKey: 'MU',
		currencyCode: 'MUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 150,
		name: 'Maldives',
		countryKey: 'MV',
		currencyCode: 'MVR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 151,
		name: 'Malawi',
		countryKey: 'MW',
		currencyCode: 'MWK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 152,
		name: 'Mexico',
		countryKey: 'MX',
		currencyCode: 'MXN',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 153,
		name: 'Malaysia',
		countryKey: 'MY',
		currencyCode: 'MYR',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 154,
		name: 'Mozambique',
		countryKey: 'MZ',
		currencyCode: 'MZN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 155,
		name: 'Namibia',
		countryKey: 'NA',
		currencyCode: 'NAD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 156,
		name: 'New Caledonia',
		countryKey: 'NC',
		currencyCode: 'XPF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 157,
		name: 'Niger',
		countryKey: 'NE',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 158,
		name: 'Norfolk Island',
		countryKey: 'NF',
		currencyCode: 'AUD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 159,
		name: 'Nigeria',
		countryKey: 'NG',
		currencyCode: 'NGN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 160,
		name: 'Nicaragua',
		countryKey: 'NI',
		currencyCode: 'NIO',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 161,
		name: 'Netherlands',
		countryKey: 'NL',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 162,
		name: 'Norway',
		countryKey: 'NO',
		currencyCode: 'NOK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 163,
		name: 'Nepal',
		countryKey: 'NP',
		currencyCode: 'NPR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 164,
		name: 'Nauru',
		countryKey: 'NR',
		currencyCode: 'AUD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 165,
		name: 'Niue',
		countryKey: 'NU',
		currencyCode: 'NZD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 166,
		name: 'New Zealand',
		countryKey: 'NZ',
		currencyCode: 'NZD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 167,
		name: 'Oman',
		countryKey: 'OM',
		currencyCode: 'OMR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 168,
		name: 'Panama',
		countryKey: 'PA',
		currencyCode: 'PAB',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 169,
		name: 'Peru',
		countryKey: 'PE',
		currencyCode: 'PEN',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 170,
		name: 'French Polynesia',
		countryKey: 'PF',
		currencyCode: 'XPF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 171,
		name: 'Papua New Guinea',
		countryKey: 'PG',
		currencyCode: 'PGK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 172,
		name: 'Philippines',
		countryKey: 'PH',
		currencyCode: 'PHP',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 173,
		name: 'Pakistan',
		countryKey: 'PK',
		currencyCode: 'PKR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 174,
		name: 'Poland',
		countryKey: 'PL',
		currencyCode: 'PLN',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 175,
		name: 'St Pierre And Miquelon',
		countryKey: 'PM',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 176,
		name: 'Puerto Rico',
		countryKey: 'PR',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 177,
		name: 'Palestine',
		countryKey: 'PS',
		currencyCode: 'XXX',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 178,
		name: 'Portugal',
		countryKey: 'PT',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 179,
		name: 'Palau',
		countryKey: 'PW',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 180,
		name: 'Paraguay',
		countryKey: 'PY',
		currencyCode: 'PYG',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 181,
		name: 'Qatar',
		countryKey: 'QA',
		currencyCode: 'QAR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 182,
		name: 'Reunion',
		countryKey: 'RE',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 183,
		name: 'Romania',
		countryKey: 'RO',
		currencyCode: 'RON',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 184,
		name: 'Serbia',
		countryKey: 'RS',
		currencyCode: 'RSD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 185,
		name: 'Russian Federation',
		countryKey: 'RU',
		currencyCode: 'RUB',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 186,
		name: 'Rwanda',
		countryKey: 'RW',
		currencyCode: 'RWF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 187,
		name: 'Saudi Arabia',
		countryKey: 'SA',
		currencyCode: 'SAR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 188,
		name: 'Solomon Islands',
		countryKey: 'SB',
		currencyCode: 'SBD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 189,
		name: 'Seychelles',
		countryKey: 'SC',
		currencyCode: 'SCR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 190,
		name: 'Sudan',
		countryKey: 'SD',
		currencyCode: 'SDG',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 191,
		name: 'Sweden',
		countryKey: 'SE',
		currencyCode: 'SEK',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 192,
		name: 'Singapore',
		countryKey: 'SG',
		currencyCode: 'SGD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 193,
		name: 'Slovenia',
		countryKey: 'SI',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 194,
		name: 'Slovakia',
		countryKey: 'SK',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 195,
		name: 'Sierra Leone',
		countryKey: 'SL',
		currencyCode: 'SLL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 196,
		name: 'San Marino',
		countryKey: 'SM',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 197,
		name: 'Senegal',
		countryKey: 'SN',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 198,
		name: 'Somalia',
		countryKey: 'SO',
		currencyCode: 'SOS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 199,
		name: 'Suriname',
		countryKey: 'SR',
		currencyCode: 'SRD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 200,
		name: 'Sao Tome And Principe',
		countryKey: 'ST',
		currencyCode: 'STD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 201,
		name: 'El Salvador',
		countryKey: 'SV',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 202,
		name: 'Syrian Arab Republic',
		countryKey: 'SY',
		currencyCode: 'SYP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 203,
		name: 'Swaziland',
		countryKey: 'SZ',
		currencyCode: 'SZL',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 204,
		name: 'Turks And Caicos Islands',
		countryKey: 'TC',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 205,
		name: 'Chad',
		countryKey: 'TD',
		currencyCode: 'XAF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 206,
		name: 'French Southern Territories',
		countryKey: 'TF',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 207,
		name: 'Togo',
		countryKey: 'TG',
		currencyCode: 'XOF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 208,
		name: 'Thailand',
		countryKey: 'TH',
		currencyCode: 'THB',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 209,
		name: 'Tajikistan',
		countryKey: 'TJ',
		currencyCode: 'TJS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 210,
		name: 'Tokelau',
		countryKey: 'TK',
		currencyCode: 'NZD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 211,
		name: 'TLS',
		countryKey: 'TL',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 212,
		name: 'Turkmenistan',
		countryKey: 'TM',
		currencyCode: 'TMT',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 213,
		name: 'Tunisia',
		countryKey: 'TN',
		currencyCode: 'TND',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 214,
		name: 'Tonga',
		countryKey: 'TO',
		currencyCode: 'TOP',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 215,
		name: 'Turkey',
		countryKey: 'TR',
		currencyCode: 'TRY',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 216,
		name: 'Trinidad And Tobago',
		countryKey: 'TT',
		currencyCode: 'TTD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 217,
		name: 'Tuvalu',
		countryKey: 'TV',
		currencyCode: 'AUD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 218,
		name: 'Taiwan',
		countryKey: 'TW',
		currencyCode: 'TWD',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 219,
		name: 'United Republic of Tanzania',
		countryKey: 'TZ',
		currencyCode: 'TZS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 220,
		name: 'Ukraine',
		countryKey: 'UA',
		currencyCode: 'UAH',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 221,
		name: 'Uganda',
		countryKey: 'UG',
		currencyCode: 'UGX',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 222,
		name: 'UMI',
		countryKey: 'UM',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 223,
		name: 'United States',
		countryKey: 'US',
		currencyCode: 'USD',
		metricSystem: false,
		regionKey: 'NA'
	},
	{
		id: 224,
		name: 'Uruguay',
		countryKey: 'UY',
		currencyCode: 'UYU',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 225,
		name: 'Uzbekistan',
		countryKey: 'UZ',
		currencyCode: 'UZS',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 226,
		name: 'Vatican City State (Holy See)',
		countryKey: 'VA',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 227,
		name: 'Saint Vincent And The Grenadines',
		countryKey: 'VC',
		currencyCode: 'XCD',
		metricSystem: true,
		regionKey: 'NA'
	},
	{
		id: 228,
		name: 'Venezuela',
		countryKey: 'VE',
		currencyCode: 'VEF',
		metricSystem: true,
		regionKey: 'LATAM'
	},
	{
		id: 229,
		name: 'Virgin Islands British',
		countryKey: 'VG',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 230,
		name: 'Virgin Islands U.S.',
		countryKey: 'VI',
		currencyCode: 'USD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 231,
		name: 'Vietnam',
		countryKey: 'VN',
		currencyCode: 'VND',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 232,
		name: 'Vanuatu',
		countryKey: 'VU',
		currencyCode: 'VUV',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 233,
		name: 'WLF',
		countryKey: 'WF',
		currencyCode: 'XPF',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 234,
		name: 'Samoa',
		countryKey: 'WS',
		currencyCode: 'WST',
		metricSystem: true,
		regionKey: 'APAC'
	},
	{
		id: 235,
		name: 'Yemen',
		countryKey: 'YE',
		currencyCode: 'YER',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 236,
		name: 'Mayotte',
		countryKey: 'YT',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 237,
		name: 'South Africa',
		countryKey: 'ZA',
		currencyCode: 'ZAR',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 238,
		name: 'Zambia',
		countryKey: 'ZM',
		currencyCode: 'XXX',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 239,
		name: 'Zimbabwe',
		countryKey: 'ZW',
		currencyCode: 'ZWD',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 241,
		name: 'Other',
		countryKey: 'XX',
		currencyCode: 'XXX',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 242,
		name: 'United Kingdom',
		countryKey: 'GB',
		currencyCode: 'GBP',
		metricSystem: true,
		regionKey: 'EMEA'
	},
	{
		id: 243,
		name: 'Kosovo',
		countryKey: 'XK',
		currencyCode: 'EUR',
		metricSystem: true,
		regionKey: 'EMEA'
	}
];

const formattedHatchCountries = countriesHatch.map((countryHatch) => {
	const locale = countryHatch.countryKey;
	const formatted = {
		...countryHatch,
		locale,
		language: locale
	};
	// TO DO - COUNTRIES LOCALE
	// const ccCountry = countriesCC.find((c) => c.en === countryHatch.name);
	// if (ccCountry) {
	// 	formatted.language = `${locale}_${ccCountry.language}`;
	// }
	return formatted;
});

export default formattedHatchCountries;
