const strToBool = (s) => s.toLowerCase() === 'true';

const sortAlphabetically = (data, field) => {
	if (field) {
		return data && Array.isArray(data)
			? data.sort((a, b) => a?.[field]?.localeCompare(b[field]))
			: [];
	}

	return data.sort((a, b) => a.localeCompare(b));
};

const sortByKey = (array, key) =>
	array?.length ? array.sort((a, b) => a[key].localeCompare(b[key])) : [];

const setNestedValue = (obj, path, value) => {
	const keys = path.split('.');
	let current = obj;

	for (let i = 0; i < keys.length - 1; i += 1) {
		const key = keys[i];
		if (!current[key]) {
			current[key] = {};
		}
		current = current[key];
	}

	current[keys[keys.length - 1]] = value;
};

const formatBrandUserCountriesData = (data, mappedCountries) => {
	if (!data || !data.sgUserMappings) {
		return [];
	}

	const uniqueCountryCodes = new Set();

	return Object.values(data.sgUserMappings).reduce(
		(accumulator, countryMappings) => {
			const formattedMappings = countryMappings
				.map(({ countryCode, ...userMapping }) => {
					if (uniqueCountryCodes.has(countryCode)) {
						return null;
					}

					uniqueCountryCodes.add(countryCode);

					const matchedCountry = mappedCountries.find(
						(country) => country.value.locale === countryCode
					);

					return matchedCountry
						? {
								label: matchedCountry.label,
								value: { ...matchedCountry.value },
								...userMapping
						  }
						: null;
				})
				.filter(Boolean);

			return [...accumulator, ...formattedMappings];
		},
		[]
	);
};

const filterOutNull = (obj) =>
	Object.fromEntries(
		Object.entries(obj).filter(
			([, value]) => value !== null && value !== 'undefined'
		)
	);

const formatTokens = (tokens) =>
	tokens
		? Object.keys(tokens)
				.filter((key) => !tokens[key].client_group_id)
				.map((key) => ({
					label: key,
					value: key
				}))
		: [];

const getUniqueSortedBrandNames = (sgUserMappings) => {
	const brandNames = sgUserMappings
		? Object.values(sgUserMappings).flatMap((brands) =>
				brands.map((brand) => brand.brandName)
		  )
		: [];
	return [...new Set(brandNames)].sort();
};

const groupBrandsByCountry = (sgUserMappings, sortedBrandNames) =>
	sortedBrandNames.reduce((acc, brandName) => {
		Object.keys(sgUserMappings).forEach((countryCode) => {
			const brands = sgUserMappings[countryCode];
			const matchingBrand = brands.find(
				(brand) => brand.brandName === brandName
			);
			if (matchingBrand) {
				if (!acc[countryCode]) {
					acc[countryCode] = [];
				}
				acc[countryCode].push(matchingBrand);
			}
		});
		return acc;
	}, {});

const preparePsAdminMappings = (countries, clientGroups) =>
	countries.reduce((result, country) => {
		const countryMappings = clientGroups.map((client) => ({
			countryCode: country.abbreviation,
			brandId: client.id,
			brandName: client.name
		}));
		return {
			...result,
			[country.abbreviation]: countryMappings
		};
	}, {});

const truncateString = (str, maxLength) => {
	if (str?.length > maxLength) {
		return `${str.substring(0, maxLength)}...`;
	}
	return str;
};

export {
	strToBool,
	sortAlphabetically,
	sortByKey,
	setNestedValue,
	formatBrandUserCountriesData,
	filterOutNull,
	truncateString,
	formatTokens,
	getUniqueSortedBrandNames,
	groupBrandsByCountry,
	preparePsAdminMappings
};
