import React from 'react';

import { Skeleton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const ChartSkeleton = () => (
	<Paper>
		<Box padding={2} sx={{ height: '100vh' }}>
			<Skeleton variant="text" width={200} height={40} />
			<Skeleton variant="text" width={400} height={20} />

			<Box display="flex" justifyContent="space-between" mt={2}>
				<Skeleton variant="text" width={150} height={30} />
				<Skeleton variant="text" width={150} height={30} />
				<Skeleton variant="text" width={150} height={30} />
			</Box>

			<Skeleton variant="rectangular" width="100%" height={500} mt={2} />

			<Box display="flex" justifyContent="space-between" mt={2}>
				<Skeleton variant="rectangular" width="30%" height={200} />
				<Skeleton variant="rectangular" width="30%" height={200} />
				<Skeleton variant="rectangular" width="30%" height={200} />
			</Box>

			<Box display="flex" justifyContent="space-between" mt={2}>
				<Skeleton variant="text" width="30%" height={20} />
				<Skeleton variant="text" width="30%" height={20} />
				<Skeleton variant="text" width="30%" height={20} />
			</Box>

			<Box display="flex" justifyContent="space-between" mt={2}>
				<Skeleton variant="rectangular" width="30%" height={200} />
				<Skeleton variant="rectangular" width="30%" height={200} />
				<Skeleton variant="rectangular" width="30%" height={200} />
			</Box>
		</Box>
	</Paper>
);

export default ChartSkeleton;
