import { PLATFORMS, STATUS, STATUS_MESSAGE } from 'constants';
import { all, put, call, takeLatest, delay } from 'redux-saga/effects';
import { differenceInMilliseconds } from 'date-fns';
import hatchApi from 'services/auth/hatch';

import ccApi from 'services/auth/cc';
import psApi from 'services/auth/ps';
import httpClient from 'services/httpClient';
import hatchCountries from 'services/hatchCountries';

import localStorage from 'services/localStorage';
import { decodeJWT } from 'services/tokenService';

import {
	actionTypes as authActionTypes,
	actionCreators as authActionCreators
} from 'redux/ducks/authDuck';
import { actionTypes as appActionTypes } from 'redux/ducks/appDuck';

import { getManufacturerIds } from 'utils/user/userUtils';
import {
	sortAlphabetically,
	sortByKey,
	formatBrandUserCountriesData,
	formatTokens,
	getUniqueSortedBrandNames,
	groupBrandsByCountry,
	preparePsAdminMappings
} from 'utils/data/dataUtils';
import {
	getEndpointFromError,
	getErrorMessage
} from 'utils/errorHandling/errorUtils';
import errorHandlerSaga from './errorHandlerSaga';

const fiveMinInMiliseconds = 300000;

export function* scheduleRefreshToken({ payload }) {
	const token = payload.token || payload.access_token;
	const refreshToken = payload.refresh_token;
	const decoded = decodeJWT(token);
	const expiresInMiliseconds = differenceInMilliseconds(
		new Date(decoded?.payload?.exp * 1000),
		new Date()
	);

	yield delay(expiresInMiliseconds - fiveMinInMiliseconds);

	yield put({
		type: authActionTypes.AUTH_HATCH_TOKEN_REFRESH_REQUESTED,
		refreshToken
	});
}

export function* loginHatchUser({ payload, store, history }) {
	try {
		const form = new FormData();

		form.append('username', payload.username);
		form.append('password', payload.password);
		form.append('grant_type', payload.grant_type);

		if (payload.code) {
			form.append('code', payload.code);
		}

		const { data } = yield call(hatchApi.login, form);

		localStorage.write({
			token: data.access_token,
			refresh: data.refresh_token,
			platform: PLATFORMS.hatch,
			expires: data.expires_in
		});

		yield put({
			type: authActionTypes.AUTH_HATCH_USER_REQUESTED,
			store,
			history
		});

		yield put({
			type: authActionTypes.AUTH_HATCH_LOGIN_SUCCEEDED,
			payload: { ...data, origin: PLATFORMS.hatch }
		});
	} catch (err) {
		if (err?.status === STATUS.MFA_REQUIRED) {
			yield put({
				type: authActionTypes.AUTH_HATCH_USER_MFA_REQUESTED,
				mfa: true
			});
		} else if (STATUS_MESSAGE[err?.data?.error]) {
			yield put({
				type: authActionTypes.AUTH_HATCH_LOGIN_FAILED,
				error: {
					type: err?.data.error,
					error_description: STATUS_MESSAGE[err?.data.error]
				}
			});
		} else {
			yield put({
				type: authActionTypes.AUTH_HATCH_LOGIN_FAILED,
				error: {
					type: err?.data?.error || err?.code || 'Server error',
					error_description:
						STATUS_MESSAGE[err?.data?.error] ||
						err?.message ||
						err?.data?.error_description
				}
			});
		}
	}
}

export function* getHatchUserSaga(payload) {
	const { store, history } = payload;

	try {
		const { data } = yield call(hatchApi.getUser);

		const sortedHatchBrands = sortByKey(data?.brands, 'name');

		const sortedHatchCountries = sortByKey(hatchCountries, 'name');

		yield put({
			type: authActionTypes.AUTH_HATCH_USER_SUCCEEDED,
			user: data,
			brands: sortedHatchBrands.map((brand) => ({
				label: brand.name,
				value: brand,
				id: brand.id,
				affiliateId: brand.affiliateId
			})),
			countries: sortedHatchCountries.map((country) => ({
				label: country.name,
				value: {
					name: country.name,
					locale: country.countryKey,
					id: country.id,
					language: country.language
				}
			}))
		});

		yield put({
			type: authActionTypes.AUTH_SET_ORIGIN,
			origin: PLATFORMS.hatch
		});

		yield put({
			type: authActionTypes.AUTH_SET_INTERCEPTORS,
			store,
			history
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_HATCH_USER_FAILED
		});

		localStorage.remove(['token', 'refresh', 'expires', 'platform']);

		yield put({
			type: authActionTypes.AUTH_LOGOUT_REQUESTED,
			origin,
			history
		});
	}
}

export function* getHatchRefreshToken(payload) {
	try {
		const { data } = yield call(hatchApi.refreshToken, payload);

		localStorage.write({
			token: data.access_token,
			platform: PLATFORMS.hatch,
			refresh: data.refresh_token
		});

		yield put({
			type: authActionTypes.AUTH_HATCH_TOKEN_REFRESH_SUCCEEDED,
			payload: { ...data, origin: payload.origin }
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_HATCH_TOKEN_REFRESH_FAILED
		});
		yield put({
			type: appActionTypes.APP_LOADING_SUCCESS
		});
	}
}

export function* getCCTokenSaga() {
	try {
		const { data } = yield call(ccApi.getCCToken);

		// Save token
		localStorage.write({ token: data.atoken, platform: PLATFORMS.cc });

		yield put({
			type: authActionTypes.AUTH_CC_TOKEN_SUCCEEDED,
			token: data.atoken
		});

		// Init User fetching
		yield put({
			type: authActionTypes.AUTH_CC_USER_REQUESTED
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_CC_TOKEN_FAILED
		});
	}
}

export function* getCCLicensesSaga(payload) {
	try {
		const { data } = yield call(ccApi.getTokens, payload.country);
		// sortAlphabetically
		const tokens = data.response.map((token) => ({
			label: `${token.description} (${token.token})`,
			value: token.token
		}));

		yield put({
			type: authActionTypes.AUTH_CC_LICENSE_SUCCEEDED,
			tokens: tokens?.length
				? sortAlphabetically(tokens, 'label')
				: [
						{
							label: `NO ACTIVE TOKENS`,
							value: ''
						}
				  ]
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_CC_LICENSE_FAILED
		});
	}
}

export function* getCCUserSaga(payload) {
	const { store, history } = payload;

	try {
		const { data } = yield call(ccApi.getUser);
		const { data: countries } = yield call(ccApi.getCountries);

		yield put({
			type: authActionTypes.AUTH_CC_USER_SUCCEEDED,
			user: data,
			manufacturer_id: data.payload.manufacturer_id,
			countries: countries.data.map((country) => ({
				label: `${country.en} (${country.language})`,
				value: {
					name: country.en,
					locale: country.country,
					language: country.language,
					id: country.id
				}
			}))
		});

		yield put({
			type: authActionTypes.AUTH_SET_INTERCEPTORS,
			store,
			history
		});
	} catch (err) {
		localStorage.remove(['token', 'refresh', 'platform']);

		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_CC_USER_FAILED
		});

		yield put({
			type: authActionTypes.AUTH_LOGOUT_REQUESTED,
			origin,
			history
		});
	}
}

export function* scheduleCCRefreshToken() {
	const tenMinutes = 600000;
	yield delay(tenMinutes);
	yield put({
		type: authActionTypes.AUTH_CC_TOKEN_REFRESH_REQUESTED
	});
}

export function* refreshCCToken() {
	try {
		const { data } = yield call(ccApi.getToken);

		localStorage.write({ token: data.atoken, platform: PLATFORMS.cc });

		yield put({
			type: authActionTypes.AUTH_CC_TOKEN_REFRESH_SUCCEEDED,
			payload: { ...data, origin: PLATFORMS.cc }
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_HATCH_TOKEN_REFRESH_FAILED
		});
	}
}

export function* getPSTokenSaga() {
	try {
		const { data } = yield call(psApi.getToken);
		localStorage.write({ token: data.jwt, platform: PLATFORMS.ps });

		yield put({
			type: authActionTypes.AUTH_PS_TOKEN_SUCCEEDED,
			token: data.jwt
		});

		yield put({
			type: authActionTypes.AUTH_SET_ORIGIN,
			origin: PLATFORMS.ps
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_PS_TOKEN_FAILED
		});
	}
}

export function* getPSUserSaga(payload) {
	const { store, history } = payload;

	try {
		const { data } = yield call(psApi.getUser);
		const { data: countries } = yield call(psApi.getCountries);
		const { data: response } = yield call(psApi.getTokensPS, data.client.id);

		const sortedCountries = sortByKey(countries, 'name');
		const sortedClientGroups = sortByKey(data?.clientGroups, 'name');
		const uniqueSortedBrandNames = getUniqueSortedBrandNames(
			data.sgUserMappings
		);
		const sortedBrands = groupBrandsByCountry(
			data?.sgUserMappings,
			uniqueSortedBrandNames
		);

		const psAdminRole = data.userRoles.some(
			(role) => role.permissionName === 'ps-admin'
		);

		const psAdminMappings = preparePsAdminMappings(
			sortedCountries,
			sortedClientGroups
		);

		const mappedCountries = sortedCountries.map((country) => ({
			label: country.name,
			value: {
				name: country.name,
				locale: country.abbreviation,
				language: country.abbreviation,
				id: 0
			}
		}));

		const brandUserCountriesData = formatBrandUserCountriesData(
			data,
			mappedCountries
		);

		const commonData = {
			type: authActionTypes.AUTH_PS_USER_SUCCEEDED,
			user: data,
			brands: data.sgUserMappings ? getManufacturerIds(sortedBrands) : [],
			restrictedAccess: !!data.sgUserMappings,
			countries: brandUserCountriesData,
			tokens: formatTokens(response?.tokens)
		};

		if (psAdminRole) {
			commonData.brands = psAdminMappings
				? getManufacturerIds(psAdminMappings)
				: [];
			commonData.restrictedAccess = false;
			commonData.countries = mappedCountries;
		}

		yield put(commonData);

		yield put({
			type: authActionTypes.AUTH_SET_ORIGIN,
			origin: PLATFORMS.ps
		});

		yield put({
			type: authActionTypes.AUTH_SET_INTERCEPTORS,
			store,
			history
		});
	} catch (error) {
		if (error && error.status) {
			const statusCode = error.status;
			const endpoint = getEndpointFromError(error);
			const errorMessage = getErrorMessage(statusCode, endpoint);

			yield put(authActionCreators.setError(errorMessage));
		} else {
			yield put(
				authActionCreators.setError(
					'An error occurred. Please try again later.'
				)
			);
		}
	}
}

function* logoutSaga(action) {
	try {
		const { origin, history, unauthorized } = action;

		if (origin !== PLATFORMS.hatch) {
			// const method = origin === PLATFORMS.cc ? ccApi.logout : psApi.logout;
			yield call(ccApi.logout, origin, history);
		} else {
			localStorage.remove(['token', 'refresh', 'platform']);
		}

		yield put({
			type: authActionTypes.AUTH_TOKEN_REMOVED
		});

		yield put({
			type: authActionTypes.AUTH_LOGOUT_SUCCEEDED,
			origin,
			history,
			unauthorized
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_LOGOUT_FAILED
		});
	}
}

function* redirectSaga(action) {
	try {
		const { origin, history, unauthorized } = action;

		if (unauthorized) {
			history.push('/login?redirectedWith=401');
		} else {
			history.push('/login');
		}

		yield put({
			type: authActionTypes.AUTH_LOGOUT_REDIRECT_SUCEEDED,
			origin,
			history
		});

		yield put({
			type: appActionTypes.APP_LOADING_SUCCESS
		});
	} catch (err) {
		yield call(errorHandlerSaga, {
			err,
			type: authActionTypes.AUTH_LOGOUT_REDIRECT_FAILED
		});
	}
}

function* setupInterceptors(action) {
	try {
		httpClient.interceptor.setupInterceptors(action.store, action.history);

		yield put({
			type: appActionTypes.APP_LOADING_SUCCESS
		});
	} catch {
		yield put({
			type: appActionTypes.APP_BOOTSTRAP_FAILURE
		});
	}
}

export default function* authSaga() {
	yield all([
		takeLatest(authActionTypes.AUTH_HATCH_LOGIN_REQUESTED, loginHatchUser),
		takeLatest(
			authActionTypes.AUTH_HATCH_LOGIN_SUCCEEDED,
			scheduleRefreshToken
		),
		takeLatest(
			authActionTypes.AUTH_HATCH_TOKEN_REFRESH_REQUESTED,
			getHatchRefreshToken
		),
		takeLatest(authActionTypes.AUTH_HATCH_USER_REQUESTED, getHatchUserSaga),
		takeLatest(authActionTypes.AUTH_CC_USER_REQUESTED, getCCUserSaga),
		takeLatest(authActionTypes.AUTH_CC_USER_SUCCEEDED, scheduleCCRefreshToken),
		takeLatest(authActionTypes.AUTH_CC_TOKEN_REFRESH_REQUESTED, refreshCCToken),
		takeLatest(authActionTypes.AUTH_CC_TOKEN_REQUESTED, getCCTokenSaga),
		takeLatest(authActionTypes.AUTH_CC_LICENSE_REQUESTED, getCCLicensesSaga),
		takeLatest(authActionTypes.AUTH_PS_TOKEN_REQUESTED, getPSTokenSaga),
		takeLatest(authActionTypes.AUTH_PS_USER_REQUESTED, getPSUserSaga),
		takeLatest(authActionTypes.AUTH_LOGOUT_REQUESTED, logoutSaga),
		takeLatest(authActionTypes.AUTH_LOGOUT_SUCCEEDED, redirectSaga),
		takeLatest(authActionTypes.AUTH_SET_INTERCEPTORS, setupInterceptors)
	]);
}
